import React from 'react';
import compose from 'recompose/compose';
import { popper } from '../../helpers/inputs/autocomplete-popover';
import { withAnchor, withContainer, withError, withExplainer } from './input-field';
import { withPopover } from './popovers/list';
import { Input, withInputLabel } from './input';

const Component = React.memo(({ onInput, ...props }) => {
  return <Input role='combobox' aria-autocomplete='list' onChange={onInput} {...props} />;
});

export const Autocomplete = withPopover({ popper })(Component);
export const InputAutocomplete = compose(
  withAnchor,
  withContainer,
  withExplainer,
  withError,
  withInputLabel
)(Autocomplete);
