import React, { useCallback, useRef, useState } from 'react';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { FunctionShim } from '@packages/helpers/core/shims/function-shim';
import { InputButtonWithIcon as ButtonWithIcon } from '../../buttons/button-with-icon';
import { CoachMessage } from '../../coach-message';
import { InputGroup } from '../../inputs/input-group';
import { Modal } from '../../modal';

export const Confirm = React.memo(
  ({
    show,
    label = 'Confirm Modal',
    children,
    html = children ? void 0 : '<p>Are you sure???</p>',
    onAccept,
    onDecline,
    acceptLabel = 'Yes',
    declineLabel = 'No',
    acceptType = 'destructive',
    declineType = 'major'
  }) => {
    return (
      <Modal label={label} show={show} onClose={onDecline}>
        <CoachMessage html={html}>{children}</CoachMessage>
        <InputGroup type='coach'>
          <ButtonWithIcon type={acceptType} onClick={onAccept}>
            {acceptLabel}
          </ButtonWithIcon>
          <ButtonWithIcon type={declineType} onClick={onDecline}>
            {declineLabel}
          </ButtonWithIcon>
        </InputGroup>
      </Modal>
    );
  }
);

export const useConfirm = options => {
  const [visible, setVisibility] = useState(false);

  const callback = useRef(options?.callback);
  const props = useRef(options?.props);

  const confirm = useCallback((cb, p) => {
    if (FunctionShim.isFunction(cb)) {
      callback.current = cb;
    }

    if (ObjectShim.isObject(p)) {
      props.current = { ...props.current, ...p };
    }

    setVisibility(true);
  }, []);

  const accept = useCallback(() => {
    const cb = callback.current;

    if (FunctionShim.isFunction(cb)) {
      cb();
    }

    setVisibility(false);
  }, []);

  const decline = useCallback(() => {
    setVisibility(false);
  }, []);

  return [<Confirm show={visible} onAccept={accept} onDecline={decline} {...props.current} />, confirm];
};
