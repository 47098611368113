import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { ConfigService } from '../../services/config-service';
import { store } from '../../store';

export const buildAddress = location => {
  if (location) {
    const path = ConfigService.get('PROPERTY_PORTFOLIO.ADDRESS.DATA_DICTIONARY');

    const dictionary = ObjectShim.getNested(store.getState().staticData, path);

    return (
      dictionary?.items?.reduce((address, { key, separator = '' }) => {
        const value = location[key];

        if (value) {
          return address + value + separator;
        }

        return address;
      }, '') ?? ''
    );
  }

  return '';
};

export const mapAddressAsSelectItem = address => {
  return (
    address && {
      id: address.Uprn,
      label: buildAddress(address),
      value: address
    }
  );
};
