import { compose, withHandlers } from 'recompose';
import { parse } from 'query-string';
import { changeLastPartOfPath } from '../../helpers/utils';
import { GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND } from '../../helpers/constants';
import { fromMode, getLocationFromModeState, withSearchParams } from '../../helpers/navigation/routing';
import { processAdditionalValues } from './helpers/parse-additional-values';

export const withInputHandlers = compose(
  withHandlers({
    submitGoalsLocation:
      ({ history, location: { pathname, search }, nextPageLink }) =>
      response => {
        const { goalsLocation, goalsLocationParsed = false } = response;

        const isEngland = goalsLocation === GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND;

        if (isEngland) {
          return history.push(changeLastPartOfPath(pathname, '/region-england'));
        }

        const isParsed = goalsLocationParsed && Object.values(goalsLocationParsed).some(item => item);

        if (isParsed) {
          const mode = search && parse(search).mode;
          const query = mode ? `?mode=${mode}` : '';

          return history.push(nextPageLink + query);
        }

        history.push(changeLastPartOfPath(pathname, '/region'));
      },
    submitLocation:
      ({ history, location: { pathname, search }, nextPageLink }) =>
      (response, key) => {
        // API Endpoint is aimed to always return next interface
        // [userAttributeName]: <Object>: <Location>
        // [${userAttributeName}LocationParsed]: <Object>: <ParsedLocation>
        const { [key]: location, [`${key}LocationParsed`]: locationParsed = false } = response;

        const isEngland = location?.formatted_address === GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND;

        if (isEngland) {
          return history.push(changeLastPartOfPath(pathname, '/region-england'));
        }

        const isParsed = locationParsed && Object.values(locationParsed).some(item => item);

        if (isParsed) {
          const mode = search && parse(search).mode;
          const query = mode ? `?mode=${mode}` : '';

          return history.push(nextPageLink + query);
        }

        history.push(changeLastPartOfPath(pathname, '/region'));
      },
    goToNextQuestion:
      ({ history, location: { search, state }, nextPageLink }) =>
      () => {
        //Prevents skipping questions when user is going to edit results
        const origin = getLocationFromModeState(state);

        history.push(fromMode(withSearchParams(nextPageLink, search), origin));
      }
  }),
  withHandlers({
    submit:
      ({ data, value, sendUserAttribute, sendAdditionalUserAttributes, goToNextQuestion }) =>
      async () => {
        const { userAttributeField, userAttributeValue, redirectAfterSave, additionalValues, staticPayload } = data;

        const sendMain = () => {
          if (userAttributeField) {
            return sendUserAttribute({
              [userAttributeField]: userAttributeValue !== undefined ? userAttributeValue : value,
              ...staticPayload
            });
          }
        };

        const sendAdditional = async () => {
          if (additionalValues) {
            const processedValues = await processAdditionalValues(additionalValues);

            if (processedValues) {
              return sendAdditionalUserAttributes(processedValues);
            }
          }
        };

        // redirectAfterSave is mandatory if next question relies on data sent on previous question
        // e.g pension finder question currently-employed which is right before company-name
        // company-name relies on result of prev question
        if (redirectAfterSave) {
          //waiting for server to respond and redirect after all endpoints are resolved
          await sendMain();
          await sendAdditional();

          goToNextQuestion();
        } else {
          //not waiting for server response, optimistic UI approach
          sendMain();
          sendAdditional();

          goToNextQuestion();
        }
      },
    onWheelChange:
      ({ onChange }) =>
      () =>
      value =>
        onChange(value),
    onChangeCheckbox:
      ({ value, onChange }) =>
      (name, boundValue) =>
      e =>
        onChange({ ...value, [name]: { checked: e ? e.target.checked : false, value: boundValue } }),
    onChangeRadio:
      ({ onChange }) =>
      (name, boundValue) =>
      e =>
        onChange({ [name]: { checked: e ? e.target.checked : false, value: boundValue } }),
    onChangeCheckboxText:
      ({ value, onChange }) =>
      name =>
      inputValue =>
        onChange({
          ...value,
          [name]: {
            text: inputValue,
            checked: true
          }
        }),
    onChangeRadioText:
      ({ onChange }) =>
      name =>
      inputValue =>
        onChange({
          [name]: {
            text: inputValue,
            checked: true
          }
        }),
    onLocationSubmit:
      ({ value, data, sendUserAttribute, submitGoalsLocation, submitLocation }) =>
      async () => {
        const { userAttributeField } = data;
        const response = await sendUserAttribute({ [userAttributeField]: value });

        // TODO: Refactor it on API endpoint readiness.
        return userAttributeField === 'goalsGeolocation'
          ? submitGoalsLocation(response)
          : submitLocation(response, userAttributeField);
      },
    onSelect:
      ({ onChange }) =>
      () =>
      value =>
        onChange(value)
  })
);
