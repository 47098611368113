import { isDevelopmentMode } from '../helpers/main';
import { dashboardRoutes } from './dashboard';
import { articlesRoutes } from './articles';
import { homePageRoutes } from './home-pages';
import { statusPageRoutes } from './status-page';
import { customPageRoutes } from './custom-page';
import { legalPageRoutes } from './legal-page';
import { accountsRoutes } from './account';
import { blogRoutes } from './blog';
import { rentReportingRoutes } from './rent-reporting';
import { questions } from './questions';
import { tourPageRoutes } from './tour-page';
import { pointsRoutes } from './points';
import { demoPageRoutes } from './demo';
import { propertyPageRoutes } from './property-page';

export const routes = () => {
  const {
    DISPLAY_MODULES: { DASHBOARD, ARTICLES, BLOGHOMEPAGE, ACCOUNT, POINTS }
  } = window.CONFIG;

  return [
    ...(DASHBOARD ? [...dashboardRoutes] : []),
    ...(ARTICLES ? [...articlesRoutes] : []),
    ...(BLOGHOMEPAGE ? [...blogRoutes] : []),
    ...propertyPageRoutes,
    ...homePageRoutes,
    ...statusPageRoutes,
    ...customPageRoutes,
    ...legalPageRoutes,
    ...(ACCOUNT ? [...accountsRoutes] : []),
    ...rentReportingRoutes,
    ...tourPageRoutes,
    ...questions,
    ...(POINTS ? [...pointsRoutes] : []),
    ...(isDevelopmentMode ? [...demoPageRoutes] : [])
  ];
};
