import React from 'react';
import { compose, withProps, branch, renderComponent } from 'recompose';
import { withRouter, NavLink as RouterNavLink, Link as RouterLink } from 'react-router-dom';
import { openLink } from '../helpers/utils';

const Button = React.memo(({ type, className, onClick, children }) => (
  <button data-type={type} className={className} onClick={onClick}>
    {children}
  </button>
));

const ExternalLink = React.memo(({ to, history, ...props }) => {
  const handleClick = () => openLink(to, history, true);

  return <Button type='external' onClick={handleClick} {...props} />;
});

const BlockedLink = React.memo(props => <Button type='blocked' disabled {...props} />);

const ButtonLink = React.memo(props => <Button type='link' {...props} />);

const LinkComponent = React.memo(({ to, replace, className, style, children, onClick }) => (
  <RouterLink to={to} replace={replace} onClick={onClick} style={style} className={className}>
    {children}
  </RouterLink>
));

const NavLinkComponent = React.memo(({ to, replace, className, activeClassName, children, onClick }) => (
  <RouterNavLink to={to} replace={replace} activeClassName={activeClassName} className={className} onClick={onClick}>
    {children}
  </RouterNavLink>
));

const Enhance = compose(
  withRouter,
  branch(({ isExternal }) => isExternal, renderComponent(ExternalLink)),
  branch(({ isBlocked }) => isBlocked, renderComponent(BlockedLink)),
  branch(({ isButton }) => isButton, renderComponent(ButtonLink)),
  withProps(({ replace, location, to }) => ({
    replace: typeof replace === 'function' ? replace(to, location) : to === location.pathname
  }))
);

export const Link = Enhance(LinkComponent);
export const NavLink = Enhance(NavLinkComponent);
