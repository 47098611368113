import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Checklist as ChecklistMenu } from '../../components/templates/checklist';
import { Footer } from '../../components/layout/page-footer';
import { withMetaTags } from '../../components/meta-tags';

const SEO_PAGE_DATA_NAME = 'checklist';

const ChecklistComponent = () => (
  <>
    <ChecklistMenu />
    <Footer />
  </>
);

const mapStateToProps = state => ({
  metaData: state.content.seoPages[SEO_PAGE_DATA_NAME]
});

export const Checklist = compose(connect(mapStateToProps), withMetaTags(SEO_PAGE_DATA_NAME))(ChecklistComponent);
