import React from 'react';
import { compose, withHandlers } from 'recompose';
import { Textarea } from '../textarea';
import { InputGroup } from '../input-group';
import { withContainer } from '../input-field';
import { InputRadio } from './radio';

export const RadioGroup = React.memo(({ items = [], onChange, onTextChange, className, value }) => {
  return (
    <InputGroup heir className={className}>
      {items.map(item => (
        <Radio key={item.name} value={value} data={item} onChange={onChange} onTextChange={onTextChange} />
      ))}
    </InputGroup>
  );
});

const RadioComponent = React.memo(({ data, onChange, onTextChange, getValueByName }) => {
  const { name, label, explainer, value, type, maxLength, placeholder, helpModal } = data;

  const { checked, text } = getValueByName(name);

  return (
    <>
      <InputRadio
        label={label}
        informer={helpModal}
        explainer={explainer}
        checked={checked}
        onChange={onChange(name, value)}
      />
      {checked && type && (
        <Textarea placeholder={placeholder} maxLength={maxLength} value={text} onChange={onTextChange(name)} />
      )}
    </>
  );
});

const Radio = compose(
  withHandlers({
    getValueByName:
      ({ value = {} }) =>
      name =>
        value[name] ?? {
          text: '',
          checked: false
        }
  })
)(RadioComponent);

export const InputRadioGroup = withContainer(RadioGroup);
