import { get, post } from './utils';

const getUserAttributes = async type => get(`/user-attr${type ? `/${type}` : ''}`);
const sendUserAttribute = async ({ type, params }) => post(`/user-attr/${type}`, params);
const getUserAttributesSettings = async () => get(`/user-attribute-settings`);
const sendAdditionalUserAttributes = async params => post(`/user-attr`, params);

export const userAttributes = {
  getUserAttributes,
  sendUserAttribute,
  getUserAttributesSettings,
  sendAdditionalUserAttributes
};
