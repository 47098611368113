import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

export const QuestionNiNInput = React.memo(({ label, onChange, value, submit, errorMessages }) => (
  <Input
    type='text'
    inputType='text'
    label={label}
    format='upper'
    value={value}
    min={9}
    max={9}
    errorMessages={errorMessages}
    mask='aa999999a'
    maskChar={null}
    onChange={onChange}
    onSubmit={submit}
  />
));
