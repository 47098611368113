import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import styles from '../../styles/components/input-label.module.scss';
import { toggleModal } from '../../store/reducers/modals';
import { InformerIcon } from '../icons/informer-icon';
import { pickProps } from '../../helpers/hocs/pick-props';

const Informer = compose(
  connect(null, { toggleModal }),
  withHandlers({
    onClick:
      ({ data, toggleModal }) =>
      () => {
        toggleModal(true, 'help', { buttonEnabled: false, ...data });
      }
  }),
  pickProps(['onClick'])
)(InformerIcon);

export const Label = React.memo(({ htmlFor, informer, className, children }) => (
  <div className={styles.wrapper}>
    <div className={classnames({ [styles['no-informer']]: !informer })}>
      {informer && <Informer className={styles.informer} data={informer} />}
    </div>
    <div className={classnames(styles.inner, className)}>
      <label htmlFor={htmlFor}>{children}</label>
    </div>
  </div>
));

export const InputLabel = React.memo(({ inline = false, layout, label, children, ...props }) => (
  <div className={classnames(styles.container, layout)}>
    {label && <Label {...props}>{label}</Label>}
    {children}
  </div>
));
