import React from 'react';
import classnames from 'classnames';
import { Column } from '~/components/layout/grid';
import styles from '../../styles/components/spacers.module.scss';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';

const Component = (
  { type = 'section', keyline = false, background = false, renderAs: Component = Column, className, ...props },
  ref
) => (
  <Component
    ref={ref}
    className={classnames(
      styles[type],
      {
        [styles['keyline-top']]: keyline && keyline !== 'bottom',
        [styles['keyline-bottom']]: keyline && keyline === 'bottom',
        [styles.background]: background
      },
      className
    )}
    {...props}
  />
);

export const Spacer = withMemoAndRef(Component);
