import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import { parse } from 'query-string';
import { createCustomPageProps } from '../../../helpers/utils';
import { getLocationFromModeState } from '../../../helpers/navigation/routing';
import { goToComplete } from '../../../helpers/custom-page/go-to-complete';
import { FHC_MODULE_STATUSES } from '../../../helpers/constants';
import { RESULT } from '../../../helpers/navigation';
import { sendStatus } from '../../../store/reducers/status-data';
import { getCustomPage } from '../../../store/reducers/content';
import { withLoadingHandlers } from '../../with-loader';
import { Checkpoint } from '../checkpoint';

const CustomPageCompleteComponent = ({ to, loading }) => (
  <>
    <Helmet title='Complete' />
    <Checkpoint to={to} loading={loading} calculatingMsg='Updating your results' />
  </>
);

export const CustomPageComplete = compose(
  connect(null, { getCustomPage, sendStatus }),
  withProps(
    ({
      location: { pathname, state },
      match: {
        params: { redirectTo = RESULT }
      }
    }) => {
      const toOrigin = getLocationFromModeState(state);

      return {
        toResult: !toOrigin && redirectTo === RESULT,
        to: toOrigin ?? goToComplete(pathname, redirectTo)
      };
    }
  ),
  withLoadingHandlers(({ toResult }) => toResult),
  withHandlers({
    getPageByTrigger:
      ({ location, sendStatus, getCustomPage, turnOffLoading }) =>
      trigger => {
        const { pathname, search } = location;
        const { name } = createCustomPageProps(pathname);
        const { activityName = name, activityStatus = FHC_MODULE_STATUSES.COMPLETED } = parse(search);

        sendStatus(activityName, activityStatus, () => {
          getCustomPage({ name, trigger, full: `${name}-${trigger}`, from: pathname });
          turnOffLoading();
        });
      }
  }),
  lifecycle({
    componentDidMount() {
      const { toResult, getPageByTrigger } = this.props;

      if (toResult) {
        getPageByTrigger(RESULT);
      }
    }
  })
)(CustomPageCompleteComponent);
