import React from 'react';
import { connect } from 'react-redux';
import { compose, branch, renderComponent } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { InputButtonWithIcon as ButtonWithIcon } from '../../buttons/button-with-icon';
import { getNextActivity } from '../../../store/reducers/digital-coach';
import { sendStatus } from '../../../store/reducers/status-data';
import { sendAdditionalUserAttributes } from '../../../store/reducers/user-attributes/actions';
import { statusData } from '../../../api/status-data';
import {
  TYPE_ORIGIN_RATE_APP,
  TYPE_ORIGIN_SNOOZE,
  TYPE_ORIGIN_UPDATE_MARKETING_LISTS
} from '../../../helpers/digital-coach/constants';
import { SnoozeButton } from './buttons/snooze';
import { MarketingButton } from './buttons/marketing-preferences';
import { CustomButton } from './buttons/custom';
import { RateAppButton } from './buttons/rate';

export const CustomAction = compose(
  branch(({ originType }) => originType === TYPE_ORIGIN_SNOOZE, renderComponent(SnoozeButton)),
  branch(({ originType }) => originType === TYPE_ORIGIN_UPDATE_MARKETING_LISTS, renderComponent(MarketingButton)),
  branch(({ originType }) => originType === TYPE_ORIGIN_RATE_APP, renderComponent(RateAppButton))
)(CustomButton);

const ReloadActionComponent = React.memo(
  ({ label, activityKey, statusKey, sendStatus, attributes, getNextActivity, sendAdditionalUserAttributes }) => {
    const onClick = async () => {
      if (attributes && !ObjectShim.isEmpty(attributes)) {
        await sendAdditionalUserAttributes(ObjectShim.deepen(attributes));
      }
      if (activityKey) {
        sendStatus(activityKey, statusKey, getNextActivity);
      } else {
        await statusData.recalculateActivity();
        getNextActivity();
      }
    };

    return <ButtonWithIcon onClick={onClick}>{label}</ButtonWithIcon>;
  }
);

export const ReloadAction = connect(null, { sendStatus, getNextActivity, sendAdditionalUserAttributes })(
  ReloadActionComponent
);

export const Action = React.memo(({ label, link, isExternal, activityKey, statusKey }) => (
  <ButtonWithIcon to={link} isExternal={isExternal} activity={activityKey} status={statusKey}>
    {label}
  </ButtonWithIcon>
));
