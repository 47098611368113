import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { store } from '../../../store';
import { interpolateRegex } from '../../../helpers/regex';
import { insertVarBeforeInterpolation } from '../../../helpers/insert-var-into-string';

// eslint-disable-next-line import/no-webpack-loader-syntax
import * as workerBase from 'workerize-loader!../../../helpers/workers/evaluate-condition';

const worker = workerBase();

//The main idea here is to prevent possible XSS attacks
//For those reasons we are evaluating code inside web worker which doesn't have an access to window, local storage, etc.

//If you want to compare strings. You must wrap condition in admin console into `` (e.g `${goal.location}` !== `London`)
export const evaluateCondition = async (condition, params) => {
  //if error occurs we set false
  try {
    const parsedCondition = parse(condition, params);

    return await worker.evaluate(parsedCondition);
  } catch {
    return false;
  }
};

export const parse = (value, params) => {
  if (typeof value !== 'string') {
    return value;
  }

  value = insertVarBeforeInterpolation(value, { ...params });

  const userAttributes = store.getState().userAttributes;

  return value.replace(interpolateRegex, (_, name) => {
    //here we access any nesting level of parent
    return ObjectShim.getNested(userAttributes, name);
  });
};
