import React from 'react';
import { HeadingVMPlan } from '../vm-plan-widget/components/heading';
import { HeadingVMMortgage } from '../vm-mortgage-widget/heading';
import { HeadingVMPoints } from '../points-widget/components/heading';

const headings = {
  'vm-plan': HeadingVMPlan,
  'what-is-within-reach': HeadingVMMortgage,
  'vm-points': HeadingVMPoints
};

export const CustomPageHeadings = props => {
  if (props.name && headings[props.name]) {
    const CustomHeading = headings[props.name];

    return <CustomHeading {...props} />;
  }

  return props.children;
};
