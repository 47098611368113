import React from 'react';
import compose from 'recompose/compose';
import classnames from 'classnames';
import styles from '../../../styles/components/radio.module.scss';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { withAnchor, withContainer, withExplainer, withLabel } from '../input-field';

const Input = React.memo(({ id, className, ...props }) => (
  <input type='radio' id={id} className={classnames(styles.input, className, 'thrown-away')} {...props} />
));

const Component = React.memo(({ id, icon = <AffiliateIcon name='radio-input' />, value = false, pulse, ...props }) => (
  <div>
    <Input id={id} className={styles.field} value={value} {...props} />
    <label htmlFor={id} className={classnames(styles.icon, { pulse })}>
      {icon}
    </label>
  </div>
));

export const Radio = withAnchor(Component);
export const InputRadio = compose(
  withAnchor,
  withContainer,
  withExplainer,
  withLabel({ className: styles.label, layout: styles.container })
)(Component);
