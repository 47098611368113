import dayjs from 'dayjs';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';

export const applyAdditionalRules = (type, currentValidation, rulesToApply) => {
  if (!additionalRules[type] || !ObjectShim.isObject(rulesToApply)) {
    return;
  }

  Object.keys(rulesToApply).forEach(key => {
    if (additionalRules[type][key]) {
      currentValidation.rule(value => {
        try {
          return additionalRules[type][key](value);
        } catch {
          return true;
        }
      }, rulesToApply[key]);
    }
  });
};

const additionalRules = {
  date: {
    'date-of-birth': value => {
      const date = dayjs(value, 'DD/MM/YYYY');
      const today = dayjs();

      return today.diff(date, 'year') >= 16;
    }
  }
};
