import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose, withState, lifecycle, withHandlers } from 'recompose';
import styles from '../../styles/components/checkpoint.module.scss';
import { ConfigService } from '../../services/config-service';
import { TickIcon } from '../../icons/tick';
import { Animation } from '../layout/animation';
import { Screen } from '../layout/screen';
import { Spinner } from '../with-loader';

const Loading = React.memo(({ lottie }) => {
  return lottie ? <Animation path={lottie.loading} /> : <Spinner />;
});

const Done = React.memo(({ lottie, callback }) => {
  return lottie ? <Animation path={lottie.done} loop={false} onComplete={callback} /> : <TickIcon />;
});

const CheckpointTemplate = React.memo(({ handleDone, calculating, calculatingMsg, completeMsg }) => {
  const {
    lottie,
    labels: { loading, done }
  } = ConfigService.get('CHECKPOINT', { labels: {} });

  return (
    <Screen className={styles.container}>
      <div className={classnames(styles.icon, { [styles.simple]: !lottie })}>
        {calculating ? <Loading lottie={lottie} /> : <Done lottie={lottie} callback={handleDone} />}
      </div>
      <h2 className={styles.label}>{calculating ? calculatingMsg ?? loading : completeMsg ?? done}</h2>
    </Screen>
  );
});

export const Checkpoint = compose(
  withRouter,
  withState('calculating', 'setCalculating', true),
  withState('done', 'setDone', false),
  withHandlers({
    handleDone:
      ({ setDone }) =>
      () => {
        setDone(true);
      }
  }),
  withHandlers({
    handleComplete:
      ({ setCalculating, handleDone }) =>
      () => {
        const { lottie, timeouts } = ConfigService.get('CHECKPOINT', {});

        setTimeout(() => {
          setCalculating(false);

          !lottie && setTimeout(handleDone, timeouts.done);
        }, timeouts.loading);
      }
  }),
  lifecycle({
    componentDidMount() {
      const { loading, setCalculating, handleDone } = this.props;

      const { timeouts } = ConfigService.get('CHECKPOINT', {});

      if (!loading) {
        setTimeout(() => {
          setCalculating(false);

          setTimeout(handleDone, timeouts.done);
        }, timeouts.loading);
      }
    },
    componentDidUpdate(prevProps) {
      const { loading, done, handleComplete } = this.props;

      if (prevProps.loading !== loading && !loading) {
        handleComplete();
      }

      if (prevProps.done !== done && done) {
        const { history, to } = this.props;

        history.replace(to);
      }
    }
  })
)(CheckpointTemplate);
