import React, { useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';
import classnames from 'classnames';
import { useDidUpdateEffect } from '../../helpers/hooks/use-update-effect';
import { scrollTo } from '../../helpers/layout/scroll';
import { ARTICLE, CUSTOM } from '../../helpers/navigation';
import { LAYOUT_SECTIONS } from '../../helpers/layout/sections';
import { KnowledgeBase } from '../templates/knowledge-base';
import { Checklist } from '../templates/checklist';
import { BREAKPOINTS_MATCHES } from '../with-resize';
import { isVisible, useLayoutSection } from './with-layout';
import { Footer } from './page-footer';

const SidebarComponent = React.memo(({ basePath, className }) => {
  const ref = useLayoutSection(LAYOUT_SECTIONS.SIDEBAR, 'clientWidth');

  const scrollSidebarTo = useCallback(
    (top, options = { incremental: false, smooth: false }) => {
      const sidebar = ref.current;

      if (sidebar) {
        const { incremental, smooth } = options;

        scrollTo(sidebar, { top, incremental, behavior: smooth ? 'smooth' : 'auto' });
      }
    },
    [ref]
  );

  useDidUpdateEffect(() => {
    scrollSidebarTo(0);
  }, [basePath, scrollSidebarTo]);

  return (
    <aside ref={ref} className={classnames('sidebar', className)}>
      <Provider value={scrollSidebarTo}>
        <Switch>
          <Route path='/checklist' component={Checklist} />
          <Route path='/articles' component={KnowledgeBase} />
        </Switch>
        <Footer />
      </Provider>
    </aside>
  );
});

const Sidebar = isVisible({
  forTemplates: [CUSTOM, ARTICLE],
  onBreakpoints: [BREAKPOINTS_MATCHES.LG_AND_UP]
})(SidebarComponent);

const SidebarContext = React.createContext();
const { Provider, Consumer } = SidebarContext;

export { Sidebar, SidebarContext, Consumer as SidebarConsumer };
