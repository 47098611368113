import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { createCustomPageProps } from '../../../helpers/utils';
import { Question } from '../index';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../components/buttons/button-with-icon';
import {
  ACCOUNT_TYPES,
  REQUIRED_PROPS_COUNT_LISA,
  DEFAULT_ACCOUNTS_PAGE_MESSAGE,
  getAccountTypes,
  hasAccount as hasAccountFunc
} from '../../../helpers/deposit-builder/accounts';
import { CreditCard } from '../../../icons/deposit-builder/credit-card';
import { sendAccounts } from '../../../store/reducers/user-attributes/actions';
import { enhanceName } from '../helpers/prettify-name';
import { ConfigService } from '../../../services/config-service';

export const DepositBuilderAccounts = React.memo(({ onClick, accounts, disabled, ...rest }) => {
  const hasAccount = hasAccountFunc(accounts);
  const ACCOUNT_TYPES_DATA = getAccountTypes();

  const noAccountsMessage = ConfigService.get(
    'DEPOSIT_BUILDER.ACCOUNTS_PAGE.noAccountsMessage',
    DEFAULT_ACCOUNTS_PAGE_MESSAGE.noAccounts
  );

  const hasAccountsMessage = ConfigService.get(
    'DEPOSIT_BUILDER.ACCOUNTS_PAGE.hasAccountsMessage',
    DEFAULT_ACCOUNTS_PAGE_MESSAGE.hasAccounts
  );

  const message = hasAccount ? hasAccountsMessage : noAccountsMessage;

  return (
    <Question message={message} {...rest}>
      {() => (
        <>
          {hasAccount && (
            <ButtonWithIcon to='/checklist/deposit-builder/nearly-there' type='minor'>
              That’s it, no more accounts
            </ButtonWithIcon>
          )}
          {Object.keys(ACCOUNT_TYPES_DATA).map(key => (
            <ButtonWithIcon
              disabled={disabled(key)}
              key={key}
              onClick={onClick({ type: key, title: ACCOUNT_TYPES_DATA[key] })}
              icon={<CreditCard />}
              type='account'
              account={key}
            >
              {ACCOUNT_TYPES_DATA[key]}
            </ButtonWithIcon>
          ))}
          {!hasAccount && (
            <ButtonWithIcon to='/checklist/deposit-builder/existing-savings' type='minor'>
              I’ve not started saving yet
            </ButtonWithIcon>
          )}
        </>
      )}
    </Question>
  );
});

const mapStateToProps = (state, ownProps) => {
  const params = createCustomPageProps(ownProps.location.pathname);

  return {
    accounts: state.userAttributes.accounts.items,
    match: { params: { activity: params.name, slug: params.trigger } }
  };
};

export const DepositBuilderAccountsQuestion = compose(
  connect(mapStateToProps, { sendAccounts }),
  withHandlers({
    onClick:
      ({ sendAccounts, history, accounts }) =>
      item =>
      async () => {
        const previousState = accounts;
        const type = item.type;
        const name = item.title;
        const prettifiedName = enhanceName(previousState, '-')(type);

        if (type === 'others') {
          history.push('/checklist/deposit-builder/name-your-account');
        } else {
          await sendAccounts({
            items: {
              ...previousState,
              [prettifiedName]: { ...previousState[prettifiedName], name, type }
            }
          });

          history.push(`/checklist/deposit-builder/total-in-account/${prettifiedName}`);
        }
      },
    disabled:
      ({ accounts }) =>
      type => {
        if (type === ACCOUNT_TYPES.LISA || type === ACCOUNT_TYPES.HTB) {
          return Object.values(accounts).some(
            item => item.type === type && Object.keys(item).length === REQUIRED_PROPS_COUNT_LISA
          );
        }

        return false;
      }
  })
)(DepositBuilderAccounts);
