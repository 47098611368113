import React from 'react';
import { helpers } from '../../../api/helpers';
import { withAutoCompleteHandlers } from './components/autocomplete/handlers';
import { AutocompleteInput } from './components/autocomplete';

const Input = withAutoCompleteHandlers({
  valueName: 'formatted_address',
  userAttributeName: 'goal.goalsGeolocation',
  method: helpers.getGeocode
})(AutocompleteInput);

export const QuestionAddressInput = React.memo(({ label, value, onChange, onLocationSubmit }) => (
  <Input label={label} value={value} onChange={onChange} onSubmit={onLocationSubmit} />
));
