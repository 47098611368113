import React from 'react';
import { compose, lifecycle, withHandlers, withState, withPropsOnChange } from 'recompose';
import { SelectService } from '../../services/select-service';
import { InputSelect } from '../inputs/select';
import styles from '../../styles/components/category-filter.module.scss';

export const CategoryFilterComponent = React.memo(({ select, values, handleSelect, filterTitle }) => {
  return (
    <div className={styles.filter}>
      <InputSelect
        label={filterTitle ?? 'Categories'}
        items={values}
        value={String(select)}
        defaultSelected={value => value.key}
        name='select'
        onChange={handleSelect}
      />
    </div>
  );
});
export const CategoryFilter = compose(
  withState('select', 'setSelect', ({ selectName, value }) => SelectService.getSelects({ selectName, value })),
  withState('values', 'setValues', [
    {
      id: 0,
      label: 'All',
      value: {
        key: 'All',
        title: 'All'
      },
      disabled: false
    }
  ]),
  withPropsOnChange(['items', 'accessor'], ({ items, accessor }) => ({
    categories: items
      .filter(Boolean)
      .map(item => {
        const key = typeof accessor === 'function' ? accessor(item) : accessor;
        return item?.[key] ?? 'All';
      })
      .sort()
  })),
  withHandlers({
    setSelectValues:
      ({ categories, values, setValues }) =>
      () => {
        let selectValuesItems = categories
          .filter((unique, item) => {
            return categories.indexOf(unique !== 'All' ? unique : '') === item;
          })
          .map((item, index) => ({
            id: index + 1,
            label: item,
            value: {
              key: item,
              title: item
            },
            disabled: false
          }));
        setValues([...values, ...selectValuesItems]);
      },
    handleSelect:
      ({ setSelect, handleFilterChange, selectName }) =>
      name =>
      value => {
        const toggleSelectValue = SelectService.setSelects({ name: selectName, value: value.key });
        setSelect(toggleSelectValue);
        handleFilterChange(value.key);
      },
    selectStorageHandler:
      ({ setSelect, handleFilterChange, selectName }) =>
      () => {
        let currentSelectedValue = SelectService.getSelects();
        setSelect(currentSelectedValue?.[selectName] ?? 'All');
        handleFilterChange(currentSelectedValue?.[selectName] ?? 'All');
      }
  }),
  lifecycle({
    componentDidMount() {
      const { selectStorageHandler, setSelectValues } = this.props;

      selectStorageHandler();
      setSelectValues();
    }
  })
)(CategoryFilterComponent);
