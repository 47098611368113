export const getConfig = async () => {
  const configAffiliate = parseAffiliate(true); // eslint-disable-line
  const hourStamp = new Date().setHours(new Date().getHours(), 0, 0, 0);

  return fetch(`${process.env.REACT_APP_CONFIG_BUCKET_URL}/${configAffiliate}/config.json?nocache=${hourStamp}`, {
    method: 'GET'
  }).then(response => {
    if (!response.ok) {
      return;
    }

    return response.json();
  });
};
