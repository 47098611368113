import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import { getQuestions } from '../../store/reducers/questions';
import { evaluateCondition } from './helpers/parse-condition';
import { parseMessage } from './helpers/parse-message';

const mapStateToProps = (state, ownProps) => {
  const { activity, slug } = ownProps.match.params;

  return {
    question: state.questions.questions[activity] && state.questions.questions[activity][slug]
  };
};

export const withQuestion = compose(
  connect(mapStateToProps, { getQuestions }),
  withState('questionMessage', 'setMessage', null),
  withHandlers({
    skipQuestion:
      ({
        question,
        history,
        location,
        match: {
          params: { identifier }
        }
      }) =>
      async () => {
        const mode = location.search && parse(location.search).mode;

        //Skip question if there is condition and it is not edit mode
        if (!mode && question?.skipQuestion) {
          const skipCondition = await evaluateCondition(question.skipQuestion.condition, { identifier });

          const nextPageLink = `${question.skipQuestion.nextPageLink}${identifier ? '/' + identifier : ''}`;

          if (skipCondition) {
            history.replace(nextPageLink);
            return true;
          }
        }

        return false;
      },
    setMessage:
      ({
        question,
        message,
        setMessage,
        match: {
          params: { identifier }
        }
      }) =>
      async () => {
        const questionMessage = await parseMessage(message ?? question.text, { identifier });

        setMessage(questionMessage);
      }
  }),
  withHandlers({
    onQuestionChange:
      ({ skipQuestion, setMessage }) =>
      async () => {
        const isSkipped = await skipQuestion();

        if (!isSkipped) {
          await setMessage();
        }
      }
  }),
  lifecycle({
    async componentDidMount() {
      const {
        getQuestions,
        match: {
          params: { activity }
        },
        question,
        onQuestionChange
      } = this.props;

      if (!question) {
        await getQuestions(activity);
      }

      await onQuestionChange();
    },
    async componentDidUpdate(prevProps) {
      const { question, onQuestionChange } = this.props;

      if (prevProps.question !== question) {
        await onQuestionChange();
      }
    }
  })
);
