import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

export const QuestionPercentInput = React.memo(
  ({ label, onChange, format, value, max, min, submit, errorMessages }) => (
    <Input
      type='tel'
      label={label}
      onChange={onChange}
      format={format}
      value={value}
      maxLength={3}
      onSubmit={submit}
      max={max}
      min={min}
      errorMessages={errorMessages}
      mask='9.99'
      maskChar='0'
    />
  )
);
