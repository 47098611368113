import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Modal } from '../../modal';
import { toggleModal } from '../../../store/reducers/modals';
import { InputGroup } from '../../inputs/input-group';
import { InputButtonWithIcon as ButtonWithIcon } from '../../buttons/button-with-icon';
import { PurifiedHtmlContainer } from '../purified-html-container';
import { Spacer } from '../spacers';

const HelpModalComponent = React.memo(
  ({ show, modalContent: { title, body, buttonEnabled, buttonLabel, buttonOpenNewWindow, buttonLink }, hide }) => (
    <Modal title={title} show={show} onClose={hide}>
      <PurifiedHtmlContainer html={body} className='typography typography-extended' />
      {buttonEnabled && (
        <>
          <Spacer type='component' />
          <InputGroup>
            <ButtonWithIcon to={buttonLink} isExternal={buttonOpenNewWindow} onBeforeRoutePush={hide}>
              {buttonLabel}
            </ButtonWithIcon>
          </InputGroup>
        </>
      )}
    </Modal>
  )
);

const mapStateToProps = state => ({
  show: state.modals.showHelp,
  modalContent:
    state.faq.questions?.[state.modals.params.faqName]?.[state.modals.params.faqIndex] || state.modals.params || {}
});

const mapDispatchToProps = dispatch => ({
  hide: () => {
    dispatch(toggleModal(false, 'help'));
  }
});

export const HelpModal = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        location: { pathname },
        show,
        hide
      } = this.props;

      if (prevProps.location.pathname !== pathname && show) {
        hide();
      }
    }
  })
)(HelpModalComponent);
