import React from 'react';
import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import styles from '../../../styles/components/checklist-item.module.scss';
import { getStatusPlain, redirectToModule } from '../../../helpers/utils';
import { FHC_MODULE_STATUSES } from '../../../helpers/constants';
import { setNavChecklistLink } from '../../../store/reducers/navigation';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { NavLink } from '../../link';
import { withDisplayCondition } from './with-display-condition';

const ChecklistItemComponent = React.memo(
  ({ to, iconName, iconStyle, status, item: { labelText, isExternal, link }, handleClick }) => (
    <li className={styles.container} style={iconStyle}>
      <NavLink
        to={to}
        isExternal={isExternal}
        isBlocked={!link}
        className={styles.button}
        activeClassName={styles.selected}
        onClick={handleClick}
      >
        <AffiliateIcon
          name={iconName}
          fallback={{ external: `checklist-indicator-${FHC_MODULE_STATUSES.TO_DO}` }}
          className={styles.status}
        />
        <p className={styles.label}>{labelText}</p>
        <AffiliateIcon name='checklist-action' className={styles.action} />
      </NavLink>
    </li>
  )
);

export const ChecklistItem = compose(
  connect(
    state => ({
      statuses: state.statusData.parsedStatuses,
      selected: state.navigation.checklistLink
    }),
    { setNavChecklistLink }
  ),
  withProps(({ statuses, item }) => {
    const { link, statusMappingDetails, displayCondition } = item;
    const status = getStatusPlain(statuses[statusMappingDetails])?.toLowerCase();

    return {
      to: redirectToModule(link, statusMappingDetails),
      iconName: `checklist-indicator-${status}`,
      iconStyle: {
        '--app-custom-checklist-item-bg-colour': `var(--checklist-item-checkbox-${status}-bg-colour)`,
        '--app-custom-checklist-item-icon-colour': `var(--checklist-item-checkbox-${status}-icon-colour)`,
        '--app-custom-checklist-item-border': `var(--checklist-item-checkbox-${status}-border)`
      },
      status,
      displayCondition
    };
  }),
  withHandlers({
    handleClick:
      ({ selected, item, setNavChecklistLink }) =>
      () => {
        if (selected.link !== item.link) {
          setNavChecklistLink(item);
        }
      }
  }),
  withDisplayCondition
)(ChecklistItemComponent);
