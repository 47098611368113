import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

export const QuestionEmailInput = React.memo(({ label, onChange, format, value, max, min, submit, errorMessages }) => (
  <Input
    type='email'
    inputType='email'
    label={label}
    onChange={onChange}
    format={format}
    value={value}
    onSubmit={submit}
    max={max}
    min={min}
    errorMessages={errorMessages}
  />
));
