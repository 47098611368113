import React from 'react';
import { compose } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import { CheckboxGroup } from '../../../components/inputs/checkbox/checkbox-group';
import { withButton, withContainer } from './components/input-field';

const Input = compose(withContainer, withButton)(CheckboxGroup);

export const QuestionCheckboxInput = React.memo(
  ({ onChangeCheckbox, onChangeCheckboxText, value, buttonLabel, submit, items }) => (
    <Input
      onChange={onChangeCheckbox}
      onTextChange={onChangeCheckboxText}
      value={value}
      buttonLabel={buttonLabel}
      onSubmit={submit}
      items={items}
      disabled={isNil(value) || !Object.values(value).some(item => item.checked)}
    />
  )
);
