import React from 'react';
import classnames from 'classnames';
import { compose, withHandlers, withState } from 'recompose';
import styles from '../styles/components/loader.module.scss';
import { AffiliateIcon } from './icons/affiliate-icon';

export const Spinner = ({ className }) => (
  <AffiliateIcon className={classnames(styles.spinner, className)} name='loading' />
);

export const Loader = ({ className }) => <Spinner className={classnames(styles.container, className)} />;

export const withLoader = Component => props => {
  if (props.loading) {
    return <Loader />;
  } else {
    return <Component {...props} />;
  }
};

export const withSpinner = Component => props => {
  if (props.loading) {
    return <Spinner />;
  } else {
    return <Component {...props} />;
  }
};

export const withLoadingHandlers =
  (initial = false) =>
  Component =>
    compose(
      withState('loading', 'setLoading', initial),
      withHandlers({
        toggleLoading:
          ({ setLoading }) =>
          () =>
            setLoading(loading => !loading),
        turnOnLoading:
          ({ setLoading }) =>
          () =>
            setLoading(true),
        turnOffLoading:
          ({ setLoading }) =>
          () =>
            setLoading(false)
      })
    )(Component);
