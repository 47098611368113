import React from 'react';
import classnames from 'classnames';
import { Grid, Column } from '~/components/layout/grid';
import styles from '../../styles/components/page-footer.module.scss';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { Link } from '../link';
import { CustomPageFooters } from '../widgets/components/footers';
import { AffiliateImage } from '../images/image';
import { ConfigService } from '../../services/config-service';
import { PurifiedHtmlContainer } from './purified-html-container';

const FooterContainer = React.memo(({ children, className }) => (
  <footer className={classnames(styles.container, className)}>
    <Grid>{children}</Grid>
  </footer>
));

export const Text = React.memo(({ children, ...props }) => (
  <div className={styles['text-container']}>
    <PurifiedHtmlContainer className={classnames('typography', styles.text)} {...props}>
      {children}
    </PurifiedHtmlContainer>
  </div>
));

export const LegalText = props => (
  <Column className={styles.legal}>
    <Text {...props} />
  </Column>
);

export const LegalFooter = React.memo(({ className, ...props }) => (
  <FooterContainer className={className}>
    <CustomPageFooters {...props}>
      <LegalText {...props} />
    </CustomPageFooters>
  </FooterContainer>
));

export const Footer = React.memo(() => {
  const { header, socials, logoLink, buttons, copyrightText } = ConfigService.get('FOOTER', {});

  return (
    <FooterContainer className={styles.homepage}>
      <Column>
        <div className={styles.body}>
          {header && (
            <section className={styles.header}>
              <p className={styles.title}>{header}</p>
            </section>
          )}
          {socials && (
            <ul className={styles.icons}>
              {socials.map(({ icon, link }) => (
                <li key={link} className={styles.icon}>
                  <Link to={link} isExternal>
                    <AffiliateIcon name={icon} />
                  </Link>
                </li>
              ))}
            </ul>
          )}
          {logoLink && (
            <section className={styles['logo-container']}>
              <Link to={logoLink}>
                <AffiliateImage className={styles.logo} name='footer-logo' extension='png' />
              </Link>
            </section>
          )}
          {buttons && (
            <Grid renderAs='ul' className={styles.buttons} fill>
              {buttons.map(({ labelText, link, isExternal }) => (
                <li key={labelText} className={styles.button}>
                  <Link to={link} isExternal={isExternal} className={styles.link}>
                    {labelText}
                  </Link>
                </li>
              ))}
            </Grid>
          )}
        </div>
        <Text>
          {[].concat(copyrightText).map((row, key) => (
            <p key={key}>{row}</p>
          ))}
        </Text>
      </Column>
    </FooterContainer>
  );
});
