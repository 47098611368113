import { get, patch, post, put, remove } from './utils';

const getUser = async shadowId => get(`/data-capture/users/${shadowId}`);
const createUser = async params => post(`/data-capture/users`, params);
const updateUser = async params => patch(`/user`, params);
const getAuthorizedUser = async () => get('/user');
const logout = async params => post('/logout', params);

const login = async params => post('/login', params);
const signUp = async params => post('/sign-up', params);
const confirmSignUp = async params => post('/confirm-sign-up', params);
const resendCodeSignUp = async params => post('/resend-sign-up ', params);
const resetCode = async params => post('/forgot-password-request', params);
const resetPassword = async params => post('/confirm-password', params);
const updatePassword = async params => post('/change-password', params);
const deleteAccount = async params => remove('/user', params);
const refreshSession = async params => post('/session', params);
const trackSession = async (params = {}) => post('/user/track-session', params);
const checkEmail = async params => post('/auth/is-email-available', params);
const getMarketingPreferences = async () => get('/marketing-lists');
const setMarketingPreferences = async params => post('/marketing-lists', params);
const getAdvertsByName = async () => get('/advertising/cards');
const getPropertyByUprnCode = async ({ uprn, propertyId, attributeName = 'property.items', addressKey }) =>
  get(
    `/property-data/load-property?uprn=${uprn}&attributeName=${attributeName}&propertyKey=${propertyId}&addressKey=${addressKey}`
  );
const getAlternativeMortgages = async ({ propertyId, attributeName = 'property.items' }) =>
  get(`/property-data/mortgage-deals?attributeName=${attributeName}&propertyKey=${propertyId}`);
const updateProperty = async params => put('/property-data/properties', params);
const sendRequestedDataViaEmail = async email => get(`/tools/request-my-data?email=${email}`);

export const users = {
  getUser,
  createUser,
  login,
  signUp,
  confirmSignUp,
  resendCodeSignUp,
  resetCode,
  resetPassword,
  getAuthorizedUser,
  updatePassword,
  logout,
  deleteAccount,
  refreshSession,
  updateUser,
  trackSession,
  checkEmail,
  getMarketingPreferences,
  setMarketingPreferences,
  getAdvertsByName,
  getPropertyByUprnCode,
  getAlternativeMortgages,
  updateProperty,
  sendRequestedDataViaEmail
};
