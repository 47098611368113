import React from 'react';
import { branch } from 'recompose';
import { isNil } from '@packages/helpers/core/common';

const renderAsButton =
  Component =>
  ({ type, label, onClick, className, ...props }) => {
    const { name, inline } = props;

    return (
      <button
        style={{ display: inline ? 'inline-block' : 'block' }}
        type={type}
        className={className}
        data-label={label ?? name}
        onClick={onClick}
      >
        <Component {...props} />
      </button>
    );
  };

export const withButton = branch(({ onClick }) => !isNil(onClick), renderAsButton);
