import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../../styles/widgets/points/vm-points.module.scss';
import { insertVarIntoString } from '../../../../helpers/insert-var-into-string';
import { PurifiedHtmlContainer } from '../../../layout/purified-html-container';
import { PieChart } from '../../../charts/pie-chart';

const Component = React.memo(({ value, total, nextStep, title, pointsText, allAwardsUnlockedText, nextUnlockText }) => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        {title && <p className={styles.title}>{title}</p>}
        <PurifiedHtmlContainer
          renderAs='p'
          className={styles.points}
          html={insertVarIntoString(pointsText, { points: total }, 'span')}
        />
        {nextStep ? (
          <PurifiedHtmlContainer
            renderAs='p'
            className={styles.target}
            html={insertVarIntoString(nextUnlockText, { points: nextStep }, 'span')}
          />
        ) : (
          <p className={styles.target}>{allAwardsUnlockedText}</p>
        )}
      </div>
      <div className={styles.doughnut}>
        <PieChart value={value}>
          <span className={styles.value}>
            {value}
            <span className={styles.suffix}>%</span>
          </span>
        </PieChart>
      </div>
    </div>
  );
});

const getNextUnlock = (userCurrent, steps) => {
  const currentIndex = steps.indexOf(userCurrent);
  const nextStep = steps[currentIndex + 1];

  if (nextStep) {
    return nextStep;
  }
};

const mapStateToProps = ({ points }) => {
  const { pointsDetails, rewardCards } = points;

  const userTotal = Number(pointsDetails.userTotal) + 1;
  const steps = [...rewardCards.map(item => Number(item.awardPoints)), userTotal].sort((a, b) => a - b);
  const nextStep = getNextUnlock(userTotal, steps);

  const total = userTotal - 1;
  const value = nextStep ? Math.round((total * 100) / nextStep) : 100;

  return {
    total,
    value,
    nextStep
  };
};

export const Points = connect(mapStateToProps)(Component);
