import React, { useRef, useEffect, useContext, useCallback } from 'react';
import classnames from 'classnames';
import { compose, withHandlers, withState } from 'recompose';
import { matchPath, withRouter, useLocation } from 'react-router-dom';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { ChecklistService } from '../../../services/checklist-service';
import styles from '../../../styles/components/checklist-category.module.scss';
import { Collapse, CollapseTrigger, CollapsePanel } from '../../collapse';
import { redirectToModule, getRoutesByPathname } from '../../../helpers/utils';
import { SidebarContext } from '../../layout/sidebar';
import { ChecklistItem } from './item';
import { withDisplayCondition } from './with-display-condition';

const ChecklistCategoryComponent = React.memo(
  ({ items, icon, heading, subheading, expanded, handleExpand, handleHighlight }) => {
    const ref = useRef(null);
    const hasActiveItemRef = useRef(false);
    const highlightedRef = useRef(false);

    const { pathname } = useLocation();
    const scrollSidebarTo = useContext(SidebarContext);

    const scrollIntoSidebarView = useCallback(() => {
      const { current: category } = ref;
      const { current: hasActiveItem } = hasActiveItemRef;
      const { current: highlighted } = highlightedRef;

      if (category && hasActiveItem && !highlighted) {
        const { top } = category.getBoundingClientRect();

        scrollSidebarTo(top, { incremental: true, smooth: true });

        highlightedRef.current = true;
      }
    }, [scrollSidebarTo]);

    useEffect(() => {
      const [, gist] = getRoutesByPathname(pathname);
      const { current: highlighted } = highlightedRef;

      if (gist) {
        const hasActiveItem = items.some(({ link, statusMappingDetails }) =>
          matchPath(redirectToModule(link, statusMappingDetails), { path: pathname, exact: true })
        );

        hasActiveItemRef.current = hasActiveItem;

        if (hasActiveItem && !highlighted) {
          handleHighlight();
        }
      }
    }, [pathname]); // eslint-disable-line

    return (
      <Collapse renderAs='section' expanded={expanded}>
        <CollapseTrigger ref={ref} className={styles.container} onClick={handleExpand}>
          <div className={styles.header}>
            {icon && <AffiliateIcon name={icon} className={styles.icon} />}
            <div className={styles.label}>
              <h2 className={styles.heading}>{heading}</h2>
              {subheading && <p className={styles.subheading}>{subheading}</p>}
            </div>
            <AffiliateIcon
              className={classnames(styles.toggle, { [styles.active]: expanded })}
              name={`${expanded ? 'close' : 'open'}-vertical`}
            />
          </div>
        </CollapseTrigger>
        <CollapsePanel onExpanded={scrollIntoSidebarView}>
          <ul className={styles.navigation}>
            {items.map(item => (
              <ChecklistItem key={item.link} item={item} />
            ))}
          </ul>
        </CollapsePanel>
      </Collapse>
    );
  }
);

export const ChecklistCategory = compose(
  withState('expanded', 'setExpanded', ({ heading, expanded }) =>
    ChecklistService.getCategoryState({ heading, expanded })
  ),
  withHandlers({
    handleExpand:
      ({ heading, setExpanded }) =>
      () => {
        const state = ChecklistService.toggleCategoryState({ heading });

        setExpanded(state);
      },
    handleHighlight:
      ({ expanded, heading, setExpanded }) =>
      () => {
        if (!expanded) {
          const state = ChecklistService.toggleCategoryState({ heading });

          setExpanded(state);
        }
      }
  }),
  withRouter,
  withDisplayCondition
)(ChecklistCategoryComponent);
