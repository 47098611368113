import React from 'react';
import uuid from 'uuid';
import { withPropsOnChange } from 'recompose';
import styles from '../../styles/components/input-field.module.scss';
import { InputLabel } from './input-label';
import { InputError } from './input-error';
import { InputExplainer } from './input-explainer';

export const withAnchor = withPropsOnChange(['id', 'name'], ({ id, name }) => ({
  id: id ?? uuid(name)
}));
export const withContainer = Component => props =>
  (
    <div className={styles.container}>
      <Component {...props} />
    </div>
  );
export const withLabel =
  ({ className, inline, layout } = {}) =>
  Component =>
    React.memo(({ id, label, informer, ...props }) => (
      <InputLabel htmlFor={id} label={label} informer={informer} className={className} inline={inline} layout={layout}>
        <Component id={id} {...props} />
      </InputLabel>
    ));
export const withError = Component =>
  React.memo(({ error, ...props }) => (
    <>
      <Component {...props} />
      {error && <InputError>{error}</InputError>}
    </>
  ));
export const withExplainer = Component =>
  React.memo(({ explainer, ...props }) => (
    <>
      <Component {...props} />
      {explainer && <InputExplainer>{explainer}</InputExplainer>}
    </>
  ));
