import uuid from 'uuid';
import { parse } from 'query-string';
import { users } from '../api/users';
import { affiliate } from '../helpers/main';
import { readCookie, deleteCookie } from '../helpers/cookie';

export class ShadowUserService {
  static async createIfNotExists(intro) {
    if (localStorage.getItem('shadowId')) {
      return;
    }

    const shadowIdCookie = readCookie('shadowId');

    if (shadowIdCookie) {
      deleteCookie('shadowId');
    }

    const shadowId = shadowIdCookie ?? uuid.v1();

    const {
      utm_source: utmSource,
      utm_campaign: utmCampaign,
      client_customer_id: clientCustomerId,
      client_scheme_id: clientSchemeId
    } = parse(window.location.search);

    try {
      const {
        DISPLAY_MODULES: { INTRO }
      } = window.CONFIG;

      const response = await users.createUser({
        affiliateSource: affiliate ?? 'fhc',
        checklist: '',
        shadowId,
        ...(utmSource && utmSource === 'affiliate' ? { introducer: utmCampaign } : {}),
        ...(clientCustomerId ? { clientCustomerId } : {}),
        ...(clientCustomerId
          ? { clientSchemeId: clientSchemeId?.toLowerCase() === 'leaver' ? null : clientSchemeId }
          : {})
        // lif-2311
        // firebaseToken: localStorage.getItem('firebaseToken')
      });

      localStorage.setItem('shadowId', shadowId);

      INTRO && intro && intro();

      return response;
    } catch (e) {
      return e;
    }
  }

  static async delete() {
    deleteCookie('shadowId');
    deleteCookie('isActiveTrackSession');
    localStorage.removeItem('shadowId');
    localStorage.removeItem('checklist');

    return await ShadowUserService.createIfNotExists();
  }

  static getId() {
    return localStorage.getItem('shadowId');
  }
}
