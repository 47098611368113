import Loadable from 'react-loadable';
import { asTemplate } from '../components/layout/with-layout';
import { Loader } from '../components/with-loader';
import { PROPERTY } from '../helpers/navigation';

const PropertyPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "property-page"*/ '../components/templates/property-page').then(
      module => module.PropertyPage
    ),
  loading: Loader
});

export const propertyPageRoutes = [
  {
    to: '/property-page/:id',
    component: asTemplate(PROPERTY)(PropertyPage)
  }
];
