import { affiliate } from '../../helpers/main';

const getDefaultHeaders = () => {
  return new Headers({ 'Content-Type': 'application/json' });
};

export const createHeaders = type => {
  const headers = getDefaultHeaders();
  const token = localStorage.getItem('token');
  const shadowId = localStorage.getItem('shadowId');

  switch (type) {
    case 'static': {
      headers.set('x-api-key', process.env.REACT_APP_STATIC_API_KEY);
      break;
    }
    case 'content': {
      headers.set('x-api-key', process.env.REACT_APP_KNOWLEDGE_BASE_API_KEY);
      break;
    }
    case 'main':
    default: {
      if (token) {
        headers.set('authorization', token);
        headers.set('X-Shadow-User', '');
      }

      if (shadowId && !token) {
        headers.set('X-Shadow-User', shadowId);
        headers.set('authorization', '');
      }
      break;
    }
  }

  headers.set('x-affiliate', affiliate);

  return headers;
};
