import { connect } from 'react-redux';
import { compose, withState, withHandlers, lifecycle, branch, renderNothing } from 'recompose';
import { evaluateCondition } from '../../../helpers/checklist/display-condition';

const mapStateToProps = ({ userAttributes }) => ({ userAttributes });

export const withDisplayCondition = compose(
  connect(mapStateToProps),
  withState('isPreventedToBeRender', 'setRenderCondition', ({ displayCondition }) => Boolean(displayCondition)),
  withHandlers(() => {
    let unmounted = false;

    return {
      evaluateDisplayCondition:
        ({ item, setRenderCondition }) =>
        async (displayCondition, userAttributes) => {
          const isAllowedToBeRender = await evaluateCondition(displayCondition, userAttributes);

          if (!unmounted) {
            setRenderCondition(!isAllowedToBeRender);
          }
        },
      unmount: () => () => {
        unmounted = true;
      }
    };
  }),
  lifecycle({
    componentDidMount() {
      const { userAttributes, displayCondition, evaluateDisplayCondition } = this.props;

      if (displayCondition) {
        evaluateDisplayCondition(displayCondition, userAttributes);
      }
    },
    componentDidUpdate(prevProps) {
      const { displayCondition, userAttributes, evaluateDisplayCondition } = this.props;

      if (displayCondition && userAttributes !== prevProps.userAttributes) {
        evaluateDisplayCondition(displayCondition, userAttributes);
      }
    },
    componentWillUnmount() {
      const { unmount } = this.props;

      // notify handlers to stop throwing state changes
      // if the component had been already unmounted
      unmount();
    }
  }),
  branch(({ isPreventedToBeRender }) => isPreventedToBeRender, renderNothing)
);
