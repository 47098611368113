import React from 'react';
import { Switch, Route as ReactRoute, withRouter, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { accountChunks } from '../../helpers/import-modules';
import { useOutlineControl } from '../../helpers/hooks/use-outline-control';
import { useViewportControl } from '../../helpers/hooks/use-viewport-control';
import { getScrollXPosition, getScrollYPosition, scrollTo } from '../../helpers/layout/scroll';
import { getStatusData } from '../../store/reducers/status-data';
import { getUserMarketingPreferences } from '../../store/reducers/account/actions';
import { getUserAttributes } from '../../store/reducers/user-attributes/actions';
import { getValidations } from '../../store/reducers/validations';
import { getPoints, getRewardCards } from '../../store/reducers/points-and-awards';
import { UserService } from '../../services/user-servcie';
import { NativeAction } from '../../services/native-action';
import { routes } from '../../routes';
import { GoogleTagManager } from '../google-tag-manager';
import { Route } from '../route';
import { withLoader } from '../with-loader';
import { Consents as ConsentsModal } from './popups/consents';
import { SharingOptionsModal } from './popups/sharing-options';
import { HelpModal } from './popups/help-popup';
import { NotFoundPage } from './not-found-page';

export const Toast = Loadable({
  loader: () => import(/* webpackChunkName: "toast" */ '../toast').then(module => module.Toast),
  loading: () => null
});

const Router = React.memo(() => {
  const {
    NAVIGATION_BAR: { launchPage }
  } = window.CONFIG;

  return (
    <Switch>
      {routes().map(route => {
        const { to, exact } = route;

        return <Route key={to} exact={exact ? exact : true} path={to} route={route} />;
      })}
      <ReactRoute path='(/|/index.html|//index.html)' render={() => <Redirect to={launchPage} />} />
      <ReactRoute component={NotFoundPage} />
    </Switch>
  );
});

const ContentComponent = React.memo(() => {
  const {
    DISPLAY_COMPONENTS: { TOAST }
  } = window.CONFIG;

  useOutlineControl();
  useViewportControl();

  return (
    <>
      <GoogleTagManager />
      {TOAST.SHOW && <Toast />}
      <Router />
      <ConsentsModal />
      <SharingOptionsModal />
      <HelpModal />
    </>
  );
});

export const Content = compose(
  connect(
    state => ({
      loading: state.userAttributes.loading
    }),
    {
      getStatusData,
      getUserAttributes,
      getValidations,
      getPoints,
      getRewardCards,
      getUserMarketingPreferences
    }
  ),
  withRouter,
  lifecycle({
    async componentDidMount() {
      const {
        getUserMarketingPreferences,
        getStatusData,
        getUserAttributes,
        getValidations,
        getPoints,
        getRewardCards
      } = this.props;
      const {
        DISPLAY_MODULES: { ACCOUNT, POINTS }
      } = window.CONFIG;

      ACCOUNT && accountChunks();

      if (POINTS) {
        // eslint-disable-next-line no-unused-expressions
        import(/* webpackChunkName: "points-and-awards"*/ '../../pages/points-and-awards');
        // eslint-disable-next-line no-unused-expressions
        import(/* webpackChunkName: "claims-and-awards"*/ '../templates/claim-rewards');
      }

      // lif-2311
      // UserService.updateLocalFirebaseToken(user);
      if (!UserService.hasAccount()) {
        await getUserMarketingPreferences();
        await getStatusData();

        if (POINTS) {
          await getPoints();
          await getRewardCards();
        }
        await getValidations();
        await getUserAttributes();

        NativeAction.initialize();

        // eslint-disable-next-line no-console
        console.log('[HBC] All required data is loaded', new Date());
      }
    },
    componentDidUpdate(prevProps) {
      const {
        location: { pathname }
      } = this.props;

      const scrollX = getScrollXPosition();
      const scrollY = getScrollYPosition();
      const locationChanged = prevProps.location.pathname !== pathname;

      if (locationChanged && (scrollX || scrollY)) {
        scrollTo(window, { left: 0, top: 0 });
      }
    }
  }),
  withLoader
)(ContentComponent);
