import dayjs from 'dayjs';
import { getCountry } from './get-country';

export const getBuyingTime = timeframe => dayjs().add(timeframe, 'month');

const calculate = (propertyPrice, params) => {
  let feeSum = 0;
  params.forEach(param => {
    if (param.from > propertyPrice) {
      return;
    }

    if (propertyPrice > param.from && propertyPrice <= param.to) {
      feeSum += ((propertyPrice - param.from) / 100) * param.percent;
    }

    if (propertyPrice > param.from && propertyPrice > param.to) {
      feeSum += ((param.to - param.from) / 100) * param.percent;
    }
  });

  return Math.round(feeSum);
};

export const calculateStumpDuty = ({ goalsLocationParsed, timeframe, propertyPrice }) => {
  try {
    const {
      DISPLAY_COMPONENTS: { STAMP_DUTY: show },
      DEFAULT_VALUES: { STAMP_DUTY: data }
    } = window.CONFIG;

    if (!show || !data) {
      return 0;
    }

    const country = getCountry(goalsLocationParsed);
    const buyingTime = getBuyingTime(timeframe);

    const rules = data[country];

    const ruleKey = Object.keys(rules).find(item => {
      return buyingTime.isBefore(dayjs(item));
    });

    const rule = ruleKey ? rules[ruleKey] : rules.last;

    if (rule.breakingPropertyPrice && propertyPrice <= rule.breakingPropertyPrice) {
      return calculate(propertyPrice, rule.less);
    }

    return calculate(propertyPrice, rule.main);
  } catch (err) {
    throw new Error('Calculate stamp duty failed. Probably config value is missing' + err);
  }
};
