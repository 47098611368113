import { store } from '../../store';
import { isHtmlElement } from './element';

export const hasScroll = target => {
  return isHtmlElement(target) && target.clientHeight < target.scrollHeight;
};

export const isScrollAtTop = target => {
  return hasScroll(target) && target.scrollTop === 0;
};

export const isScrollAtBottom = target => {
  return hasScroll(target) && target.scrollHeight - target.scrollTop <= target.clientHeight;
};

const IS_SUPPORT_PAGE_OFFSET = window.pageXOffset !== undefined;
const IS_CSS1_COMPAT = document.compatMode === 'CSS1Compat';

export const getScrollXPosition = () => {
  return IS_SUPPORT_PAGE_OFFSET
    ? window.pageXOffset
    : IS_CSS1_COMPAT
    ? document.documentElement.scrollLeft
    : document.body.scrollLeft;
};

export const getScrollYPosition = () => {
  return IS_SUPPORT_PAGE_OFFSET
    ? window.pageYOffset
    : IS_CSS1_COMPAT
    ? document.documentElement.scrollTop
    : document.body.scrollTop;
};

const SCROLL = (() => {
  const dummy = document.createElement('div');

  const SUPPORTS = {
    WINDOW: window.scrollTo || window.scrollBy || window.scroll || null,
    ELEMENT: dummy.scrollTo || dummy.scrollBy || dummy.scroll || null,
    IS_SMOOTH_SUPPORTED: false
  };

  try {
    SUPPORTS.ELEMENT.call(dummy, {
      top: 0,
      get behavior() {
        SUPPORTS.IS_SMOOTH_SUPPORTED = true;
        return 'smooth';
      }
    });
  } finally {
    return SUPPORTS;
  }
})();

export const scrollTo = (target, { behavior, incremental, ...options }) => {
  let method, scrollTop, scrollLeft;

  if (target instanceof Window) {
    method = SCROLL.WINDOW;

    scrollTop = getScrollYPosition();
    scrollLeft = getScrollXPosition();
  } else if (isHtmlElement(target)) {
    method = SCROLL.ELEMENT;

    scrollTop = target.scrollTop;
    scrollLeft = target.scrollLeft;
  }

  if (incremental) {
    if ('left' in options) {
      options.left += scrollLeft;
    }

    if ('top' in options) {
      const { topNavigation } = store.getState().layout.sizes;

      options.top += scrollTop - topNavigation;
    }
  }

  if (behavior && SCROLL.IS_SMOOTH_SUPPORTED) {
    options.behavior = behavior;
  }

  try {
    if (options.behavior) {
      method.call(target, options);
    } else {
      method.call(target, options.left ?? scrollLeft, options.top ?? scrollTop);
    }
  } catch (error) {
    if (options.left) {
      target.scrollLeft = options.left;
    }

    if (options.top) {
      target.scrollTop = options.top;
    }
  }
};
