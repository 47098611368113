import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from './store';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss';
import App from './App';
import { ErrorBoundary } from './components/error-boundary';
import { ThemeProvider } from './components/theme';
import { SlotsProvider } from './components/slots';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'unfetch/polyfill';
// eslint-disable-next-line import/no-unassigned-import
import 'beautiful-react-redux/patch';

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ErrorBoundary>
        <ThemeProvider>
          <SlotsProvider>
            <App />
          </SlotsProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
