import React, { useCallback } from 'react';
import classnames from 'classnames';
import styles from '../../styles/components/dimmer.module.scss';
import { withStopPropagation } from '../../helpers/hocs/with-stop-propagation';
import { useEventListener } from '../../helpers/hooks/use-event-listener';
import { Key } from '../../helpers/layout/event';

const Component = React.memo(
  ({ on = true, soft = false, display = 'block', children, className, onClick, onEscClick }) => {
    const handleEscKey = useCallback(
      event => {
        if (on && Key.isSpecificKey('escape')(event) && onEscClick) {
          onEscClick();
        }
      },
      [on, onEscClick]
    );

    useEventListener('keyup', handleEscKey);

    return (
      <div
        style={{
          ...(soft ? void 0 : { display: on ? display : 'none' })
        }}
        className={classnames(styles.container, { [styles.soft]: soft }, className)}
        data-visible={on}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }
);

export const Dimmer = withStopPropagation('onClick')(Component);
