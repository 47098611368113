import { interpolateRegex, starsRegex } from './regex';

const wrap = (data, wrapper) => {
  switch (wrapper) {
    case 'link':
      return `<a href=${data.to} ${data.isExternal ? 'target="_blank"' : ''}>${data.label}</a>`;
    case 'strong':
      return `<strong>${data}</strong>`;
    case 'span':
      return `<span>${data}</span>`;
    default:
      return data;
  }
};

export const insertVarIntoString = (template, data, wrapper) => {
  return template.replace(/\n/g, '<br />').replace(interpolateRegex, (_, name) => wrap(data[name], wrapper) ?? '');
};

export const insertVarBeforeInterpolation = (template, data) => {
  return template.replace(starsRegex, (_, name) => data[name] ?? '');
};
