import Loadable from 'react-loadable';
import { asTemplate } from '../components/layout/with-layout';
import { Loader } from '../components/with-loader';
import { QUESTION, TOUR } from '../helpers/navigation';
import { Question } from '../pages/question';

const TourPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "tourPage" */ '../components/templates/tour-page').then(module => module.TourPage),
  loading: Loader
});

export const tourPageRoutes = [
  {
    to: '/tour/:name/:tag',
    component: asTemplate(TOUR)(TourPage)
  },
  {
    to: '/welcome/:activity/:slug',
    component: asTemplate(QUESTION)(Question)
  }
];
