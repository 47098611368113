import React from 'react';
import { Helmet } from 'react-helmet';
import { compose, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';
import { Column } from '~/components/layout/grid';
import styles from '../../styles/templates/calculators-template.module.scss';
import { getArticleBySlug } from '../../api/content';
import { redirectToModule } from '../../helpers/utils';
import { affiliate } from '../../helpers/main';
import { sendStatus } from '../../store/reducers/status-data';
import { withLoader } from '../../components/with-loader';
import { InputButtonWithIcon as ButtonWithIcon } from '../../components/buttons/button-with-icon';
import { LegalFooter as Footer } from '../../components/layout/page-footer';
import { Template, THeadline, TSubHeading } from '../../components/template';
import { Image } from '../../components/images/image';
import { Link } from '../../components/link';
import { withResize } from '../../components/with-resize';
import { Spacer } from '../../components/layout/spacers';

const sectionsData = [
  {
    key: 'plan',
    title: 'Where do I begin? - Create a plan tool',
    text: `Try out different buying scenarios and see how much money you will need for your deposit, legal fees, stamp 
         duty and other extra costs. Plus view a breakdown of all these costs and the amount you should be saving each 
         month to cover them.`,
    image: 'https://barratt.firsthomecoach.co.uk/barratthomes/images/plan-small.jpg',
    buttonLabel: 'Create your plan',
    buttonLink: '/checklist/plan/start',
    statusMapping: 'myPlan'
  },
  {
    key: 'depositBuilder',
    title: 'How should I save my deposit? - Deposit builder tool',
    text: `Discover the best and fastest way to build your deposit. Compare your current savings strategy with some 
          alternative approaches to see how much sooner you could be buying and if you’re eligible for free government 
          money towards your home purchase.`,
    image: 'https://barratt.firsthomecoach.co.uk/barratthomes/images/deposit-small.jpg',
    buttonLabel: 'Build your deposit',
    buttonLink: '/checklist/deposit-builder/start',
    statusMapping: 'depositBuilder'
  },
  {
    key: 'borrowingPower',
    title: 'How much can I borrow? - Mortgage borrowing power tool',
    text: `Find out how much money you may be able to borrow for your home, plus your likely repayments and rates when 
          you take out a standard mortgage. See a breakdown of your property price and discover how using a standard 
          mortgage would compare to purchasing with a Help to Buy Equity Loan.`,
    image: 'https://barratt.firsthomecoach.co.uk/barratthomes/images/borrowing-small.jpg',
    buttonLabel: 'Your borrowing power',
    buttonLink: '/checklist/how-much-can-i-borrow/start',
    statusMapping: 'mortgageRepaymentCalculator'
  },
  {
    key: 'htb',
    title: 'Is the Help to Buy scheme for me? - Help to Buy Equity Loan tool',
    text: `Find out how much money you may be able to spend on a property, the rates you’re likely to get and what your 
          repayments might be if you use a Help to Buy Equity Loan. See how this approach compares to taking out a 
          standard mortgage.`,
    image: 'https://barratt.firsthomecoach.co.uk/barratthomes/images/help-to-buy-small.jpg',
    buttonLabel: 'Explore help to buy',
    buttonLink: '/checklist/help-to-buy/start',
    statusMapping: 'mortgageHelpToBuy'
  }
];

const sectionImages = {
  barrattlondon: {
    plan: 'https://barratt.firsthomecoach.co.uk/barrattlondon/images/plan-small.jpg',
    depositBuilder: 'https://barratt.firsthomecoach.co.uk/barrattlondon/images/deposit-small.jpg',
    borrowingPower: 'https://barratt.firsthomecoach.co.uk/barrattlondon/images/borrowing-small.jpg',
    htb: 'https://barratt.firsthomecoach.co.uk/barrattlondon/images/help-to-buy-small.jpg'
  },
  barratthomes: {
    plan: 'https://barratt.firsthomecoach.co.uk/barratthomes/images/plan-small.jpg',
    depositBuilder: 'https://barratt.firsthomecoach.co.uk/barratthomes/images/deposit-small.jpg',
    borrowingPower: 'https://barratt.firsthomecoach.co.uk/barratthomes/images/borrowing-small.jpg',
    htb: 'https://barratt.firsthomecoach.co.uk/barratthomes/images/help-to-buy-small.jpg'
  },
  davidwilson: {
    plan: 'https://barratt.firsthomecoach.co.uk/davidwilson/images/plan-small.jpg',
    depositBuilder: 'https://barratt.firsthomecoach.co.uk/davidwilson/images/deposit-small.jpg',
    borrowingPower: 'https://barratt.firsthomecoach.co.uk/davidwilson/images/borrowing-small.jpg',
    htb: 'https://barratt.firsthomecoach.co.uk/davidwilson/images/help-to-buy-small.jpg'
  }
};

const affiliateToSLug = {
  barrattlondon: 'barratt-london-calculator-page',
  barratthomes: 'barratt-home-calculator-page',
  davidwilson: 'david-wilson-calculator-page'
};

const FirstColumn = React.memo(({ section }) => (
  <Column span={{ sm: '6' }}>
    <TSubHeading>{section.text}</TSubHeading>
    <Spacer renderAs='div' type='content' />
    <ButtonWithIcon to={redirectToModule(section.buttonLink, section.statusMapping)}>
      {section.buttonLabel}
    </ButtonWithIcon>
  </Column>
));

const SecondColumn = React.memo(({ section }) => (
  <>
    <Column className={styles.media} span={{ sm: '7+6' }}>
      <Image
        className={styles.image}
        src={sectionImages[affiliate] ? sectionImages[affiliate][section.key] : null}
        fluid
      />
    </Column>
    <Spacer hide='sm' type='component' />
  </>
));

const CalculatorPage = React.memo(({ viewport: { mdAndUp }, meta: { title, description, canonical } }) => (
  <>
    <Helmet title={title}>
      <meta name='description' content={description} />
      {canonical && <link rel='canonical' href={canonical} />}
    </Helmet>
    <Template className='home-page'>
      <Spacer type='top' />
      <Column renderAs='header' className='text-center'>
        <h1 className='heading-page-title'>Home buying tools</h1>
        <Spacer renderAs='div' />
        <p className='body-paragraph-m content strong'>
          From figuring out how much you need to save to discovering the amount you could borrow to buy your first home,
          our calculators will help you work out the best way to get a foot on the property ladder.
        </p>
      </Column>
      <Spacer keyline='bottom' />
      {sectionsData.map((section, index, sections) => (
        <React.Fragment key={section.key}>
          <Spacer type='component' />
          <Column>
            <THeadline title={section.title} />
          </Column>
          {mdAndUp ? (
            <>
              <FirstColumn section={section} />
              <SecondColumn section={section} />
            </>
          ) : (
            <>
              <SecondColumn section={section} />
              <FirstColumn section={section} />
            </>
          )}
          <Spacer type={index === sections.length - 1 ? 'bottom' : 'component'} />
        </React.Fragment>
      ))}
    </Template>
    <Footer>
      <p className={styles.powered}>Powered by</p>
      <Link to='https://www.firsthomecoach.co.uk/' isExternal>
        <Image
          src='https://barratt.firsthomecoach.co.uk/barratthomes/images/footer-image.png'
          title='First Home Coach'
          fluid
        />
      </Link>
    </Footer>
  </>
));

export const CalculatorHomePage = compose(
  connect(null, { sendStatus }),
  withState('loading', 'setLoading', true),
  withState('meta', 'setMeta', {}),
  lifecycle({
    async componentDidMount() {
      const { setLoading, setMeta, sendStatus } = this.props;
      const post = await getArticleBySlug({ slug: affiliateToSLug[affiliate] });

      if (post.meta) {
        setMeta(post.meta);
      }

      setLoading(false);

      await sendStatus('myPlan', 'seen');
      await sendStatus('mortgageRepaymentCalculator', 'seen');
      await sendStatus('mortgageHelpToBuy', 'seen');
      await sendStatus('depositBuilder', 'seen');
    }
  }),
  withResize,
  withLoader
)(CalculatorPage);
