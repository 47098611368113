import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

export const QuestionNumberInput = React.memo(
  ({ label, onChange, format, value, max, min, submit, errorMessages, mask, maskChar }) => (
    <Input
      type='tel'
      label={label}
      onChange={onChange}
      format={format}
      value={value}
      maxLength={15}
      onSubmit={submit}
      max={max}
      min={min}
      errorMessages={errorMessages}
      mask={mask}
      maskChar={maskChar}
    />
  )
);
