import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { getChecklist } from '../../../store/reducers/checklist';
import { resetDynamicNavChecklistLink } from '../../../store/reducers/navigation';
import { withLoader } from '../../../components/with-loader';

const mapStateToProps = ({ checklist }) => ({
  checklist: checklist.items,
  loading: checklist.loading
});

export const withDynamicChecklist = compose(
  connect(mapStateToProps, { getChecklist, resetDynamicNavChecklistLink }),
  lifecycle({
    async componentDidMount() {
      const { getChecklist, resetDynamicNavChecklistLink } = this.props;

      await getChecklist();
      resetDynamicNavChecklistLink();
    }
  }),
  withLoader
);
