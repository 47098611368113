import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { sendUserAttribute, sendAdditionalUserAttributes } from '../../store/reducers/user-attributes/actions';
import { QuestionTextInput } from './inputs/text';
import { QuestionButtonInput } from './inputs/button';
import { QuestionNumberInput } from './inputs/number';
import { QuestionPercentInput } from './inputs/percent';
import { QuestionAddressInput } from './inputs/address';
import { QuestionDateInput } from './inputs/date';
import { QuestionWheelInput } from './inputs/wheel';
import { QuestionTextareaInput } from './inputs/textarea';
import { QuestionCheckboxInput } from './inputs/checkbox';
import { QuestionRadioInput } from './inputs/radio';
import { QuestionPensionProviderInput } from './inputs/pension-provider';
import { QuestionEmailInput } from './inputs/email';
import { QuestionPhoneNumberInput } from './inputs/phone-number';
import { QuestionNiNInput } from './inputs/nin';
import { QuestionSelectInput } from './inputs/select';
import { withInputHandlers } from './with-handlers';
import { QuestionPropertyAddressInput } from './inputs/property-address';
import { QuestionInfoButton } from './inputs/button-info';

const inputs = {
  button: QuestionButtonInput,
  text: QuestionTextInput,
  number: QuestionNumberInput,
  percent: QuestionPercentInput,
  address: QuestionAddressInput,
  propertyAddress: QuestionPropertyAddressInput,
  date: QuestionDateInput,
  wheel: QuestionWheelInput,
  textarea: QuestionTextareaInput,
  checkbox: QuestionCheckboxInput,
  radio: QuestionRadioInput,
  pensionProvider: QuestionPensionProviderInput,
  email: QuestionEmailInput,
  phoneNumber: QuestionPhoneNumberInput,
  nin: QuestionNiNInput,
  select: QuestionSelectInput,
  infoButton: QuestionInfoButton
};

const InputComponent = React.memo(props => {
  const { data, type } = props;

  if (inputs[type]) {
    const Component = inputs[type];

    return <Component {...props} {...data} />;
  }

  return null;
});

const mapStateToProps = (state, ownProps) => {
  const { data, identifier } = ownProps;

  const type = data.userAttributeType;
  const field = data.userAttributeField;

  const reusableType = type?.split('.')[0];
  const name = type?.split('.')[1];

  //this part is used for question with dynamic names e.g pension-finder
  //type consists of two parts, 1-user attribute type (e.g pension, goal), 2 - items (object)
  if (name && identifier) {
    const type = reusableType;

    return {
      defaultValue: state.userAttributes?.[type]?.[name]?.[identifier]?.[field],
      previousState: state.userAttributes?.[type]?.[name] ?? {},
      name,
      type
    };
  }

  return {
    defaultValue: state.userAttributes?.[type]?.[field],
    type
  };
};

const mapDispatchToProps = dispatch => ({
  sendUserAttribute: (type, params, cb) => dispatch(sendUserAttribute(type)(params, cb)),
  sendAdditionalUserAttributes: params => dispatch(sendAdditionalUserAttributes(params))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { name, defaultValue, previousState, type, nextPageLink } = stateProps;
  const { identifier } = ownProps;

  const sendUserAttribute = type => async (params, cb) => {
    if (name && identifier) {
      return await dispatchProps.sendUserAttribute(
        type,
        {
          [name]: { ...previousState, [identifier]: { ...previousState[identifier], ...params } }
        },
        cb
      );
    }

    return await dispatchProps.sendUserAttribute(type, params, cb);
  };

  return { ...dispatchProps, ...ownProps, defaultValue, nextPageLink, sendUserAttribute: sendUserAttribute(type) };
};

export const Input = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withRouter,
  withState('value', 'onChange', ({ defaultValue, data }) => data?.defaultValue ?? defaultValue),
  withProps(({ data, identifier }) => {
    if (!data.nextPageLink) {
      return;
    }

    return {
      nextPageLink:
        //breakReusable used for braking a chain of reusable questions
        !data.breakReusable && identifier && !data.nextPageLink.includes('done')
          ? `${data.nextPageLink}/${identifier}`
          : data.nextPageLink
    };
  }),
  withInputHandlers
)(InputComponent);
