import { helpers } from './helpers';
import { userAttributes } from './user-attributes';
import { users } from './users';
import { depositBuilder } from './deposit-builder';
import { content } from './content';
import { statusData } from './status-data';
import { marketingInformation } from './marketing-info';
import { points } from './points-and-awards';
import { configuration } from './configuration';
import { eligibility } from './eligibility';

export const api = {
  geocode: helpers,
  userAttributes,
  users,
  depositBuilder,
  content,
  statusData,
  marketingInformation,
  points,
  configuration,
  eligibility
};
