const endsWithNumber = (value: string | undefined = ''): boolean => {
  const target = StringShim.trim(value);

  return target.length > 0 ? !Number.isNaN(Number(target.slice(-1))) : false;
};

const replaceLastCharacter = (value: string | undefined = '', replaceWith: string): string => {
  return value.replace(/.$/, replaceWith);
};

const trim = (value: string | undefined = '', native = true): string => {
  if (native && Object.prototype.hasOwnProperty.call(String.prototype, 'trim')) {
    return String.prototype.trim.call(value);
  }

  return value.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
};

const capitalize = (value: string | undefined = ''): string => {
  const target = StringShim.trim(value).split(' ');

  return target
    .map(current => {
      const value = StringShim.trim(current);

      switch (value.length) {
        case 0:
          return value;
        case 1:
          return value.toUpperCase();
        default:
          return value.charAt(0).toUpperCase() + value.slice(1);
      }
    })
    .filter(Boolean)
    .join(' ');
};

const isString = <T extends unknown>(value: T): boolean => {
  return typeof value === 'string' || value instanceof String;
};

const toKebabCase = (...partials: (string | undefined)[]): string => {
  let value = '';

  while (partials.length) {
    if (value.length > 0) {
      const partial = StringShim.trim(partials.shift());

      if (partial.length > 0) {
        value = `${value}-${partial}`;
      }
    } else {
      value = StringShim.trim(partials.shift());
    }
  }

  return value;
};

export class StringShim {
  static endsWithNumber = endsWithNumber;
  static replaceLastCharacter = replaceLastCharacter;
  static trim = trim;
  static capitalize = capitalize;
  static isString = isString;
  static toKebabCase = toKebabCase;
}
