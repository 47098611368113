import React from 'react';
import { ButtonWithIcon } from '../../../components/buttons/button-with-icon';
import { withContainer } from './components/input-field';

const Input = withContainer(ButtonWithIcon);

export const QuestionButtonInput = React.memo(({ label, submit, buttonType }) => (
  <Input onClick={submit} type={buttonType} buttonType='submit'>
    {label}
  </Input>
));
