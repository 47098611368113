import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { compose } from 'recompose';
import { getMortgagesByPropertyId } from '../../../helpers/property-portfolio';

const MONTHLY_RENTAL_KEY = 'monthlyRentalIncome';
const GROSS_YIELD = 'grossYield';
const PROPERTY_LOAN = 'propertyLoan';

export const generateMABProps = state => {
  const { property, mortgage } = state.userAttributes;

  const properties = property.items;
  const mortgages = mortgage.items;

  let items = {};

  Object.keys(properties).forEach(propertyKey => {
    const propertyMortgages = getPropertyMortgages(mortgages, propertyKey);

    const monthlyRentalIncome = calculateMonthlyRentalIncome(properties[propertyKey], propertyMortgages);
    const grossYield = calculateGrossYield(properties[propertyKey]);
    const propertyLoan = calculatePropertyLoan(properties[propertyKey], propertyMortgages);

    items = {
      ...items,
      [propertyKey]: {
        ...properties[propertyKey],
        [MONTHLY_RENTAL_KEY]: monthlyRentalIncome,
        [GROSS_YIELD]: grossYield,
        [PROPERTY_LOAN]: propertyLoan
      }
    };
  });

  return { items };
};

const PERCENT = 0.12;

const CURRENT_MONTHLY_MORTGAGE_KEY = 'currentMortgageMonthly';
const CURRENT_MORTGAGE_OUTSTANDING_KEY = 'currentMortgageOutstanding';

const calculateMonthlyRentalIncome = (property, mortgages) => {
  try {
    const { rentalIncome, marketRental } = property;

    const monthlyMortgagePayment = sumByMortgageKey(mortgages, CURRENT_MONTHLY_MORTGAGE_KEY);

    const result = Number(rentalIncome) - Number(marketRental) * PERCENT - monthlyMortgagePayment;

    return Math.round(result);
  } catch {
    return 'Not enough data';
  }
};
const calculateGrossYield = property => {
  try {
    const { price, rentalIncome } = property;

    const result = ((Number(rentalIncome) * 12) / Number(price)) * 100;

    return result;
  } catch {
    return 'Not enough data';
  }
};
const calculatePropertyLoan = (property, mortgages) => {
  try {
    const { value } = property;

    const outStandingMortgages = sumByMortgageKey(mortgages, CURRENT_MORTGAGE_OUTSTANDING_KEY);

    const result = (outStandingMortgages / Number(value)) * 100;

    return result;
  } catch {
    return 'Not enough data';
  }
};

const pickMortgages = items => keys => ObjectShim.pick(items, keys);

const getPropertyMortgages = (mortgages, key) =>
  compose(pickMortgages(mortgages), getMortgagesByPropertyId(key), Object.keys)(mortgages);

const sumByMortgageKey = (mortgages, key) =>
  Object.keys(mortgages).reduce((acc, current) => {
    return (acc += mortgages[current][key]);
  }, 0);
