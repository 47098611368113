import React, { useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';
import { NumberShim } from '@packages/helpers/core/shims/number-shim';
import styles from '../../../styles/components/wheel.module.scss';
import { subscribe, unsubscribe } from '../../../store/reducers/wheels';
import { withResize } from '../../with-resize';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { withContainer } from '../input-field';
import { Label as InputLabel } from '../input-label';
import { useValidator } from '../../../helpers/hooks/use-validator';
import { useController } from './hooks/use-controller';
import { useView } from './hooks/use-view';
import { useFormatter } from './hooks/use-formatter';

const WheelComponent = React.memo(
  ({
    value = 0,
    increment = 10,
    validators,
    dynamic = false,
    className,
    name,
    label,
    informer,
    computed,
    prefix,
    suffix,
    format,
    dimensions,
    subscribe,
    unsubscribe,
    stopSiblings,
    onChange,
    onChangeEnd
  }) => {
    const [controller, { handleStart, handleIncrease, handleDecrease, stop }] = useController(value, increment);
    const [view, windowRef] = useView(dimensions);

    const { error, errors } = useValidator(value, validators);

    const handleDragStart = event => {
      stopSiblings();

      event.stopPropagation();
      event.preventDefault();
    };

    const handleDrag = event => {
      const { multiplier, boundary } = event.detail;

      if (onChange && !errors[boundary]) {
        const nextValue = NumberShim.toNearestOf(value, increment * multiplier);

        onChange(name)(nextValue);
      }

      event.stopPropagation();
      event.preventDefault();
    };

    const handleDragEnd = event => {
      if (onChangeEnd) {
        onChangeEnd(name)(value);
      }

      event.stopPropagation();
      event.preventDefault();
    };

    const handleTap = handle => event => {
      stopSiblings();

      handle(event);

      if (onChangeEnd) {
        PromiseShim.queueMicrotask(() => {
          onChangeEnd(name)(value);
        });
      }
    };

    useEffect(() => {
      if (dynamic) {
        subscribe(name, stop);

        return () => {
          unsubscribe(name);
        };
      }
    }, [name, dynamic, subscribe, unsubscribe, stop]);

    useEffect(() => {
      return () => {
        stop();
      };
    }, [stop]);

    const { transform, transition } = controller;
    const { grips, gripWidth, zTranslate, rotationPerPanel, steppersDisplay } = view;

    const formatted = useFormatter(value, computed, format);

    return (
      <div className={classnames(styles.container, className)} data-atid={name && `${name}-slider`}>
        <div className={styles.text}>
          {label && (
            <div className={styles.left}>
              <InputLabel informer={informer} className={styles.label}>
                {label}
              </InputLabel>
            </div>
          )}
          <div className={classnames(styles.center, styles.value)}>
            <span>
              {prefix}
              {formatted.value}
              {suffix}
            </span>
          </div>
          <div className={classnames(styles.right, styles.computed)}>
            <span>{formatted.computed}</span>
          </div>
        </div>
        <div className={styles.wrapper} onDrag={handleDrag} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          {steppersDisplay && (
            <button
              aria-label='decrease'
              className={styles.stepper}
              onClick={handleTap(handleDecrease)}
              disabled={errors.min}
            >
              <AffiliateIcon name='stepper-decrease' />
            </button>
          )}
          <div className={classnames(styles.indicator, { [styles.error]: error })} />
          <div className={styles.body}>
            <div className={classnames(styles.fader, styles.left)}></div>
            <div ref={windowRef} className={styles.window}>
              <ul
                style={{ width: gripWidth, transform, transition }}
                className={styles.rotator}
                onMouseDown={handleStart}
                onTouchStart={handleStart}
              >
                {ArrayShim.range(grips).map(el => {
                  const yRotation = rotationPerPanel * el;

                  return (
                    <li
                      key={yRotation}
                      className={styles.grip}
                      style={{
                        width: gripWidth,
                        transform: `rotateY(${yRotation}deg) translateZ(${zTranslate}px)`
                      }}
                    />
                  );
                })}
              </ul>
            </div>
            <div className={classnames(styles.fader, styles.right)}></div>
          </div>
          {steppersDisplay && (
            <button
              aria-label='increase'
              className={styles.stepper}
              onClick={handleTap(handleIncrease)}
              disabled={errors.max}
            >
              <AffiliateIcon name='stepper-increase' />
            </button>
          )}
        </div>
      </div>
    );
  }
);

export const Wheel = compose(
  connect(
    (state, { name }) => ({
      validators: state.validations.validations,
      stopSiblings: compose(
        ...state.wheels.dynamic.reduce((callbacks, [wheel, stopMomentum]) => {
          if (wheel !== name) {
            return [...callbacks, stopMomentum];
          }

          return callbacks;
        }, [])
      )
    }),
    { subscribe, unsubscribe }
  ),
  withPropsOnChange(['name', 'min', 'max'], ({ name, min, max, validators: state }) => {
    const centralizeMin = state.goal[name]?.validationData.min;
    const centralizeMax = state.goal[name]?.validationData.max;

    const minLimit = min ?? centralizeMin;
    const maxLimit = max ?? centralizeMax;

    const validators = {};

    if (Number.isFinite(minLimit)) {
      validators.min = value => value <= minLimit;
    }

    if (Number.isFinite(maxLimit)) {
      validators.max = value => value >= maxLimit;
    }

    return { validators };
  }),
  withResize
)(WheelComponent);

export const InputWheel = withContainer(Wheel);
