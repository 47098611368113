import React from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import { compose } from 'recompose';
import styles from '../../styles/components/input.module.scss';
import { formatDisplayValue, formatAfterChangeValue } from '../../helpers/inputs';
import { withAnchor, withContainer, withError, withExplainer, withLabel } from '../inputs/input-field';

export const Input = React.memo(({ value, type, onChange, format, className, ...props }) => {
  let pattern = '[0-9]*';

  const handleOnChange = e => {
    const value = format ? formatAfterChangeValue(e.target.value, format, type) : e.target.value;

    onChange(value);
  };

  if (type === 'text' || type === 'email' || type === 'password') {
    pattern = null;
  }

  return (
    <InputMask
      type={type}
      value={formatDisplayValue(value, format)}
      className={classnames(styles.field, className)}
      pattern={pattern}
      autoComplete='off'
      onChange={handleOnChange}
      {...props}
    />
  );
});

export const withInputLabel = withLabel({ className: styles.label, layout: styles.container });
export const InputField = compose(withAnchor, withContainer, withExplainer, withError, withInputLabel)(Input);
