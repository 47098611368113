import React from 'react';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { compose, lifecycle, branch, withProps, renderComponent } from 'recompose';
import { CoachMessage } from '../../coach-message';
import { InputGroup } from '../../inputs/input-group';
import { withSpinner } from '../../with-loader';
import { getNextActivity } from '../../../store/reducers/digital-coach';
import { isModuleCompleted } from '../../../helpers/utils';
import { UserService } from '../../../services/user-servcie';
import {
  TYPE_BUTTON_ACTION_CALL_CUSTOM_ACTION,
  TYPE_BUTTON_ACTION_RELOAD,
  TYPE_ORIGIN_RATE_APP
} from '../../../helpers/digital-coach/constants';
import { useConfirm } from '../../layout/popups/confirm';
import { DigitalCoachDisplayController } from './display-controller';
import { Action, ReloadAction, CustomAction } from './actions';
import { RateAppButton } from './buttons/rate';

const DigitalCoachAction = compose(
  branch(({ actionType }) => actionType === TYPE_ORIGIN_RATE_APP, renderComponent(RateAppButton)),
  branch(({ actionType }) => actionType === TYPE_BUTTON_ACTION_RELOAD, renderComponent(ReloadAction)),
  branch(({ actionType }) => actionType === TYPE_BUTTON_ACTION_CALL_CUSTOM_ACTION, renderComponent(CustomAction))
)(Action);

const DigitalCoachWidgetComponent = React.memo(
  ({ nextActivity: { digitalCoachMessage: message, digitalCoachActions: actions, options } }) => {
    const [confirm, handleConfirm] = useConfirm({
      props: {
        acceptType: 'major',
        declineType: 'minor'
      }
    });

    return (
      <>
        <DigitalCoachDisplayController type={options?.slideUp}>
          {message && <CoachMessage html={message} />}
          {actions && (
            <InputGroup type='coach'>
              {actions
                .sort((a, b) => a.weight - b.weight)
                .map(action => (
                  <DigitalCoachAction key={uuid('digital-coach-action')} onConfirm={handleConfirm} {...action} />
                ))}
            </InputGroup>
          )}
        </DigitalCoachDisplayController>
        {confirm}
      </>
    );
  }
);

const mapStateToProps = state => {
  const nextActivity = state.activities.nextActivity;

  return {
    nextActivity,
    loading: state.activities.loading,
    isCompleted: nextActivity.activityKey && isModuleCompleted(nextActivity.activityKey)
  };
};

const StaticDigitalCoachWidget = compose(
  withProps(() => {
    const {
      ACCOUNT: { DIGITAL_COACH_ACTIVITY }
    } = window.CONFIG;

    return {
      nextActivity: DIGITAL_COACH_ACTIVITY
    };
  })
)(DigitalCoachWidgetComponent);

export const DigitalCoachWidget = compose(
  connect(mapStateToProps, { getNextActivity }),
  lifecycle({
    async componentDidMount() {
      const { getNextActivity } = this.props;

      await getNextActivity();
    },
    async componentDidUpdate() {
      const { getNextActivity, isCompleted } = this.props;

      if (isCompleted) {
        await getNextActivity();
      }
    }
  }),
  branch(() => UserService.hasAccount(), renderComponent(StaticDigitalCoachWidget)),
  withSpinner
)(DigitalCoachWidgetComponent);
