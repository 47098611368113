import React from 'react';

const footers = {};

export const CustomPageFooters = props => {
  if (props.name && footers[props.name]) {
    const CustomFooter = footers[props.name];

    return <CustomFooter {...props} />;
  }

  return props.children;
};
