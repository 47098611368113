import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { getKnowledgeBase } from '../../../store/reducers/content';
import { resetNavKnowledgeLink } from '../../../store/reducers/navigation';
import { withLoader } from '../../with-loader';

const mapStateToProps = ({ content }) => ({
  knowledgeBase: content.knowledgeTopics,
  loading: content.loadingKnowledgeTopics
});

export const withKnowledgeBase = compose(
  connect(mapStateToProps, { getKnowledgeBase, resetNavKnowledgeLink }),
  lifecycle({
    async componentDidMount() {
      const { getKnowledgeBase, resetNavKnowledgeLink } = this.props;

      await getKnowledgeBase();
      resetNavKnowledgeLink();
    }
  }),
  withLoader
);
