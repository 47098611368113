import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { getTwoDimensionalArrayIterator } from '../array';

/* Will be removed as soon as BE will have implemented own mapping */
const DYNAMIC_ITEM_TO_STATIC_ITEM_MAP = {
  name: 'labelText'
};

const DYNAMIC_TO_STATIC_MAP = {
  checklistItems: 'items',
  isExpanded: 'expanded'
};

const DYNAMIC_PROP_MAP = {
  checklistItems: (section = []) => {
    return section.map(item =>
      Object.keys(item).reduce(
        (acc, key) => ({
          ...acc,
          [DYNAMIC_ITEM_TO_STATIC_ITEM_MAP[key] ?? key]: item[key]
        }),
        {}
      )
    );
  }
};
export const mapChecklist = (checklist = []) => {
  return checklist.map(section =>
    Object.keys(section).reduce((acc, key) => {
      return {
        ...acc,
        [DYNAMIC_TO_STATIC_MAP[key] ?? key]: DYNAMIC_PROP_MAP[key] ? DYNAMIC_PROP_MAP[key](section[key]) : section[key]
      };
    }, {})
  );
};
/* ---- */

export const getChecklistFirstItem = (checklist, defaultValue) => {
  return ObjectShim.getNested(checklist, '0.items.0', defaultValue);
};

export const getChecklistItemBy = (checklist, callback, defaultValue) => {
  const items = getTwoDimensionalArrayIterator(checklist, 'items');

  for (const item of items) {
    if (callback(item)) {
      return item;
    }
  }

  return getChecklistFirstItem(checklist, defaultValue);
};

export const getNextChecklistItemBy = (checklist, callback, defaultValue) => {
  const items = getTwoDimensionalArrayIterator(checklist, 'items');

  let match = false;

  for (const item of items) {
    // if previous iteration was success return current item
    if (match) {
      return item;
    }

    // store the match of current iteration
    match = callback(item);
  }

  return getChecklistFirstItem(checklist, defaultValue);
};
