import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Grid, Column } from '~/components/layout/grid';
import styles from '../../styles/components/page-header.module.scss';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { CustomPageHeadings as CustomPageWrapper } from '../widgets/components/headings';

export const Heading = React.memo(({ renderAs: Component = 'h1', children, className }) => (
  <Component className={classnames(styles.heading, className)}>{children}</Component>
));

export const SubHeading = React.memo(({ children, className }) => (
  <p className={classnames(styles.subheading, className)}>{children}</p>
));

export const Body = React.memo(({ children, className }) => (
  <div className={classnames(styles.body, className)}>{children}</div>
));

export const Icon = React.memo(({ name, className }) => (
  <AffiliateIcon name={name} className={classnames(styles.icon, className)} />
));

export const Header = React.memo(({ withKeyline, className, children, ...props }) => {
  const { heading, subheading, icon = false, edit = false } = props;

  return (
    <header
      className={classnames(
        styles.container,
        {
          [styles['with-keyline']]: withKeyline
        },
        className
      )}
    >
      <Grid>
        <Column>
          <div className={styles.wrapper}>
            <CustomPageWrapper {...props}>
              {icon && <Icon name={icon} />}
              <Body>
                {heading && <Heading>{heading}</Heading>}
                {subheading && <SubHeading>{subheading}</SubHeading>}
                {children}
              </Body>
              {edit}
            </CustomPageWrapper>
          </div>
        </Column>
      </Grid>
    </header>
  );
});

const HeaderEditIcon = React.memo(({ onClick }) => {
  const {
    DISPLAY_COMPONENTS: { HEADING_EDIT_BUTTON }
  } = window.CONFIG;

  return (
    !!HEADING_EDIT_BUTTON && <AffiliateIcon className={styles['icon-edit']} name='nav-restart' onClick={onClick} />
  );
});

export const HeaderWithIcon = React.memo(({ to, onClick, ...props }) => {
  const history = useHistory();
  const handleClick = () => (onClick ? onClick() : history.push(to));

  return <Header edit={<HeaderEditIcon onClick={handleClick} />} {...props} />;
});
