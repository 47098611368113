import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { branch, compose, defaultProps, mapProps } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { modifyHtml } from '../../helpers/custom-page/modify-html';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';
import { omitProps } from '../../helpers/hocs/omit-props';
import { sanitizeHtml } from '../../helpers/sanitize';

const withParsing =
  Component =>
  ({ parse, ...props }) => {
    const history = useHistory();
    const ref = useRef(null);

    const parser = useMemo(() => modifyHtml(history), [history]);

    const { embeds, images, links, buttons, coachies, points } = {
      embeds: parse?.embeds ?? true,
      images: parse?.images ?? true,
      links: parse?.links ?? true,
      buttons: parse?.buttons ?? true,
      coachies: parse?.coachies ?? true,
      points: parse?.points ?? true
    };

    useLayoutEffect(() => {
      const template = ref.current;

      if (template) parser(template, { embeds, images, links, buttons, coachies, points });
    }, [parser, embeds, images, links, buttons, coachies, points]);

    return <Component ref={ref} {...props} />;
  };

const PurifiedHtmlComponent = ({ renderAs: Component = 'div', html, sanitizer, children, ...props }, ref) => (
  <Component ref={ref} dangerouslySetInnerHTML={html && { __html: sanitizeHtml(html, sanitizer) }} {...props}>
    {html === void 0 ? children : null}
  </Component>
);

const DEFAULT_CONFIG = ObjectShim.immutable(
  {
    embeds: false,
    images: false,
    links: true,
    buttons: false,
    coachies: false,
    points: false
  },
  true
);

// WARNING!!!: avoid parsing of nested instances of a component.

// set 'parse' to Boolean 'false' to any instance of
// nested PurifiedHtmlContainer
export const PurifiedHtmlContainer = compose(
  defaultProps({
    // By default parsing is turned on and only parse template links.
    parse: { links: true }
  }),
  branch(
    // if you want to prevent template parsing
    // then pass 'parse' prop with Boolean 'false' value
    ({ parse }) => parse,
    compose(
      // if you want to run all the parsers at once
      // then pass 'parse' prop with Boolean 'true' value

      // else pass an object with prefered parsers
      // specified parsers will be merged with default map
      // and then invoked before the render
      mapProps(({ parse, ...props }) => ({
        ...props,
        parse: ObjectShim.isObject(parse)
          ? {
              ...DEFAULT_CONFIG,
              ...parse
            }
          : parse
      })),
      withParsing
    ),
    omitProps(['parse'])
  ),
  withMemoAndRef
)(PurifiedHtmlComponent);
