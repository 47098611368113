import Loadable from 'react-loadable';
import { Question } from '../pages/question';
import { Loader } from '../components/with-loader';
import { DepositBuilderAccountsQuestion } from '../pages/question/enhancement/deposit-builder-accounts';
import { DepositBuilderAccountNameQuestion } from '../pages/question/enhancement/deposit-builder-account-name';
import { asTemplate } from '../components/layout/with-layout';
import { QUESTION } from '../helpers/navigation';

const PensionFinderCompanyNameQuestion = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "pensionFinderCompanyName" */ '../pages/question/enhancement/pension-finder-company-name'
    ).then(module => module.PensionFinderCompanyNameQuestion),
  loading: Loader
});

const PropertyQuestion = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "propertyQuestion" */ '../pages/question/enhancement/property-portfolio-property-question'
    ).then(module => module.PropertyQuestion),
  loading: Loader
});

const PropertyMortgageQuestion = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "propertyMortgageQuestion" */ '../pages/question/enhancement/property-portfolio-property-mortgage-question'
    ).then(module => module.PropertyMortgageQuestion),
  loading: Loader
});

export const questions = [
  {
    to: '/checklist/deposit-builder/accounts',
    component: asTemplate(QUESTION)(DepositBuilderAccountsQuestion)
  },
  {
    to: '/checklist/deposit-builder/name-your-account',
    component: asTemplate(QUESTION)(DepositBuilderAccountNameQuestion)
  },
  {
    to: '/checklist/pension-finder/company-name',
    component: asTemplate(QUESTION)(PensionFinderCompanyNameQuestion)
  },
  {
    to: '/checklist/property-portfolio/:slug',
    component: asTemplate(QUESTION)(PropertyQuestion)
  },
  {
    to: '/checklist/addMortgage/:slug',
    component: asTemplate(QUESTION)(PropertyMortgageQuestion)
  },
  {
    to: '/checklist/:activity/:slug/:identifier?',
    component: asTemplate(QUESTION)(Question)
  }
];
