import { createCustomPageProps } from '../utils';

export const goToComplete = (pathname, redirect) => {
  const customPageUrl = `/checklist/${createCustomPageProps(pathname).name}`;

  switch (redirect) {
    case 'start':
      return customPageUrl + '/start';
    case 'dashboard':
      return '/dashboard';
    case 'result':
    default:
      return customPageUrl + '/result';
  }
};
