import { isNil } from '@packages/helpers/core/common';

export const IS_ADD_EVENT_LISTENER_SUPPORTED = target => {
  return !isNil(target) && 'addEventListener' in Object.getPrototypeOf(target);
};

// this self-immediate function test whether passive options of
// addEventListener is supported by browser.

// 'passive' option restricts using of preventDefault() method of event;
// it means if addEventListener callback will try to prevent default action
// on passive listener browser will ignore that invokation and throw a warning
// to console

// passive listeners improve performance of consecutive
// events like 'scroll' or 'touchmove'

// but in some cases when you need to prevent a scroll or user interaction
// you should first be sure that listener is not a passive

// in legacy browsers, the 3rd arg of addEventListener it is a bool flag of
// a 'capture' option.

// so it means if addEventLsitener in legacy browser doesn't support options arg
// or 'passive' options we could unintentionally start to capture an event
// instead of making it active. It could cause bugs.

// https://developer.mozilla.org/ru/docs/Web/API/EventTarget/addEventListener
export const IS_PASSIVE_SUPPORTED = (() => {
  let supportsPassiveOption = false;

  try {
    // to handle a execution of 'passive' prop by addEventListener
    // we should somohow intercept an operation.
    // lets define get function for property 'passive'.
    // it will be executed when addEventListener get access to the prop.
    const opts = Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassiveOption = true;

        return true;
      }
    });

    // add fake listener to test 'passive' prop support.
    window.addEventListener('test', null, opts);
    window.removeEventListener('test', null, opts);
  } catch (e) {}

  return supportsPassiveOption;
})();

export const prevent = (event, cb) => {
  if (cb) {
    cb();
  }

  event.preventDefault();

  return false;
};

export const stop = (event, cb) => {
  if (cb) {
    cb();
  }

  event.stopPropagation();

  return true;
};

export const getEventCoordinates = (event, relative) => {
  const pointer = {};

  switch (relative) {
    case 'client':
      // clientX/Y gives the coordinates relative to the viewport in CSS pixels.
      pointer.xCoord = event.touches ? event.touches[0].clientX : event.clientX;
      pointer.yCoord = event.touches ? event.touches[0].clientY : event.clientY;
      break;
    case 'screen':
      // screenX/Y gives the coordinates relative to the screen in device pixels.
      pointer.xCoord = event.touches ? event.touches[0].screenX : event.screenX;
      pointer.yCoord = event.touches ? event.touches[0].screenY : event.screenY;
      break;
    default:
      // pageX/Y gives the coordinates relative to the <html> element in CSS pixels.
      pointer.xCoord = event.touches ? event.touches[0].pageX : event.pageX;
      pointer.yCoord = event.touches ? event.touches[0].pageY : event.pageY;
      break;
  }

  return pointer;
};

export class Touch {
  static isSingle = event => {
    if (event instanceof TouchEvent) {
      return event.touches.length === 1;
    }

    return false;
  };
}

const KEYS_CONSTRAINTS = {
  escape: [
    ['key', 'Escape'],
    ['keyCode', 27]
  ]
};

export class Key {
  static isSpecificKey = key => event => {
    if (event instanceof KeyboardEvent) {
      return KEYS_CONSTRAINTS[key]?.every(([key, value]) => event[key] === value);
    }

    return false;
  };
}
