import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getThemeByPathname } from '../helpers/layout';

const ThemeContext = React.createContext();

const { Provider, Consumer } = ThemeContext;

const ThemeProvider = React.memo(({ children }) => {
  const { pathname } = useLocation();
  const [theme, setTheme] = useState(() => ({ prev: null, current: null }));
  const themedPages = useSelector(state => state.navigation.themedPages);

  useEffect(() => {
    setTheme(theme => ({
      prev: theme.current,
      current: getThemeByPathname(pathname, themedPages)
    }));
  }, [pathname, themedPages]);

  useEffect(() => {
    theme.prev && document.documentElement.classList.remove(theme.prev);
    theme.current && document.documentElement.classList.add(theme.current);
  }, [theme.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return <Provider value={theme.current}>{children}</Provider>;
});

export { ThemeContext, ThemeProvider, Consumer as ThemeConsumer };
