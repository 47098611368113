import { FunctionShim } from '@packages/helpers/core/shims/function-shim';
import { getScrollYPosition } from './scroll';

export const isHtmlElement = (target, IO = HTMLElement) => target instanceof IO;

const getElementNearestSvgNode = (target = null, IO = SVGGraphicsElement, exceptions = []) => {
  let element = target;

  while (element) {
    // eslint-disable-next-line no-loop-func
    if (exceptions.some(EIO => element instanceof EIO)) {
      element = element.parentNode;

      continue;
    }

    if (element instanceof IO) {
      return {
        element,
        root: element.ownerSVGElement
      };
    }

    element = element.parentNode;
  }

  return element;
};

export const getNearestSvgNode = FunctionShim.memoize(getElementNearestSvgNode);

const TOUCH_CONDITIONS = {
  top: (value, fixed) => {
    return Math.round(fixed ? value : value + getScrollYPosition()) === 0;
  },
  bottom: (value, fixed) => {
    return Math.round(fixed ? value : value + getScrollYPosition()) === window.innerHeight;
  },
  left: value => {
    return Math.round(value) === 0;
  },
  right: value => {
    return Math.round(value) === window.innerWidth;
  }
};

export const isElementTouchesEdge = (target, { edge, fixed } = {}) => {
  if (isHtmlElement(target)) {
    const rect = target.getBoundingClientRect();

    if (edge in rect) {
      return TOUCH_CONDITIONS[edge](rect[edge], fixed);
    }
  }

  return false;
};
