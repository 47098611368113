import { isNil } from '@packages/helpers/core/common';
import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { parse } from './parse-condition';

// eslint-disable-next-line import/no-webpack-loader-syntax
import * as workerBase from 'workerize-loader!../../../helpers/workers/evaluate-condition';

const worker = workerBase();

const parseValues = async values => {
  const acc = {};

  for (const key of Object.keys(values)) {
    //if value is not valid - it will not be added
    try {
      const parsed = parse(values[key]);
      const evaluated = await worker.evaluate(parsed);

      if (!isNil(evaluated)) {
        acc[key] = evaluated;
      }
    } catch {}
  }

  return acc;
};

export const processAdditionalValues = async value => {
  try {
    const normalized = ArrayShim.normalize(value, 'userAttributeType');

    const acc = {};

    for (const key of Object.keys(normalized)) {
      acc[key] = await parseValues(normalized[key].userAttributeValues);
    }

    return acc;
  } catch {
    return false;
  }
};
