import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

const masks = {
  'DD/MM/YYYY': '99/99/9999',
  'MM/YYYY': '99/9999',
  YYYY: '9999'
};

export const QuestionDateInput = React.memo(
  ({ label, onChange, value, max, min, submit, errorMessages, format, maskChar, additionalRules }) => (
    <Input
      type='date-time'
      inputType='date'
      label={label}
      onChange={onChange}
      value={value}
      onSubmit={submit}
      mask={masks[format] || '99/99/9999'}
      maskChar={maskChar || '_'}
      max={max}
      min={min}
      errorMessages={errorMessages}
      format={format}
      additionalRules={additionalRules}
    />
  )
);
