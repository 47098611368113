import { CalculatorHomePage } from '../pages/home-page/calculators';
import { Checklist } from '../pages/checklist';
import { RefreshPage } from '../components/templates/refresh-page';
import { asTemplate } from '../components/layout/with-layout';
import { CALCULATORS, CHECKLIST, CHECKPOINT } from '../helpers/navigation';

export const homePageRoutes = [
  {
    to: '/calculators',
    component: asTemplate(CALCULATORS)(CalculatorHomePage)
  },
  {
    to: '/checklist',
    hideOn: 'lgAndUp',
    component: asTemplate(CHECKLIST)(Checklist)
  },
  {
    to: '/updating-results',
    component: asTemplate(CHECKPOINT)(RefreshPage)
  },
  {
    to: '/updating-results/:gist',
    component: asTemplate(CHECKPOINT)(RefreshPage)
  }
];
