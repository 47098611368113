import Loadable from 'react-loadable';
import { asTemplate } from '../components/layout/with-layout';
import { Loader } from '../components/with-loader';
import { LEGAL } from '../helpers/navigation';

const LegalPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "legalPage"*/ '../components/templates/legal-page').then(module => module.Legal),
  loading: Loader
});

export const legalPageRoutes = [
  {
    to: '/help/:tag',
    component: asTemplate(LEGAL)(LegalPage)
  }
];
