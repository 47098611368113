import { createReducer } from '../utils';

const SHOW_CONSENT = 'SHOW_CONSENT';
const SHOW_SHARING = 'SHOW_SHARING';
const SHOW_HELP = 'SHOW_HELP';

const types = {
  consent: SHOW_CONSENT,
  sharing: SHOW_SHARING,
  help: SHOW_HELP
};

export const toggleModal = (value, type = 'consent', params) => ({ type: types[type], value, params });

const initialState = {
  showConsent: false,
  showSharing: false,
  showHelp: false,
  params: {}
};

export const modals = createReducer(initialState, {
  [SHOW_CONSENT]: (state, { value }) => ({ ...state, showConsent: value }),
  [SHOW_SHARING]: (state, { value }) => ({ ...state, showSharing: value }),
  [SHOW_HELP]: (state, { value, params }) => ({ ...state, showHelp: value, params: params || {} })
});
