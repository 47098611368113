import React from 'react';
import classnames from 'classnames';
import { Grid, Column } from '~/components/layout/grid';
import styles from '../styles/components/template.module.scss';
import { withMemoAndRef } from '../helpers/hocs/with-memo-and-ref';
import { Spacer } from './layout/spacers';
import { PurifiedHtmlContainer } from './layout/purified-html-container';
import { AspectRatioImage } from './images/aspect-ratio-image';
import { Loader } from './with-loader';

const TemplateComponent = ({ className, children, ...props }, ref) => (
  <article ref={ref} className={classnames(styles.container, className)} {...props}>
    <Grid>{children}</Grid>
  </article>
);

const Component = ({ children, classNames = {}, ...props }, ref) => {
  return (
    <Column ref={ref} {...props}>
      <div className={classnames(styles.component, classNames.component)}>{children}</div>
    </Column>
  );
};

const Image = ({ renderAs, edge, ...props }, ref) => (
  <Column ref={ref} renderAs={renderAs} className={styles.image} edge={edge}>
    <AspectRatioImage {...props} />
  </Column>
);

const Body = ({ wrapperAs: Wrapper = Column, html, children, ...props }, ref) => {
  return (
    <Wrapper ref={ref} className={classnames(styles.body, 'typography typography-extended')} {...props}>
      <PurifiedHtmlContainer
        parse
        html={html}
        sanitizer={{
          ADD_TAGS: ['iframe'],
          ADD_ATTR: ['allowfullscreen', 'frameborder']
        }}
      >
        {children}
      </PurifiedHtmlContainer>
    </Wrapper>
  );
};

export const Template = withMemoAndRef(TemplateComponent);
export const TComponent = withMemoAndRef(Component);
export const TImage = withMemoAndRef(Image);
export const TBody = withMemoAndRef(Body);

export const TSubHeading = React.memo(({ renderAs: SubHeading = 'p', children, className }) => (
  <SubHeading className={classnames(styles.subtitle, className)}>{children}</SubHeading>
));

export const THeading = React.memo(({ renderAs: Heading = 'h2', children, className }) => (
  <Heading className={classnames(styles.title, className)}>{children}</Heading>
));

export const THeadlineContainer = React.memo(({ renderAs: Component = 'div', children, className }) => (
  <Component className={classnames(styles.headline, className)}>{children}</Component>
));

export const THeadline = React.memo(
  ({ title, subtitle, className }) =>
    title && (
      <div className={className}>
        <THeadlineContainer>
          <THeading>{title}</THeading>
          {subtitle && (
            <>
              <Spacer renderAs='div' type='content' />
              <TSubHeading>{subtitle}</TSubHeading>
            </>
          )}
        </THeadlineContainer>
        <Spacer renderAs='div' type='content' />
      </div>
    )
);

export const TLoader = () => (
  <Column>
    <Loader />
  </Column>
);

export const withLoader = Component => props => {
  if (props.loading) {
    return <TLoader />;
  } else {
    return <Component {...props} />;
  }
};
