import Loadable from 'react-loadable';
import { asTemplate } from '../../components/layout/with-layout';
import { Loader } from '../../components/with-loader';
import { ACCOUNT, CHECKPOINT } from '../../helpers/navigation';

const ResetPasswordEmail = Loadable({
  loader: () =>
    import(/* webpackChunkName: "reset-password-email"*/ '../../pages/account/reset-password/email').then(
      module => module.ResetPasswordEmail
    ),
  loading: Loader
});

const ResetPasswordMagicCode = Loadable({
  loader: () =>
    import(/* webpackChunkName: "reset-password-magic-code"*/ '../../pages/account/reset-password/magic-code').then(
      module => module.ResetPasswordMagicCode
    ),
  loading: Loader
});

const ResetPasswordPassword = Loadable({
  loader: () =>
    import(/* webpackChunkName: "reset-password-password"*/ '../../pages/account/reset-password/password').then(
      module => module.ResetPasswordPassword
    ),
  loading: Loader
});

const ResetPasswordCompletePage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "reset-password-complete-page"*/ '../../pages/account/components/complete-pages').then(
      module => module.ResetPasswordCompletePage
    ),
  loading: Loader
});

export const routes = [
  {
    to: '/account/reset-password',
    component: asTemplate(ACCOUNT)(ResetPasswordEmail)
  },
  {
    to: '/account/reset-password/magic-code',
    component: asTemplate(ACCOUNT)(ResetPasswordMagicCode)
  },
  {
    to: '/account/reset-password/password',
    component: asTemplate(ACCOUNT)(ResetPasswordPassword)
  },
  {
    to: '/account/reset-password/complete',
    component: asTemplate(CHECKPOINT)(ResetPasswordCompletePage)
  }
];
