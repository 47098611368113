import uuid from 'uuid';
import ReactDOMServer from 'react-dom/server';
import React from 'react';
import { compose } from 'redux';
import { openLink } from '../../utils';
import { getAffiliateIconSync } from '../../layout';
import { isHtmlElement } from '../../layout/element';
import { withContainer } from '../../../components/inputs/input-field';
import { Button } from '../../../components/buttons/button-with-icon';
import { InputGroup } from '../../../components/inputs/input-group';
import { attachEventToElementById, getElementFromHtmlString } from './helpers';

const ButtonWithIcon = withContainer(Button);

const parseUrl = urls => {
  const url = urls.split('/').slice(3);

  if (url) {
    return '/' + url.join('/');
  }

  return '/';
};

const parseButtonProps = element => {
  const link = isHtmlElement(element) && element.querySelector('.wp-block-button__link');

  if (link) {
    const { classList } = element;
    const { innerText, href, target } = link;
    const [, type = 'major'] = classList;
    const isExternal = Boolean(target);

    return {
      type,
      innerText,
      isExternal,
      href: isExternal ? href : parseUrl(href)
    };
  }

  return {};
};

export const renderButton = history => element => {
  const { innerText, type, isExternal, href } = parseButtonProps(element);

  const id = uuid();

  const jsx = (
    <ButtonWithIcon id={id} type={type} icon={getAffiliateIconSync('button-yes')}>
      {innerText}
    </ButtonWithIcon>
  );

  return {
    id,
    Component: jsx,
    node: compose(getElementFromHtmlString, ReactDOMServer.renderToStaticMarkup)(jsx),
    handler: () => openLink(href, history, isExternal)
  };
};

export const changeButtons = history => element => {
  const children = element.querySelectorAll('.wp-block-button, .wp-block-buttons');

  children.forEach(child => {
    const { id, Component: Button, handler } = renderButton(history)(child);

    const jsx = <InputGroup>{React.cloneElement(Button)}</InputGroup>;

    const component = compose(
      attachEventToElementById({ id, handler }),
      getElementFromHtmlString,
      ReactDOMServer.renderToStaticMarkup
    )(jsx);

    child.parentNode.replaceChild(component, child);
  });

  return element;
};
