import { Helmet } from 'react-helmet';
import { compose, withHandlers } from 'recompose';
import React from 'react';
import { connect } from 'react-redux';
import { sendCreditScore } from '../../store/reducers/user-attributes/actions';
import { Question } from '../../components/templates/question-template';
import { InputButtonWithIcon as ButtonWithIcon } from '../../components/buttons/button-with-icon';

const message = (
  <>
    <p>Do you pay your rent from your bank account?</p>
    <p>It’s a purely digital service so won’t work with cash or cheque payments.</p>
  </>
);

const PaymentMethodQuestion = ({ setCreditScore }) => (
  <>
    <Helmet title='Payment method' />
    <Question message={message}>
      <ButtonWithIcon onClick={() => setCreditScore(true)}>Yes</ButtonWithIcon>
      <ButtonWithIcon onClick={() => setCreditScore(false)}>No</ButtonWithIcon>
    </Question>
  </>
);

const mapStateToProps = state => {
  const creditScore = state.userAttributes['credit-score'];

  return {
    tenant: creditScore.tenant,
    regularRentPayments: creditScore.regularRentPayments
  };
};

export const PaymentMethod = compose(
  connect(mapStateToProps, { sendCreditScore }),
  withHandlers({
    setCreditScore:
      ({ history, sendCreditScore, tenant, regularRentPayments }) =>
      async value => {
        await sendCreditScore({ rentPaidFromBankAccount: value, tenant, regularRentPayments });
        history.push('/checklist/rent-reporting/done');
      }
  })
)(PaymentMethodQuestion);
