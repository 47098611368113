import React from 'react';
import { connect } from 'react-redux';
import { branch, compose, renderNothing } from 'recompose';
import { UserService } from '../../../services/user-servcie';
import { Link } from '../../link';
import { Points } from './components/points';

export const Container = compose(
  connect(
    state => ({
      consent: state.userAttributes.consents?.promotions
    }),
    branch(({ promotions }) => {
      return !promotions || UserService.hasAccount();
    }, renderNothing)
  )
);

const Component = () => {
  const {
    POINTS: {
      POINTS_HOME: { allAwardsUnlockedText, nextUnlockText, pointsText }
    }
  } = window.CONFIG;

  return (
    <Link to='/points-and-rewards'>
      <Points allAwardsUnlockedText={allAwardsUnlockedText} nextUnlockText={nextUnlockText} pointsText={pointsText} />
    </Link>
  );
};

export const PointsWidgetVM = Container(Component);
