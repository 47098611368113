import React from 'react';
import { connect } from 'react-redux';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../buttons/button-with-icon';
import { NativeAction } from '../../../../services/native-action';
import { getNextActivity } from '../../../../store/reducers/digital-coach';

export const RateAppButtonComponent = React.memo(({ label, getNextActivity }) => {
  const onClick = async () => {
    NativeAction.trigger('rate');
    await getNextActivity();
  };

  return <ButtonWithIcon onClick={onClick}>{label}</ButtonWithIcon>;
});

export const RateAppButton = connect(null, { getNextActivity })(RateAppButtonComponent);
