import React from 'react';
import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { Select } from './components/select';

export const QuestionSelectInput = React.memo(({ onSelect, submit, items, value, placeholder, label }) => {
  const currentValue = ArrayShim.normalize(items, 'value')[value]?.label ?? value;

  return (
    <Select
      name='select'
      label={label}
      placeholder={placeholder}
      value={currentValue}
      items={items}
      onChange={onSelect}
      onSubmit={submit}
      disabled={!value}
    />
  );
});
