import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { branch, compose, renderComponent } from 'recompose';
import { withMemoAndRef } from '../../../helpers/hocs/with-memo-and-ref';
import { scrollTo } from '../../../helpers/layout/scroll';
import { isTypeOf } from '../../../helpers/digital-coach';
import { addToast } from '../../../store/reducers/toast';
import { Modal } from '../../modal';

const Component = ({ children }, ref) => {
  return <div ref={ref}>{children}</div>;
};

const DigitalCoach = withMemoAndRef(Component);

const SlideUpDigitalCoach = React.memo(({ type, ...props }) => {
  const [show, setVisibility] = useState(true);

  const hideModal = useCallback(() => {
    setVisibility(false);
  }, []);

  const label = type?.label ?? 'Digital Coach Special Condition';
  const force = type?.force ?? false;

  return (
    <>
      <DigitalCoach {...props} />
      <Modal show={show} label={label} onClose={!force && hideModal}>
        <DigitalCoach {...props} />
      </Modal>
    </>
  );
});

const SlideToDigitalCoachComponent = React.memo(({ type, addToast, ...props }) => {
  const ref = useRef(null);

  const onToastClick = useCallback(() => {
    const dc = ref.current;

    if (dc) {
      const { top } = dc.getBoundingClientRect();

      scrollTo(window, { top, incremental: true, behavior: 'smooth' });
    }
  }, []);

  const message = type?.message ?? 'Scroll To DCSC';

  useEffect(() => {
    addToast({ message, callback: onToastClick });
  }, [message, addToast, onToastClick]);

  return <DigitalCoach ref={ref} {...props} />;
});

const SlideToDigitalCoach = connect(null, { addToast })(SlideToDigitalCoachComponent);

export const DigitalCoachDisplayController = compose(
  branch(({ type }) => isTypeOf(type, 'overlay'), renderComponent(SlideUpDigitalCoach)),
  branch(({ type }) => isTypeOf(type, 'toast'), renderComponent(SlideToDigitalCoach))
)(DigitalCoach);
