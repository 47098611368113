import { useRef, useEffect } from 'react';
import { IS_ADD_EVENT_LISTENER_SUPPORTED } from '../layout/event';

export const useEventListener = (eventName, handler, target = window) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (IS_ADD_EVENT_LISTENER_SUPPORTED(target)) {
      const eventListener = event => savedHandler.current(event);

      target.addEventListener(eventName, eventListener);

      return () => {
        target.removeEventListener(eventName, eventListener);
      };
    }
  }, [eventName, target]);
};
