import React from 'react';
import { compose, lifecycle } from 'recompose';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputButtonWithIcon as ButtonWithIcon } from '../../components/buttons/button-with-icon';
import { Question } from '../../components/templates/question-template';
import { UserService } from '../../services/user-servcie';
import { setRedirectionLink } from '../../store/reducers/navigation';
import { ConfigService } from '../../services/config-service';

export const BarrierPage = React.memo(() => {
  const {
    message,
    buttons: { confirm, decline }
  } = ConfigService.get('ACCOUNT.BARRIER_PAGE', {});

  return (
    <Question message={<p>{message}</p>} hideCloseButton>
      <ButtonWithIcon to='/account/login'>{confirm}</ButtonWithIcon>
      <ButtonWithIcon type='minor' to='/account/sign-up'>
        {decline}
      </ButtonWithIcon>
    </Question>
  );
});

const RedirectToBarrier = () => <Redirect to='/barrier' />;

const RedirectToBarrierContainer = compose(
  connect(null, { setRedirectionLink }),
  withRouter,
  lifecycle({
    componentDidMount() {
      const {
        location: { pathname },
        setRedirectionLink
      } = this.props;

      setRedirectionLink(pathname);
    }
  })
)(RedirectToBarrier);

export const withBarrier = Component => props => {
  if (UserService.hasAccount()) {
    return <RedirectToBarrierContainer />;
  }

  return <Component {...props} />;
};
