import React from 'react';
import classnames from 'classnames';
import styles from '../../styles/components/input-group.module.scss';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';

const InputGroupComponent = (
  { renderAs: Component = 'div', children, className, type, atid, heir = false, question = false, ...props },
  ref
) => {
  return (
    <Component
      ref={ref}
      data-atid={atid}
      className={classnames(
        {
          [styles.provider]: !heir,
          [styles.question]: question,
          [styles[type]]: type
        },
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

export const InputGroup = withMemoAndRef(InputGroupComponent);

const FormComponent = ({ onSubmit, ...props }, ref) => {
  const handleSubmit = event => {
    event.preventDefault();

    if (onSubmit) {
      onSubmit(event);
    }
  };

  return <InputGroup ref={ref} renderAs='form' onSubmit={handleSubmit} {...props} />;
};

export const Form = withMemoAndRef(FormComponent);
