import { isNil } from '@packages/helpers/core/common';

const isStatus = status => {
  return !isNil(status) && ['key', 'order'].every(prop => status.hasOwnProperty(prop));
};

export const parseStatuses = statuses => {
  const result = {};

  for (let [key, { status }] of Object.entries(statuses)) {
    if (isStatus(status)) {
      result[key] = {
        key: status.key,
        order: status.order
      };
    } else {
      throw Error(`Every status should have next fields: 'key', 'order'`);
    }
  }

  return result;
};

export const isValid = (activity, status, statuses) => {
  return statuses.hasOwnProperty(activity) && isStatus(status);
};

export const hasHigherOrder = (activity, status, statuses) => {
  const prevStatus = statuses[activity];

  if (!isStatus(prevStatus) || isNil(status.order)) {
    return true;
  }

  return status.key !== prevStatus.key && status.order >= prevStatus.order;
};
