import React from 'react';
import { Column } from '~/components/layout/grid';
import { ConfigService } from '../../../services/config-service';
import { Header } from '../../layout/page-header';
import { Spacer } from '../../layout/spacers';
import { Template } from '../../template';
import { ChecklistCategory } from './category';
import { withChecklist } from './with-checklist';

const Component = React.memo(({ checklist = [] }) => {
  const { pageTitle, subtitle } = ConfigService.get('CHECKLIST', {});

  return (
    <>
      <Header heading={pageTitle} subheading={subtitle} withKeyline />
      <Template>
        <Spacer type='top' />
        <Column edge={['xss', 'lg']}>
          {checklist.map(category => (
            <ChecklistCategory key={category.heading} {...category} />
          ))}
        </Column>
        <Spacer type='bottom' />
      </Template>
    </>
  );
});

export const Checklist = withChecklist(Component);
