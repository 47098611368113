import React from 'react';
import { branch, compose, renderNothing } from 'recompose';
import styles from '../../styles/components/modal-header.module.scss';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { PurifiedHtmlContainer } from '../layout/purified-html-container';

const ModalHeaderComponent = React.memo(({ html, title, subTitle, children, onClose }) => (
  <header className={styles.container}>
    <PurifiedHtmlContainer html={html} className={styles.wrapper}>
      {(title || onClose) && (
        <h2 className={styles.label}>
          {title && <span className={styles.title}>{title}</span>}
          {onClose && <AffiliateIcon name='nav-exit' className={styles.icon} onClick={onClose} />}
        </h2>
      )}
      {subTitle && <p className={styles.subtitle}>{subTitle}</p>}
    </PurifiedHtmlContainer>
    {children}
  </header>
));

export const ModalHeader = compose(
  branch(({ html, title, subTitle, children, onClose }) => {
    return [html, title, subTitle, children, onClose].filter(Boolean).length < 1;
  }, renderNothing)
)(ModalHeaderComponent);
