import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose, withHandlers } from 'recompose';
import { sendCreditScore } from '../../store/reducers/user-attributes/actions';
import { Question } from '../../components/templates/question-template';
import { InputButtonWithIcon as ButtonWithIcon } from '../../components/buttons/button-with-icon';

const message = (
  <>
    <p>Are you currently living in rented accommodation?</p>
    <p>This service only works if you’re a renter.</p>
  </>
);

const RentingQuestion = ({ setCreditScore }) => (
  <>
    <Helmet title='Renting' />
    <Question message={message}>
      <ButtonWithIcon onClick={() => setCreditScore(true)}>Yes</ButtonWithIcon>
      <ButtonWithIcon onClick={() => setCreditScore(false)}>No</ButtonWithIcon>
    </Question>
  </>
);

export const Renting = compose(
  connect(null, { sendCreditScore }),
  withHandlers({
    setCreditScore:
      ({ history, sendCreditScore }) =>
      async value => {
        if (value) {
          await sendCreditScore({ tenant: true });
          history.push('/checklist/rent-reporting/regular-payments');
        } else {
          await sendCreditScore({ tenant: false });
          history.push('/checklist/rent-reporting/done');
        }
      }
  })
)(RentingQuestion);
