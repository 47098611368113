import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from '../../styles/components/application.module.scss';
import { ConfigService } from '../../services/config-service';
import { Sidebar } from './sidebar';
import { ApplicationHeader } from './application-header';
import { ApplicationFooter } from './application-footer';
import { Animation } from './animation';

export const Application = ({ children }) => {
  useLayoutEffect(() => {
    const { lottie } = ConfigService.get('CHECKPOINT', {});

    if (lottie) {
      Animation.preload();
    }
  }, []);

  const offset = useSelector(state => state.layout.sizes.sidebar);

  return (
    <>
      <ApplicationHeader className={styles.header} />
      <main className={styles.content} style={{ paddingLeft: offset }}>
        <Sidebar className={styles.sidebar} />
        {children}
      </main>
      <ApplicationFooter className={styles.footer} />
    </>
  );
};
