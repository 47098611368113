import React from 'react';
import { compose, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ButtonWithIcon } from '../../../components/buttons/button-with-icon';

import { toggleModal } from '../../../store/reducers/modals';
import { getFAQ } from '../../../store/reducers/faq';
import { withContainer } from './components/input-field';

const Input = withContainer(ButtonWithIcon);

export const Component = React.memo(({ label, buttonType, toggleModal }) => {
  return (
    <Input onClick={toggleModal} type={buttonType} buttonType='submit'>
      {label}
    </Input>
  );
});

export const QuestionInfoButton = compose(
  withRouter,
  connect(null, { toggleModal, getFAQ }),
  withHandlers({
    toggleModal:
      ({ toggleModal, modalContent }) =>
      () =>
        toggleModal(true, 'help', { faqName: modalContent, faqIndex: 0 })
  }),
  lifecycle({
    async componentDidMount() {
      const { getFAQ, modalContent } = this.props;

      await getFAQ({ name: modalContent });
    }
  })
)(Component);
