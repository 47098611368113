import { compose, branch, renderComponent } from 'recompose';
import { withGoogleBotConsent } from './bot';
import { ExpressConsent } from './express';
import { NonUserConsent } from './non-user';
import { withBugsnag } from './bugsnag';
import { withDataSharingAllowed } from './data-sharing-allowed';

export const Consents = compose(
  withDataSharingAllowed,
  withBugsnag,
  withGoogleBotConsent,
  branch(() => window.CONFIG.DISPLAY_MODULES.CONSENT_PAGE, renderComponent(ExpressConsent))
)(NonUserConsent);
