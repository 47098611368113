import React from 'react';
import { connect } from 'react-redux';
import { compose, branch, renderNothing, lifecycle, withPropsOnChange, withHandlers, withState } from 'recompose';
import { UserService } from '../../../../../services/user-servcie';
import { CONSENTS_TYPES } from '../../../../../helpers/constants';
import { toggleModal } from '../../../../../store/reducers/modals';
import { sendConsents } from '../../../../../store/reducers/user-attributes/actions';
import { sendStatus } from '../../../../../store/reducers/status-data';
import { Modal } from '../../../../modal';
import { ManagedState } from './components/managed';
import { UnmanagedState } from './components/unmanaged';

const ExpressConsentComponent = React.memo(
  ({
    type,
    footer,
    header,
    show,
    consentsState,
    onConsentsChange,
    setConsentsType,
    handleAccept,
    handleAcceptAll,
    handleInputAppear
  }) => {
    let modalBody;

    switch (type) {
      case CONSENTS_TYPES.MANAGED:
        modalBody = (
          <ManagedState
            value={consentsState}
            onAccept={handleAccept}
            onChange={onConsentsChange}
            onInputAppear={handleInputAppear('manageSeen')}
          />
        );
        break;
      default:
        modalBody = (
          <UnmanagedState
            onAccept={handleAcceptAll}
            onManage={setConsentsType(CONSENTS_TYPES.MANAGED)}
            onInputAppear={handleInputAppear('acceptSeen')}
          />
        );
        break;
    }

    return (
      <Modal key={type} label='User consents' show={show} title={header} footer={footer}>
        {modalBody}
      </Modal>
    );
  }
);

const ExpressConsentContainer = compose(
  withState('type', 'setType', () => CONSENTS_TYPES.UNMANAGED),
  withState('consentsState', 'onConsentsChange', ({ consents }) => consents),
  withHandlers({
    reportStatus:
      ({ sendStatus }) =>
      status => {
        sendStatus('consentPopup', status);
      }
  }),
  withHandlers({
    setConsentsType:
      ({ setType }) =>
      type =>
      () => {
        setType(type);
      },
    handleAccept:
      ({ toggleModal, reportStatus, sendConsents, consentsState }) =>
      () => {
        toggleModal(false, 'consent');
        reportStatus('manageComplete');

        sendConsents(consentsState);
      },
    handleAcceptAll:
      ({ toggleModal, reportStatus, sendConsents, consents, isDataSharingAllowed, defaultConsents }) =>
      () => {
        toggleModal(false, 'consent');

        // TODO: Move to ObjectShim
        let allTrue = {};

        if (!isDataSharingAllowed) {
          allTrue = defaultConsents;
        } else {
          Object.keys(consents).forEach(consent => {
            allTrue[consent] = true;
          });
        }

        reportStatus('acceptComplete');
        sendConsents(allTrue);
      },
    handleInputAppear:
      ({ reportStatus }) =>
      status =>
      () => {
        reportStatus(status);
      }
  }),
  withPropsOnChange(['type'], ({ type }) => ({
    header: type === CONSENTS_TYPES.MANAGED && window.CONFIG.ACCOUNT.CONSENT_PAGE.managed.title,
    footer: type === CONSENTS_TYPES.MANAGED && { html: window.CONFIG.ACCOUNT.CONSENT_PAGE.managed.legalFooter }
  })),
  lifecycle({
    componentDidMount() {
      const { reportStatus } = this.props;

      reportStatus('seen');
    }
  })
)(ExpressConsentComponent);

const mapStateProps = state => {
  const defaultConsents = state.consentList.defaults;
  const currentConsents = state.userAttributes.consents;

  return {
    isDataSharingAllowed: state.consentList.dataSharingAllowed,
    show: !UserService.hasAccount() && state.userAttributes.consents && !state.userAttributes.consents.essential,
    consents: { ...defaultConsents, ...currentConsents },
    defaultConsents
  };
};

export const ExpressConsent = compose(
  connect(mapStateProps, { toggleModal, sendConsents, sendStatus }),
  branch(({ show }) => !show, renderNothing)
)(ExpressConsentContainer);
