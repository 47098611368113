import { get, post, put, remove } from './utils';

const getActivities = async () => get('/status-data/digitalCoach');
// todo: temporary solution. Will be changed later
const getNextActivity = async () =>
  get(`/status-data/next-activity?page=${window.location.pathname.split('/').splice(1).join('-')}`);
const sendStatusData = async params => post(`/status-data`, params);
const removeStatusData = async ({ activityKey, activityStatusKey }) =>
  remove(`/status-data/${activityKey}/${activityStatusKey}`);
const recalculateActivity = async () => put('/status-data/recalculate-activity');
const getStatusData = async () => get(`/status-data`);

const callCustomPostAction = async (url, params = {}) => post(url, params);

export const statusData = {
  getActivities,
  getNextActivity,
  sendStatusData,
  getStatusData,
  removeStatusData,
  recalculateActivity,
  callCustomPostAction
};
