import { branch, compose, defaultProps, lifecycle, renderComponent, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { generateError } from '../../../../../helpers/inputs';
import { inputSchema } from '../../../helpers/schemas';
import { omitProps } from '../../../../../helpers/hocs/omit-props';
import { Input as BasicInput, withInputLabel } from '../../../../../components/inputs/input';
import { Textarea as BasicTextarea, withTextareaLabel } from '../../../../../components/inputs/textarea';
import { withAnchor, withError } from '../../../../../components/inputs/input-field';
import { withContainer, withCompanion } from '../input-field';
import { dynamicValidation } from '../../../helpers/dynamic-validations';

const CONSTRAINTS = [
  'inputType',
  'schema',
  'errorMessages',
  'errorMessage',
  'setError',
  'setSchema',
  'handleOnChange',
  'handleSubmit',
  'maxLength',
  'staticContext',
  'additionalRules'
];

const mapProps = ({ value, errorMessage, handleSubmit, handleOnChange }) => ({
  value,
  error: errorMessage,
  disabled: Boolean(errorMessage) || value === void 0,
  onSubmit: handleSubmit,
  onChange: handleOnChange
});

const Input = withInputLabel(BasicInput);
const Textarea = compose(omitProps(['textarea']), withTextareaLabel)(BasicTextarea);

export const InputWithButton = compose(
  withRouter,
  defaultProps({
    inputType: 'number'
  }),
  withState('errorMessage', 'setError', ''),
  withState('schema', 'setSchema', null),
  withHandlers({
    handleOnChange:
      ({ schema, onChange, setError }) =>
      value => {
        const { errors } = schema.validateSync(value);

        onChange(value);

        const actualError = generateError(errors);

        setError(actualError);
      },
    handleSubmit:
      ({ schema, value, setError, onSubmit }) =>
      event => {
        const { errors } = schema.validateSync(value);

        const actualError = generateError(errors);

        setError(actualError);

        if (actualError) return;

        onSubmit(event);
      }
  }),
  lifecycle({
    componentDidMount() {
      const { setSchema, inputType, format, mask, match, additionalRules } = this.props;

      const { max, min, errorMessages } = dynamicValidation(match, {
        max: this.props.max,
        min: this.props.min,
        errorMessages: this.props.errorMessages
      });

      const schema = inputSchema(inputType, { max, min, errorMessages, format, mask, additionalRules });

      setSchema(schema);
    }
  }),
  omitProps(CONSTRAINTS, mapProps),
  withAnchor,
  withContainer,
  withError,
  withCompanion,
  branch(({ textarea }) => textarea, renderComponent(Textarea))
)(Input);
