import React from 'react';
import { compose } from 'recompose';
import { toMoneyStringWithComma } from '../../../helpers/formatters/money-format';
import { timeframeToString } from '../../../helpers/time-format';
import { Wheel } from '../../../components/inputs/wheel';
import { withButton, withContainer } from './components/input-field';

const Input = compose(withContainer, withButton)(Wheel);

export const QuestionWheelInput = React.memo(
  ({ label, format, value, max, min, submit, buttonLabel, userAttributeField, onWheelChange, prefix, iterator }) => {
    const formats = {
      currency: toMoneyStringWithComma,
      time: timeframeToString
    };

    return (
      <Input
        label={label}
        buttonLabel={buttonLabel}
        name={userAttributeField}
        value={value}
        format={{
          value: formats[format],
          computed: format
        }}
        prefix={prefix}
        increment={iterator}
        min={min}
        max={max}
        onChange={onWheelChange}
        onSubmit={submit}
      />
    );
  }
);
