// eslint-disable-next-line import/no-webpack-loader-syntax
import workerBase from 'workerize-loader!../workers/evaluate-condition';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { StringShim } from '@packages/helpers/core/shims/string-shim';
import { interpolateRegex } from '../regex';

const worker = workerBase();

// You must wrap strings in admin console into ``
// `${goal.propertyPrise}` >= 1500000
// `${goal.location}` !== `London`
export const evaluateCondition = async (condition, userAttributes) => {
  if (!StringShim.isString(condition)) {
    return Boolean(condition);
  }

  try {
    const expression = condition.replace(interpolateRegex, (_, name) => {
      return ObjectShim.getNested(userAttributes, name);
    });

    return await worker.evaluate(expression);
  } catch {
    return false;
  }
};
