import { parseUrl as parseUrlWithQuery, stringifyUrl } from 'query-string';
import { fromMode } from '../../navigation/routing';
import { openLink } from '../../utils';

const constructQueryParams = href => stringifyUrl({ url: '', query: parseUrlWithQuery(href).query });

export const changeLinks =
  history =>
  (element, children = element.querySelectorAll('a')) => {
    const onClick =
      ({ target, href, pathname }) =>
      event => {
        event.preventDefault();

        const isExternal = target === '_blank';

        openLink(
          isExternal
            ? href
            : {
                ...fromMode(pathname),
                ...(href.includes('?') && { search: constructQueryParams(href) })
              },
          history,
          isExternal
        );
      };

    children.forEach(child => {
      child.addEventListener('click', onClick(child));
    });

    return element;
  };
