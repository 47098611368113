import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import { compose } from 'redux';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { pluralize } from './formatters';

dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

export const getNow = () => dayjs();

export const getMonthFromTimeframe = months => dayjs().add(months, 'M').format('MMM YYYY');

const GET_DATE_FROM_STRING_DEFAULT_OPTIONS = ObjectShim.immutable(
  {
    input: 'DD/MM/YYYY'
  },
  true
);

export const getDateFromString = (dateString, options) => {
  const { input, output } = Object.assign({}, GET_DATE_FROM_STRING_DEFAULT_OPTIONS, options);

  const moment = dayjs(dateString, input);

  return output ? moment.format(output) : moment;
};

const getTimeframe = timeframe => ({
  fullYears: Math.trunc(timeframe / 12),
  fullMonths: timeframe % 12
});

const convertToDateStringWithPluralize = ({ fullYears, fullMonths }) =>
  `${fullYears ? pluralize(fullYears, 'year') : ''} ${fullMonths ? pluralize(fullMonths, 'month') : ''}`.trim();
const convertToDateShorthand = ({ fullYears, fullMonths }) =>
  `${fullYears ? fullYears + 'y' : ''} ${fullMonths ? fullMonths + 'm' : ''}`.trim();

export const timeframeToString = timeframe => compose(convertToDateStringWithPluralize, getTimeframe)(timeframe);
export const timeframeToStringShorthand = timeframe => compose(convertToDateShorthand, getTimeframe)(timeframe);
export const timeframeToExpandedString = months => `${timeframeToString(months)} (${getMonthFromTimeframe(months)})`;

const SPLIT_BY_PERIODS_DEFAULT_OPTIONS = {
  parts: 4,
  unit: 'M',
  start: 'Today',
  round: Math.round,
  format: timeframeToStringShorthand
};

export const splitDurationIntoPeriods = (timeframe, options) => {
  const { parts, unit, start, format, round } = Object.assign(SPLIT_BY_PERIODS_DEFAULT_OPTIONS, options);

  let duration = dayjs.duration(timeframe, unit);

  const delta = timeframe / parts;
  const periods = [];

  const get = unit => duration.as(unit);
  const put = element => periods.unshift(element);

  const pipe = [put, format, round, get].filter(Boolean);

  do {
    compose(...pipe)(unit);
    duration = duration.subtract(delta, unit);
  } while (get(unit) > 0);

  return [start, ...periods];
};
