import React from 'react';
import { Body, Heading, SubHeading, Icon } from '../../layout/page-header';

export const HeadingVMMortgage = React.memo(({ heading, subheading }) => {
  const {
    PAGE_HEADER: { ICONS }
  } = window.CONFIG;

  return (
    <>
      <Icon name={ICONS.VM_WIR} />
      <Body>
        <Heading>{heading}</Heading>
        <SubHeading>{subheading}</SubHeading>
      </Body>
    </>
  );
});
