import { store } from '../../../store';

export const changePoints = element => {
  const {
    DISPLAY_MODULES: { POINTS }
  } = window.CONFIG;

  const elements = element.getElementsByClassName('promotions');
  const consents = store.getState().userAttributes.consents;
  const promotions = consents && consents.promotions;

  if (!POINTS || !promotions) {
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  return element;
};
