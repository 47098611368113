import { users } from '../api/users';
import { parseJwt } from '../helpers/parse-jwt';
import { UserService } from '../services/user-servcie';

export const createReducer =
  (initialState = null, actions = {}) =>
  (state = initialState, action) => {
    if (actions.hasOwnProperty(action.type)) {
      return actions[action.type](state, action);
    }

    return state;
  };

export const createAction = (types, method) => {
  return params =>
    async (dispatch, getState, { api }) => {
      const { request, success, failure } = types;

      if (request) {
        dispatch({ type: request, params });
      }

      const response = await method(api)(params);

      // For now we don't have to refresh session, token doesn't have deprecation date
      if (response.status === 401 && localStorage.getItem('token')) {
        const refreshToken = localStorage.getItem('refreshToken');
        const email = getState().account.email ? getState().account.email : parseJwt(localStorage.getItem('token'));

        const session = await users.refreshSession({ refreshToken, email });
        if (session.ok) {
          const result = await session.json();
          localStorage.setItem('token', result.session.jwtToken);
          localStorage.setItem('accessToken', result.session.accessToken);
          localStorage.setItem('refreshToken', result.session.refreshToken);
          await createAction(types, method)(params)(dispatch, getState, { api });
        } else {
          UserService.logout();
          window.location.reload();
          return;
        }
      }

      if (response.ok) {
        const payload = await response.json();

        dispatch({ type: success, payload, params });

        return payload;
      } else {
        let errorMsg, errorCode;

        try {
          const result = await response.json();

          errorMsg = result.message;
          errorCode = response.status;
        } catch ({ message }) {
          errorMsg = message;
          errorCode = null;
        }

        dispatch({ type: failure, errorMsg, params, errorCode });

        return { errorMsg, errorCode };
      }
    };
};
