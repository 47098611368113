import React from 'react';
import classnames from 'classnames';
import { compose } from 'recompose';
import styles from '../../styles/components/textarea.module.scss';
import { withAnchor, withContainer, withError, withExplainer, withLabel } from '../inputs/input-field';

export const Textarea = React.memo(({ rows = 4, onChange, className, ...props }) => {
  const handleOnChange = e => {
    let value = e.target.value;

    onChange(value);
  };

  return <textarea rows={rows} className={classnames(styles.field, className)} onChange={handleOnChange} {...props} />;
});
export const withTextareaLabel = withLabel({ className: styles.label, layout: styles.container });
export const InputTextarea = compose(withAnchor, withContainer, withExplainer, withError, withTextareaLabel)(Textarea);
