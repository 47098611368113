import React from 'react';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { NavLink } from '../../link';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { setNavKnowledgeLink } from '../../../store/reducers/navigation';
import styles from '../../../styles/components/knowledge-base-item.module.scss';

const KnowledgeBaseItemComponent = React.memo(({ item: { title, slug }, handleClick }) => (
  <li className={styles.container}>
    <NavLink className={styles.button} activeClassName={styles.selected} to={`/articles/${slug}`} onClick={handleClick}>
      <p className={styles.label}>{title}</p>
      <AffiliateIcon name='checklist-action' className={styles.action} />
    </NavLink>
  </li>
));

export const KnowledgeBaseItem = compose(
  connect(null, { setNavKnowledgeLink }),
  withHandlers({
    handleClick:
      ({ item, setNavKnowledgeLink }) =>
      () => {
        setNavKnowledgeLink(item);
      }
  })
)(KnowledgeBaseItemComponent);
