import { useHistory } from 'react-router';
import { createCustomPageProps, getRoutesByPathname } from '../../../../helpers/utils';
import * as NAVIGATION from '../../../../helpers/navigation';
import { getLocationFromModeState } from '../../../../helpers/navigation/routing';
import { HeaderIcon } from '../component';

export const CloseButton = () => {
  const history = useHistory();

  const {
    location: { pathname, state }
  } = history;

  const handleCloseButton = async () => {
    const toOrigin = getLocationFromModeState(state);

    if (toOrigin) {
      return history.push(toOrigin);
    }

    const [gistModule] = getRoutesByPathname(pathname);

    switch (gistModule) {
      case NAVIGATION.ACCOUNT:
      case NAVIGATION.TOUR:
        return history.push(`/${NAVIGATION.DASHBOARD}`);
      default:
        const { name } = createCustomPageProps(pathname);

        return history.push(`/${gistModule}/${name}/${NAVIGATION.START}`);
    }
  };

  return <HeaderIcon name='nav-exit' onClick={handleCloseButton} />;
};
