import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

const maskDefault = '+99 99999 999999 999999';
const maskCharDefault = ' ';

export const QuestionPhoneNumberInput = React.memo(
  ({
    format,
    label,
    onChange,
    value,
    max,
    min,
    submit,
    errorMessages,
    mask = maskDefault,
    maskChar = maskCharDefault
  }) => (
    <>
      <Input
        type='tel'
        inputType='phoneNumber'
        label={label}
        onChange={onChange}
        format={format}
        value={value}
        onSubmit={submit}
        max={max}
        min={min}
        errorMessages={errorMessages}
        mask={mask}
        maskChar={maskChar}
      />
    </>
  )
);
