import React from 'react';
import { configuration } from '../../../api/configuration';
import { withAutoCompleteHandlers } from './components/autocomplete/handlers';
import { AutocompleteInput } from './components/autocomplete';

const Input = withAutoCompleteHandlers({
  valueName: 'name',
  // eslint-disable-next-line no-template-curly-in-string
  userAttributeName: 'pension.items.${identifier}.pensionProvider',
  method: configuration.getPensionProvider
})(AutocompleteInput);

export const QuestionPensionProviderInput = React.memo(({ label, value, onChange, submit }) => (
  <Input label={label} value={value} onChange={onChange} onSubmit={submit} />
));
