import React from 'react';
import { compose, branch, renderComponent } from 'recompose';
import { createCustomPageProps } from '../../helpers/utils';
import { Question as QuestionLayout } from '../../components/templates/question-template';
import { Loader } from '../../components/with-loader';
import { withMetaTags } from '../../components/meta-tags';
import { withQuestion } from './with-question';
import { Input } from './input';

const QuestionTemplate = React.memo(
  ({ question, questionMessage, location: { pathname }, match: { params }, children }) => (
    <QuestionLayout atid={createCustomPageProps(pathname, '/').full} innerHtml={questionMessage} slotName={pathname}>
      {children
        ? children(question)
        : question.questionInputs
            .sort((a, b) => a.order - b.order)
            .map(({ id, type, data }) => <Input identifier={params.identifier} key={id} type={type} data={data} />)}
    </QuestionLayout>
  )
);

const SEO_PAGE_DATA_NAME = 'default';

export const Question = compose(
  withMetaTags(SEO_PAGE_DATA_NAME),
  withQuestion,
  branch(({ question }) => question, renderComponent(QuestionTemplate), renderComponent(Loader))
)(QuestionTemplate);
