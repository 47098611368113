import React from 'react';
import classnames from 'classnames';
import styles from '../../../styles/components/application-header.module.scss';
import { ConfigService } from '../../../services/config-service';
import { DEVICE } from '../../../helpers/user-agent';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { Image } from '../../images/image';
import { Link } from '../../link';
import { useSubscribe } from '../../slots';
import { Sticky } from '../sticky';
import { useLayoutSection } from '../with-layout';
import { LAYOUT_SECTIONS } from '../../../helpers/layout/sections';
import { NOT_FOUND } from '../../../helpers/navigation';
import { TopSectionNavigationBar } from './components/top-navigation';
import { BackButton } from './components/back-button';

export const HeaderIcon = React.memo(({ name, to, onClick }) => (
  <AffiliateIcon to={to} name={name} className={styles.icon} onClick={onClick} />
));

export const Component = React.memo(
  ({
    className,
    editButton,
    helpButton,
    shareButton,
    withNavigation,
    withNavigationOffset,
    handleEditButton,
    handleHelpButton,
    handleShareButton,
    template
  }) => {
    const { logoUrl, logoLink } = ConfigService.get('NAVIGATION_BAR', {});

    const ref = useLayoutSection(LAYOUT_SECTIONS.TOP_NAVIGATION, 'clientHeight');

    const leftSlot = useSubscribe('top-navigation-left');
    const rightSlot = useSubscribe('top-navigation-right');

    return (
      <Sticky ref={ref} renderAs='header' className={className} nested={false}>
        <div className={styles.container}>
          <TopSectionNavigationBar className={styles.navigation} />
          <div className={classnames(styles.body, { [styles.offset]: withNavigationOffset })}>
            <div className={classnames(styles.icons, styles.left)}>
              <BackButton />
              <div className={styles.slot} ref={leftSlot} />
            </div>
            <div className={styles.logo}>
              <Link className={styles.link} to={logoLink}>
                <Image src={logoUrl} alt='logo' fluid />
              </Link>
            </div>
            <div className={classnames(styles.icons, styles.right)}>
              <div className={styles.slot} ref={rightSlot} />
              {editButton?.display && <HeaderIcon name='nav-restart' onClick={handleEditButton} />}
              {helpButton?.display && <HeaderIcon name='nav-help' onClick={handleHelpButton} />}
              {shareButton && template !== NOT_FOUND && (
                <HeaderIcon name={`share-${DEVICE.IOS() ? 'ios' : 'generic'}`} onClick={handleShareButton} />
              )}
            </div>
          </div>
        </div>
      </Sticky>
    );
  }
);
