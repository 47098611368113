export const calculateLegalFees = propertyPrice => {
  try {
    const { LEGAL_FEES: legalFees } = window.CONFIG.DEFAULT_VALUES;

    const result = legalFees.find(item => {
      return propertyPrice < item.value;
    });

    return result?.max ?? legalFees[legalFees.length - 1].max;
  } catch {
    throw new Error('Calculate legal fees failed. Probably config value is missing');
  }
};
