import { Renting } from '../pages/rent-reporting/renting';
import { RegularPayments } from '../pages/rent-reporting/regular-payments';
import { PaymentMethod } from '../pages/rent-reporting/payment-method';
import { asTemplate } from '../components/layout/with-layout';
import { QUESTION } from '../helpers/navigation';

export const rentReportingRoutes = [
  {
    to: '/checklist/rent-reporting/renting',
    component: asTemplate(QUESTION)(Renting)
  },
  {
    to: '/checklist/rent-reporting/regular-payments',
    component: asTemplate(QUESTION)(RegularPayments)
  },
  {
    to: '/checklist/rent-reporting/payment-method',
    component: asTemplate(QUESTION)(PaymentMethod)
  }
];
