import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { getTwoDimensionalArrayIterator } from '../array';

export const getKnowledgeBaseFirstItem = (topics, defaultValue) => {
  return ObjectShim.getNested(topics, '0.posts.0', defaultValue);
};

export const getKnowledgeBaseItemBy = (topics, callback, defaultValue) => {
  const posts = getTwoDimensionalArrayIterator(topics, 'posts');

  for (const post of posts) {
    if (callback(post)) {
      return post;
    }
  }

  return getKnowledgeBaseFirstItem(topics, defaultValue);
};
