import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

export const QuestionTextareaInput = React.memo(
  ({ label, onChange, userAttributeField, value, max, min, submit, errorMessages }) => (
    <Input
      textarea
      inputType='text'
      label={label}
      name={userAttributeField}
      value={value}
      onChange={onChange}
      onSubmit={submit}
      max={max}
      min={min}
      errorMessages={errorMessages}
    />
  )
);
