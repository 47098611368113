import Loadable from 'react-loadable';
import { asTemplate } from '../components/layout/with-layout';
import { Loader } from '../components/with-loader';
import { ARTICLE, ARTICLES } from '../helpers/navigation';

export const Article = Loadable({
  loader: () =>
    import(/* webpackChunkName: "article"*/ '../components/templates/article').then(module => module.Article),
  loading: Loader
});
const KnowledgeBaseList = Loadable({
  loader: () =>
    import(/* webpackChunkName: "articles"*/ '../pages/knowledge-base').then(module => module.KnowledgeBaseList),
  loading: Loader
});

export const articlesRoutes = [
  {
    to: '/articles/:slug',
    component: asTemplate(ARTICLE)(Article)
  },
  {
    to: '/articles',
    component: asTemplate(ARTICLES)(KnowledgeBaseList),
    hideOn: 'lgAndUp'
  }
];
