import { compose } from 'redux';
import { FunctionShim } from '@packages/helpers/core/shims/function-shim';
import { ConfigService } from '../../services/config-service';
import { withQueryParams, editMode } from '../navigation/routing';
import { getRoutesByPathname } from '../utils';
import { formatValueToPercent } from '../formatters';
import { toMoneyStringWithK, toMoneyStringWithComma } from '../formatters/money-format';
import { prettifyName } from '../../pages/question/helpers/prettify-name';
import { PROPERTY } from '../navigation';

const PROPERTY_ID = 'manual-property';
const PROPERTY_CURRENT_MORTGAGE_ID = 'mortgage';

const MORTGAGE_TO_PROPERTY_SEPARATOR = '_';
const INCREMENT_SEPARATOR = '-';

export const createPropertyId = properties => prettifyName(PROPERTY_ID, properties, INCREMENT_SEPARATOR);
export const createMortgageId = (propertyId, mortgages) =>
  prettifyName(
    `${propertyId}${MORTGAGE_TO_PROPERTY_SEPARATOR}${PROPERTY_CURRENT_MORTGAGE_ID}`,
    mortgages,
    INCREMENT_SEPARATOR
  );

export const parsePropertyId = mortgageId => mortgageId.split(MORTGAGE_TO_PROPERTY_SEPARATOR)[0];

const PROPERTY_ID_PATHNAME_INDEX = 3;

export const parsePropertyIdFromPathname = (pathname = '') => {
  return getRoutesByPathname(pathname)[PROPERTY_ID_PATHNAME_INDEX];
};

export const getPropertyRoute = (propertyId, isEditing) => {
  let pathname = ConfigService.get('PROPERTY_PORTFOLIO.ENTRY_POINTS.PROPERTY_FLOW');

  if (!pathname) {
    return '';
  }

  if (!propertyId) {
    return pathname;
  }

  pathname = `${pathname}/${propertyId}`;

  if (isEditing) {
    return editMode(pathname);
  }

  return pathname;
};

export const getCurrentMortgageRoute = ({ propertyId, mortgageId, isEditing }) => {
  let pathname = ConfigService.get('PROPERTY_PORTFOLIO.ENTRY_POINTS.MORTGAGE_FLOW');

  if (!pathname) {
    return '';
  }

  if (propertyId) {
    return withQueryParams(pathname, { propertyId });
  }

  if (mortgageId) {
    return isEditing ? editMode(`${pathname}/${mortgageId}`) : `${pathname}/${mortgageId}`;
  }

  return pathname;
};

export const getPropertyDetailsRoute = propertyId => {
  if (!propertyId) {
    return '';
  }

  return `/${PROPERTY}/${propertyId}`;
};

const extendedValueToPercent = value => formatValueToPercent(value, 2);

const FORMATS_MAP = {
  percent: extendedValueToPercent,
  money: toMoneyStringWithComma,
  'money-short': toMoneyStringWithK
};

export const calculateTotalValuation = (properties = []) => {
  return properties.reduce((valuation, { price = 0 }) => (valuation += price), 0);
};

export const mapByDictionaryRule =
  entity =>
  ({ key, title, format, extra }) => {
    const value = entity[key];
    const formatter = FORMATS_MAP[format];

    return {
      title,
      value: value ? (formatter ? formatter(value) : value) : null,
      accessor: key,
      extra
    };
  };

export const mapItemsBy = (items, mapper) => keys => {
  return keys.map(id => {
    const item = { id, ...items[id] };

    return FunctionShim.isFunction(mapper) ? mapper(item) : item;
  });
};

export const getProperties = (items, mapper) => {
  return compose(mapItemsBy(items, mapper), Object.getOwnPropertyNames)(items);
};

export const getMortgagesByPropertyId = id => items =>
  items.filter(key => key.split(MORTGAGE_TO_PROPERTY_SEPARATOR)[0] === id);

export const mapMortgagesByPropertyId = id => (items, mapper) => {
  return compose(mapItemsBy(items, mapper), getMortgagesByPropertyId(id), Object.getOwnPropertyNames)(items);
};

export const getAlternativeMortgages = (items, mapper) => {
  return compose(mapItemsBy(items, mapper), Object.keys)(items);
};
