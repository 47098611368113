import React from 'react';
import styles from '../../../styles/components/knowledge-base-topic.module.scss';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { KnowledgeBaseItem } from './item';

export const KnowledgeBaseTopic = React.memo(({ category, subCategory, slug, topicFilter, posts = [] }) => {
  const {
    KNOWLEDGE_BASE: { topicDefaultIcon, topicIcons }
  } = window.CONFIG;

  const items =
    topicFilter === 'All'
      ? posts
      : posts.filter(({ homebuying_stage }) => homebuying_stage && homebuying_stage.includes(topicFilter));

  return (
    !!items.length && (
      <>
        <div className={styles.container}>
          <div className={styles.header}>
            {topicIcons && (
              <AffiliateIcon
                name={`articles-section-${slug}`}
                fallback={{ external: topicDefaultIcon }}
                className={styles.icon}
              />
            )}
            <div className={styles.label}>
              <h2 className={styles.heading}>{category}</h2>
              {subCategory && <p className={styles.subheading}>{subCategory}</p>}
            </div>
          </div>
        </div>
        <ul className={styles.menu}>
          {items.map(post => (
            <KnowledgeBaseItem key={post.id} item={post} />
          ))}
        </ul>
      </>
    )
  );
});
