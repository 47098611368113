import React from 'react';
import classnames from 'classnames';
import { compose, withState, withHandlers, defaultProps } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import styles from '../../styles/components/segmented-button.module.scss';
import { withContainer } from '../inputs/input-field';

const Segment = React.memo(({ active, handleClick, item: { label, value } }) => (
  <button className={classnames(styles.segment, { [styles.active]: value === active })} onClick={handleClick(value)}>
    <span className={styles.label}>{label}</span>
  </button>
));

const SegmentedButtonComponent = React.memo(({ items, className, atid, ...props }) => (
  <div className={classnames(styles.container, className)} data-atid={atid}>
    {items.map(item => (
      <Segment key={item.label} item={item} {...props} />
    ))}
  </div>
));

export const SegmentedButton = compose(
  defaultProps({
    items: []
  }),
  withState('active', 'setActive', ({ items, initialValue }) => {
    return isNil(initialValue) ? items[0] && items[0].value : initialValue;
  }),
  withHandlers({
    handleClick:
      ({ setActive, onChange }) =>
      value =>
      () => {
        setActive(value);
        onChange && onChange(value);
      }
  })
)(SegmentedButtonComponent);

export const InputSegmentedButton = withContainer(SegmentedButton);
