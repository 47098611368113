import React, { useMemo } from 'react';
import uuid from 'uuid';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { compose, withHandlers } from 'recompose';
import styles from '../styles/components/collapse.module.scss';
import { omitProps } from '../helpers/hocs/omit-props';
import { withMemoAndRef } from '../helpers/hocs/with-memo-and-ref';

const CONSTRAINTS = ['onExpanded', 'onCollapsed'];

const CollapseTriggerComponent = ({ expanded, className, children, ...props }, ref) => (
  <button ref={ref} aria-expanded={expanded} className={classnames(styles.trigger, className)} {...props}>
    {children}
  </button>
);

export const CollapseTrigger = withMemoAndRef(CollapseTriggerComponent);

const CollapsePanelComponent = React.memo(
  ({
    renderAs: Component = 'div',
    children,
    expanded,
    onRef,
    onEnter,
    onEntering,
    onEntered,
    onExiting,
    onExited,
    onExit,
    ...props
  }) => (
    <CSSTransition
      in={expanded}
      classNames={{ ...styles }}
      addEndListener={(node, done) => {
        node.addEventListener('transitionend', done, false);
      }}
      onEnter={onEnter}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      onExited={onExited}
    >
      <Component ref={onRef} role='region' className={styles.container} {...props}>
        {children}
      </Component>
    </CSSTransition>
  )
);

export const CollapsePanel = compose(
  withHandlers(() => {
    let maxHeight = 'auto';

    return {
      onRef:
        ({ expanded }) =>
        node => {
          if (node) {
            node.hidden = !expanded;
            node.style.maxHeight = expanded ? maxHeight : 0;
          }
        },
      onEnter: () => node => {
        node.hidden = false;
      },
      onEntering: () => node => {
        maxHeight = node.scrollHeight + 'px';

        node.style.maxHeight = maxHeight;
      },
      onEntered:
        ({ onExpanded }) =>
        node => {
          onExpanded && onExpanded(node);
        },
      onExit: () => node => {
        maxHeight = node.scrollHeight + 'px';

        node.style.maxHeight = maxHeight;
      },
      onExiting: () => node => {
        node.style.maxHeight = 0;
      },
      onExited:
        ({ onCollapsed }) =>
        node => {
          node.hidden = true;
          onCollapsed && onCollapsed(node);
        }
    };
  }),
  omitProps(CONSTRAINTS)
)(CollapsePanelComponent);

const CollapseComponent = ({ renderAs: Component = 'div', children, expanded, ...props }, ref) => {
  const triggerId = useMemo(() => uuid('collapse-trigger'), []);
  const panelId = useMemo(() => uuid('collapse-panel'), []);

  return (
    <Component ref={ref} {...props}>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          if (child.type === CollapseTrigger) {
            return React.cloneElement(child, {
              id: triggerId,
              'aria-controls': panelId,
              expanded
            });
          }

          if (child.type === CollapsePanel) {
            return React.cloneElement(child, {
              id: panelId,
              'aria-labelledby': triggerId,
              expanded
            });
          }
        }

        return child;
      })}
    </Component>
  );
};

export const Collapse = withMemoAndRef(CollapseComponent);
