import { Helmet } from 'react-helmet';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { NOT_FOUND } from '../../helpers/navigation';
import { Question } from '../templates/question-template';
import { InputButtonWithIcon as ButtonWithIcon } from '../buttons/button-with-icon';
import { asTemplate } from './with-layout';

const Component = ({ history, errorPage = false }) => {
  const {
    NAVIGATION_BAR: { logoLink },
    NOT_FOUND_PAGE,
    ERROR_PAGE
  } = window.CONFIG;

  const page = errorPage ? ERROR_PAGE : NOT_FOUND_PAGE;

  const { backButton, homeButton, text } = page;

  const goBack = () => {
    history.goBack();
  };

  const goHome = () => {
    history.push(logoLink);
  };

  return (
    <>
      <Helmet title='Buying stage' />
      <Question message={text ? <p>{text}</p> : null} hideCloseButton>
        {homeButton && homeButton.labelText && <ButtonWithIcon onClick={goHome}>{homeButton.labelText}</ButtonWithIcon>}
        {backButton && backButton.labelText && <ButtonWithIcon onClick={goBack}>{backButton.labelText}</ButtonWithIcon>}
      </Question>
    </>
  );
};

export const NotFoundPage = asTemplate(NOT_FOUND)(Component);

const RedirectToNotFound = () => <Redirect to='/not-found' />;

export const withNotFound = test => Component => {
  return props => {
    if (test(props)) {
      return <RedirectToNotFound />;
    }

    return <Component {...props} />;
  };
};
