import React, { useEffect } from 'react';
import classnames from 'classnames';
import { compose, defaultProps, withPropsOnChange } from 'recompose';
import { NumberShim } from '@packages/helpers/core/shims/number-shim';
import styles from '../../styles/components/pie-chart.module.scss';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';

const SIZE = 100;
const RADIUS = SIZE / 2;
const CENTER = SIZE / 2;

export const PieChartContainer = withMemoAndRef(({ children, className, style }, ref) => (
  <div ref={ref} className={classnames(styles.container, className)} style={style}>
    {children}
  </div>
));

const RingComponent = withMemoAndRef(
  ({ value, strokeWidth, radius, circumference, completed, className, style, onChange }, ref) => {
    useEffect(() => {
      onChange && onChange(completed);
    }, [completed, onChange]);

    return (
      <svg ref={ref} className={classnames(styles.ring, className)} style={style} viewBox={`0 0 ${SIZE} ${SIZE}`}>
        <g>
          <circle
            className={styles.circle}
            r={radius}
            cx={CENTER}
            cy={CENTER}
            style={{
              strokeWidth,
              strokeDasharray: `${circumference} ${circumference}`
            }}
          />
          <circle
            className={classnames(styles.circle, styles.progress)}
            r={radius}
            cx={CENTER}
            cy={CENTER}
            style={{
              strokeWidth,
              strokeDasharray: `${value} ${circumference}`,
              strokeOpacity: Number(!!value)
            }}
          />
        </g>
      </svg>
    );
  }
);

export const PieChartRing = compose(
  withPropsOnChange(['strokeWidth'], ({ strokeWidth }) => {
    const radius = RADIUS - NumberShim.inRange(strokeWidth, { min: 1, max: RADIUS }) / 2;
    const circumference = 2 * Math.PI * radius;

    return {
      radius,
      circumference
    };
  }),
  withPropsOnChange(['value', 'circumference'], ({ value, circumference }) => {
    let progress;

    progress = NumberShim.inRange(value, { min: 0, max: 100 });
    progress = NumberShim.percentIs(progress, circumference, false);

    return { value: progress, completed: progress === circumference };
  })
)(RingComponent);

const ChildrenComponent = withMemoAndRef(({ className, children, style, padding }, ref) => (
  <div ref={ref} className={classnames(styles.content, className)} style={{ ...style, padding }}>
    <div className={styles.children}>{children}</div>
  </div>
));

export const PieChartChildren = compose(
  withPropsOnChange(['strokeWidth'], ({ strokeWidth }) => {
    const padding = `0 ${strokeWidth}%`;

    return {
      padding: { padding }
    };
  })
)(ChildrenComponent);

const Component = ({ children, className, ...rest }) => (
  <PieChartContainer className={className}>
    <PieChartRing {...rest} />
    <PieChartChildren {...rest}>{children}</PieChartChildren>
  </PieChartContainer>
);

export const PieChart = defaultProps({
  strokeWidth: 6,
  value: 0
})(Component);
