import React from 'react';
import { compose, branch } from 'recompose';
import styles from '../../../../styles/components/question-input-field.module.scss';
import { ButtonWithIcon } from '../../../../components/buttons/button-with-icon';

export const withContainer = Component => props =>
  (
    <div className={styles.container}>
      <div className={styles.controller}>
        <Component {...props} />
      </div>
    </div>
  );
export const withCompanionButton = Component =>
  React.memo(({ buttonLabel, buttonCompanion, disabled, onSubmit, ...props }) => {
    const {
      COACH: {
        answer: { nextButton }
      }
    } = window.CONFIG;

    const label = buttonLabel ?? nextButton.labelText;

    return (
      <div className={styles.wrapper}>
        <div className={styles.input}>
          <Component {...props} />
        </div>
        <ButtonWithIcon buttonType='submit' aria-label={label} disabled={disabled} companion onClick={onSubmit} />
      </div>
    );
  });
export const withButton = Component =>
  React.memo(({ buttonLabel, buttonCompanion, disabled, onSubmit, ...props }) => {
    const {
      COACH: {
        answer: { nextButton }
      }
    } = window.CONFIG;

    const label = buttonLabel ?? nextButton.labelText;

    return (
      <>
        <Component {...props} />
        <div className={styles.button}>
          <ButtonWithIcon buttonType='submit' aria-label={label} disabled={disabled} onClick={onSubmit}>
            {label}
          </ButtonWithIcon>
        </div>
      </>
    );
  });

export const withCompanion = compose(
  branch(() => window.CONFIG.COACH.answer.nextButton.companion, withCompanionButton, withButton)
);
