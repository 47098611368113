import { useCallback, useLayoutEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose, branch, lifecycle, renderNothing } from 'recompose';
import { padExclusionMark, splitIsVisibleOptionsEntry, trimExclusionMark } from '../../helpers/layout/sections';
import { ConfigService } from '../../services/config-service';
import { setLayoutSection, setLayoutTemplate } from '../../store/reducers/layout';
import { withResize } from '../with-resize';

export const useLayoutSection = (name, metric) => {
  const ref = useRef();

  const dispatch = useDispatch();

  const setSection = useCallback(
    payload => {
      dispatch(setLayoutSection(payload));
    },
    [dispatch]
  );

  useLayoutEffect(() => {
    const node = ref.current;

    if (node) {
      setSection({ name, visibility: true, ...(metric ? { size: node[metric] } : {}) });

      return () => {
        setSection({ name, visibility: false, ...(metric ? { size: 0 } : {}) });
      };
    }
  }, [name, metric, setSection]);

  return ref;
};

const mapStateToProps = ({ layout }) => ({ template: layout.template });

export const isVisible = options =>
  compose(
    connect(mapStateToProps),
    withResize,
    branch(({ viewport, template }) => {
      const isVisible = [].concat(options).some(({ onBreakpoints = [], forTemplates = [] }) => {
        let isVisible = ConfigService.get('NAVIGATION_BAR.isVisible', true);

        if (isVisible && forTemplates.length) {
          const { inclusive, exclusive } = splitIsVisibleOptionsEntry(forTemplates);

          if (inclusive.length) {
            isVisible = inclusive.some(name => template === name);
          } else if (exclusive.length) {
            isVisible = exclusive.every(name => template !== trimExclusionMark(name));
          }
        }

        if (isVisible && onBreakpoints.length) {
          const { inclusive, exclusive } = splitIsVisibleOptionsEntry(onBreakpoints);

          if (inclusive.length) {
            isVisible = inclusive.some(name => viewport[name]);
          } else if (exclusive.length) {
            isVisible = exclusive.every(name => !viewport[trimExclusionMark(name)]);
          }
        }

        return isVisible;
      });

      return !isVisible;
    }, renderNothing)
  );

export const asTemplate = name =>
  compose(
    connect(null, { setLayoutTemplate }),
    lifecycle({
      componentDidMount() {
        const { setLayoutTemplate } = this.props;

        setLayoutTemplate({ name });
      }
    })
  );

export const not = padExclusionMark;
