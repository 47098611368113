import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { shareRoutesCheck } from '../../../helpers/regex';
import { CHECKPOINT } from '../../../helpers/navigation';
import { toggleModal } from '../../../store/reducers/modals';
import { NativeAction } from '../../../services/native-action';
import { ConfigService } from '../../../services/config-service';
import { isVisible, not } from '../with-layout';
import { Component } from './component';

const mapStateToProps = (state, { location }) => {
  const {
    sections: { sidebar, topSectionNavigation }
  } = state.layout;

  const {
    headerButtons: { editButton, helpButton }
  } = state.navigation;

  const SHARING = ConfigService.get('SHARING');

  return {
    withNavigationOffset: topSectionNavigation && !sidebar,
    editButton: editButton[location.pathname],
    helpButton: helpButton[location.pathname],
    shareButton: SHARING?.show && shareRoutesCheck.test(location.pathname)
  };
};

export const ApplicationHeader = compose(
  isVisible({
    forTemplates: [not(CHECKPOINT)]
  }),
  withRouter,
  connect(mapStateToProps, { toggleModal }),
  withHandlers({
    handleEditButton:
      ({ editButton, history }) =>
      () => {
        editButton?.callback ? editButton.callback() : history.push(editButton.url);
      },
    handleHelpButton:
      ({ helpButton, history }) =>
      () => {
        helpButton?.callback ? helpButton.callback() : history.push(helpButton.url);
      },
    handleShareButton:
      ({ toggleModal }) =>
      () => {
        const SHARING = ConfigService.get('SHARING');

        if (NativeAction.isInitialized) {
          NativeAction.trigger('share', { url: window.location.href, title: SHARING?.message });
        } else {
          toggleModal(true, 'sharing');
        }
      }
  })
)(Component);
