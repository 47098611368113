/* eslint-disable no-unused-expressions */
export const accountChunks = () => {
  import(/* webpackChunkName: "sign-up-email"*/ '../pages/account/sign-up/email');
  import(/* webpackChunkName: "sign-up-password"*/ '../pages/account/sign-up/email');
  import(/* webpackChunkName: "sign-up-magic-code"*/ '../pages/account/sign-up/magic-code');
  import(/* webpackChunkName: "sign-up-marketing"*/ '../pages/account/sign-up/marketing');
  import(/* webpackChunkName: "sign-up-complete-page"*/ '../pages/account/components/complete-pages');
  import(/* webpackChunkName: "sign-in-email"*/ '../pages/account/sign-in/email');
  import(/* webpackChunkName: "sign-in-password"*/ '../pages/account/sign-in/password');
  import(/* webpackChunkName: "sign-in-complete-page"*/ '../pages/account/components/complete-pages');
  import(/* webpackChunkName: "reset-password-email"*/ '../pages/account/reset-password/email');
  import(/* webpackChunkName: "reset-password-magic-code"*/ '../pages/account/reset-password/magic-code');
  import(/* webpackChunkName: "reset-password-password"*/ '../pages/account/reset-password/password');
  import(/* webpackChunkName: "reset-password-complete-page"*/ '../pages/account/components/complete-pages');
  import(/* webpackChunkName: "account"*/ '../pages/account/main');
  import(/* webpackChunkName: "account-details"*/ '../pages/account/main/details');
  import(/* webpackChunkName: "account-details-complete-page"*/ '../pages/account/components/complete-pages');
  import(/* webpackChunkName: "account-marketing"*/ '../pages/account/main/marketing');
  import(/* webpackChunkName: "account-cookies"*/ '../pages/account/main/cookies');
  import(/* webpackChunkName: "account-delete"*/ '../pages/account/main/delete-account');
  import(/* webpackChunkName: "account-request-data"*/ '../pages/account/main/request-data');
  import(/* webpackChunkName: "logout-complete-page"*/ '../pages/account/components/complete-pages');
  import(/* webpackChunkName: "barrier"*/ '../pages/account/main/delete-account');
};
