import { normalizePartners } from '../../helpers/marketplace';
import { createAction, createReducer } from '../utils';

const GET_MARKETPLACE_PARTNERS_REQUEST = 'GET_MARKETPLACE_PARTNERS_REQUEST';
const GET_MARKETPLACE_PARTNERS_SUCCESS = 'GET_MARKETPLACE_PARTNERS_SUCCESS';
const GET_MARKETPLACE_PARTNERS_FAILURE = 'GET_MARKETPLACE_PARTNERS_FAILURE';

const GET_PARTNERS_ELIGIBILITY_BY_KEY_REQUEST = 'GET_PARTNERS_ELIGIBILITY_REQUEST';
const GET_PARTNERS_ELIGIBILITY_BY_KEY_SUCCESS = 'GET_PARTNERS_ELIGIBILITY_SUCCESS';
const GET_PARTNERS_ELIGIBILITY_BY_KEY_FAILURE = 'GET_PARTNERS_ELIGIBILITY_FAILURE';

const GET_PARTNERS_ELIGIBILITY_REQUEST = 'GET_PARTNERS_ELIGIBILITY_REQUEST';
const GET_PARTNERS_ELIGIBILITY_SUCCESS = 'GET_PARTNERS_ELIGIBILITY_SUCCESS';
const GET_PARTNERS_ELIGIBILITY_FAILURE = 'GET_PARTNERS_ELIGIBILITY_FAILURE';

const GET_GROUPS_ELIGIBILITY_REQUEST = 'GET_GROUPS_ELIGIBILITY_REQUEST';
const GET_GROUPS_ELIGIBILITY_SUCCESS = 'GET_GROUPS_ELIGIBILITY_SUCCESS';
const GET_GROUPS_ELIGIBILITY_FAILURE = 'GET_GROUPS_ELIGIBILITY_FAILURE';

export const getMarketplacePartners = () =>
  createAction(
    {
      request: GET_MARKETPLACE_PARTNERS_REQUEST,
      success: GET_MARKETPLACE_PARTNERS_SUCCESS,
      failure: GET_MARKETPLACE_PARTNERS_FAILURE
    },
    api => api.configuration.getMarketplacePartners
  )();

export const getPartnersEligibility = activity =>
  createAction(
    {
      request: GET_PARTNERS_ELIGIBILITY_REQUEST,
      success: GET_PARTNERS_ELIGIBILITY_SUCCESS,
      failure: GET_PARTNERS_ELIGIBILITY_FAILURE
    },
    api => api.eligibility.getPartnerEligibility
  )(activity);

export const getPartnersEligibilityByKey = keysList =>
  createAction(
    {
      request: GET_PARTNERS_ELIGIBILITY_BY_KEY_REQUEST,
      success: GET_PARTNERS_ELIGIBILITY_BY_KEY_SUCCESS,
      failure: GET_PARTNERS_ELIGIBILITY_BY_KEY_FAILURE
    },
    api => api.eligibility.getPartnerEligibilityByKey
  )(keysList);

export const getGroupEligibility = groupKey =>
  createAction(
    {
      request: GET_GROUPS_ELIGIBILITY_REQUEST,
      success: GET_GROUPS_ELIGIBILITY_SUCCESS,
      failure: GET_GROUPS_ELIGIBILITY_FAILURE
    },
    api => api.eligibility.getGroupEligibility
  )(groupKey);

const initialState = {
  partners: [],
  normalizedPartners: {},
  partnerStatuses: {},
  noGroupPartnerStatus: {},
  loading: true,
  loadingEligibility: true,
  eligibilityStatus: '',
  error: false
};

export const marketplace = createReducer(initialState, {
  [GET_MARKETPLACE_PARTNERS_REQUEST]: state => ({ ...state, loading: true }),
  [GET_MARKETPLACE_PARTNERS_SUCCESS]: (state, action) => ({
    ...state,
    partners: action.payload,
    normalizedPartners: normalizePartners(action.payload),
    loading: false,
    error: false
  }),
  [GET_MARKETPLACE_PARTNERS_FAILURE]: state => ({ ...state, loading: false, error: true }),
  [GET_PARTNERS_ELIGIBILITY_REQUEST]: state => ({ ...state, loadingEligibility: true }),
  [GET_PARTNERS_ELIGIBILITY_SUCCESS]: (state, action) => ({
    ...state,
    eligibilityStatus: action.payload.status,
    loadingEligibility: false,
    error: false
  }),
  [GET_PARTNERS_ELIGIBILITY_FAILURE]: state => ({ ...state, loadingEligibility: false, error: true }),
  [GET_GROUPS_ELIGIBILITY_SUCCESS]: (state, { payload }) => ({ ...state, partnerStatuses: payload }),
  [GET_PARTNERS_ELIGIBILITY_BY_KEY_SUCCESS]: (state, { payload }) => ({ ...state, noGroupPartnerStatus: payload })
});
