// eslint-disable-next-line no-useless-escape
export const emailCheck =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const numberComma = /\B(?=(\d{3})+(?!\d))/g;

export const shareRoutesCheck = /\/start|blog\/|articles\/|lp\//;
export const formatMoneyRegex = /\B(?=(\d{3})+(?!\d))/g;
export const interpolateRegex = /\$\{([^}]+)\}/g;
export const phoneNumberRegex = /^([+]|[00]{2})([0-9]|[ -])*/;
export const starsRegex = /\*(.*?)\*/g;
