import uuid from 'uuid';
import { createReducer } from '../utils';
import { insertVarIntoString } from '../../helpers/insert-var-into-string';
import { SEND_STATUS_DATA_SUCCESS } from './status-data';
import { DELETE_ACCOUNT_SUCCESS } from './account/action-types';

const HIDE_TOAST = 'HIDE_TOAST';
const ADD_TOAST = 'ADD_TOAST';

export const hideToast = () => ({ type: HIDE_TOAST });
export const addToast = payload => ({ type: ADD_TOAST, payload: { id: uuid(), ...payload } });

const initialState = {
  messages: []
};

export const toast = createReducer(initialState, {
  [SEND_STATUS_DATA_SUCCESS]: (state, { payload: { points, promotions } }) => {
    const {
      DISPLAY_MODULES: { POINTS },
      DISPLAY_COMPONENTS: { TOAST }
    } = window.CONFIG;

    if (!POINTS || !TOAST.POINTS || !promotions) {
      return state;
    }

    return {
      ...state,
      messages: points && points.point > 0 ? [...state.messages, getMessageFromPoints(points)] : state.messages
    };
  },
  [ADD_TOAST]: (state, { payload }) => ({ ...state, messages: [...state.messages, payload] }),
  [HIDE_TOAST]: state => ({
    ...state,
    messages: state.messages.filter((_, index) => index !== 0)
  }),
  [DELETE_ACCOUNT_SUCCESS]: () => initialState
});

const getMessageFromPoints = points => {
  const { messages } = window.CONFIG.TOAST;

  return {
    message: insertVarIntoString(messages.points.text, { points: points.point }),
    id: uuid(),
    to: messages.points.to
  };
};
