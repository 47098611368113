import React from 'react';
import { Column } from '~/components/layout/grid';
import { CloseButton } from '../layout/application-header/components/close-button';
import { Header } from '../layout/page-header';
import { Footer } from '../layout/page-footer';
import { Spacer } from '../layout/spacers';
import { CoachMessage } from '../coach-message';
import { Form } from '../inputs/input-group';
import { Template } from '../template';
import { Slot } from '../slots';

export const Question = React.memo(
  ({ slotName, message, innerHtml, heading, subheading, footer, modal, atid, hideCloseButton = false, children }) => (
    <>
      {!hideCloseButton && (
        <Slot name='top-navigation-right'>
          <CloseButton />
        </Slot>
      )}
      {heading && <Header heading={heading} subheading={subheading} />}
      <Template data-atid={atid}>
        <Spacer type='top' />
        <Column>
          <CoachMessage appendSlot={slotName} html={innerHtml}>
            {message}
          </CoachMessage>
          <Form type='coach' question>
            {children}
          </Form>
        </Column>
        <Spacer type='bottom' />
      </Template>
      {footer && <Footer />}
      {modal}
    </>
  )
);
