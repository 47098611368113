/* eslint-disable no-console */
export class NativeAction {
  static isInitialized = false;

  static initialize() {
    const android = window.NativeTrigger;
    const iOS = window.webkit && window.webkit.messageHandlers.NativeTrigger;
    const cordova = window.webkit && window.webkit.messageHandlers.cordova_iab;

    this.isInitialized = android || iOS || cordova;

    this.method =
      (android && {
        trigger: (name, params) => android.trigger(name, JSON.stringify(params))
      }) ||
      (iOS && {
        trigger: (name, params) => iOS.postMessage({ trigger: name, params: JSON.stringify(params) })
      }) ||
      (cordova && {
        trigger: (name, params) => cordova.postMessage(JSON.stringify({ trigger: name, ...params }))
      });

    if (cordova) {
      document.querySelector('main').addEventListener(
        'click',
        e => {
          const anchor = e.target.closest('a');

          if (anchor?.target === '_blank' && this.method?.trigger) {
            e.preventDefault();
            e.stopPropagation();

            this.method.trigger('external', { url: anchor.href });
          }
        },
        false
      );
    }
  }

  static trigger = (name, params = {}) => {
    if (this.method && this.method.trigger) {
      return this.method.trigger(name, params);
    }
  };
}
/* eslint-enable no-console */
