import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { DISPATCH_AFTER_USER_ATTRIBUTES, sendAdditionalUserAttributes } from '../../reducers/user-attributes/actions';
import { generateMABProps } from '../generators/generate-mab-props';
import { generateFFProps } from '../generators/generate-ff-props';

//here we trigger specific action depends on user attributes prefix, in this case output method is same,
//but we can use same structure for generating props from goals

const sendTriggers = {
  beVM: { method: state => generateFFProps(state), attributeType: 'futureFit' },
  scoreVM: { method: state => generateFFProps(state), attributeType: 'futureFit' },
  positiveScoreVM: { method: state => generateFFProps(state), attributeType: 'futureFit' },
  property: { method: state => generateMABProps(state), attributeType: 'property' },
  mortgage: { method: state => generateMABProps(state), attributeType: 'property' }
};

const triggersToBeSent = ['beVM', 'scoreVM', 'positiveScoreVM', 'property', 'mortgage'];

export const afterUserAttributesSend = ({ action, store }) => {
  const state = store.getState();
  const propType = action.params.type;
  const trigger = sendTriggers[propType];

  if (trigger) {
    const params = trigger.method(state);

    if (!ObjectShim.isEmpty(params)) {
      store.dispatch({ type: DISPATCH_AFTER_USER_ATTRIBUTES, params });

      //here we send calculated data for specific property type
      if (triggersToBeSent.includes(propType)) {
        store.dispatch(sendAdditionalUserAttributes({ [sendTriggers[propType].attributeType]: params }));
      }
    }
  }
};
