import React, { useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, lifecycle, withState } from 'recompose';
import { getConfig } from './api/config';
import { ShadowUserService } from './services/shadow-user-service';
import { UserService } from './services/user-servcie';
import { MarketingInfoService } from './services/marketing-info-serice';
import { getUser } from './store/reducers/account/actions';
import { setNavThemedPages } from './store/reducers/navigation';
import { getMarketingLists } from './store/reducers/marketing-lists';
import { getConsentList } from './store/reducers/consent-list';
import { getMarketplacePartners } from './store/reducers/marketplace';
import { getAffiliateRelatedStaticData } from './store/reducers/static-data';
import { Application } from './components/layout/application';
import { ClientVerificationModal } from './components/layout/popups/client-verification-popup';
import { Content } from './components/layout/content';
import { withLoader } from './components/with-loader';
import { SmartBanner } from './components/smartbanner';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    MarketingInfoService.processURLAttributes(location.search);
  }, [location]);

  return (
    <>
      <SmartBanner />
      <Application>
        <ClientVerificationModal>
          <Content />
        </ClientVerificationModal>
      </Application>
    </>
  );
};

export default compose(
  connect(null, {
    setNavThemedPages,
    getUser,
    getMarketingLists,
    getConsentList,
    getMarketplacePartners,
    getAffiliateRelatedStaticData
  }),
  withState('loading', 'setLoading', true),
  withRouter,
  lifecycle({
    async componentDidMount() {
      const {
        setLoading,
        setNavThemedPages,
        getUser,
        getMarketingLists,
        getMarketplacePartners,
        history,
        location,
        getConsentList,
        getAffiliateRelatedStaticData
      } = this.props;

      window.CONFIG = await getConfig();

      setNavThemedPages();

      await getAffiliateRelatedStaticData();

      if (!UserService.hasAccount()) {
        if (UserService.hasUser()) {
          await getUser();
        } else {
          await ShadowUserService.createIfNotExists(() => history.replace('/welcome/intro/start'));
        }
      }
      await UserService.track();
      await getMarketingLists();
      await getConsentList(location);
      await getMarketplacePartners();

      setLoading(false);
    }
  }),
  withLoader
)(App);
