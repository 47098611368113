import { ConfigService } from '../../services/config-service';
import { getChecklistItemBy, getNextChecklistItemBy } from '../../helpers/checklist';
import { getKnowledgeBaseItemBy } from '../../helpers/articles/knowledge-base';
import { createReducer } from '../utils';
import { DELETE_ACCOUNT_SUCCESS } from '../reducers/account/action-types';

const SET_NAVIGATION_CHECKLIST_LINK = 'SET_NAVIGATION_CHECKLIST_LINK';
const SET_NAVIGATION_KNOWLEDGE_BASE_LINK = 'SET_NAVIGATION_KNOWLEDGE_BASE_LINK';

const SET_NAVIGATION_HEADER_BUTTON = 'SET_NAVIGATION_HEADER_BUTTON';
const SET_NAVIGATION_THEMED_PAGES = 'SET_NAVIGATION_THEMED_PAGES';
const SET_NAVIGATION_REDIRECT_LINK = 'SET_NAVIGATION_REDIRECT_LINK';

const getChecklistResetAction = iterator => checklist => {
  return (dispatch, getState) => {
    const checklistLink = getState().navigation.checklistLink;

    // get state value or current browser location
    const prevState = checklistLink?.link ?? window.location.pathname;

    // iterate over checklist items and find match or set null as default;
    const payload = iterator(checklist, ({ link }) => link === prevState, null);

    return dispatch({ type: SET_NAVIGATION_CHECKLIST_LINK, payload });
  };
};

const setFirstMatchedChecklistItemOrLeading = getChecklistResetAction(getChecklistItemBy);
const setNextChecklistItemOrLeading = getChecklistResetAction(getNextChecklistItemBy);

export const setNavChecklistLink = payload => ({ type: SET_NAVIGATION_CHECKLIST_LINK, payload });

export const resetStaticNavChecklistLink = () => {
  return (dispatch, getState) => {
    return dispatch(setFirstMatchedChecklistItemOrLeading(ConfigService.get('CHECKLIST.SECTIONS')));
  };
};

export const resetDynamicNavChecklistLink = () => {
  return (dispatch, getState) => {
    return dispatch(setFirstMatchedChecklistItemOrLeading(getState().checklist.items));
  };
};

export const resetFailedNavChecklistLink = lastFailed => {
  return (dispatch, getState) => {
    if (getState().navigation.checklistLink.link?.includes(lastFailed)) {
      const { dynamic, SECTIONS } = ConfigService.get('CHECKLIST', {});

      return dispatch(setNextChecklistItemOrLeading(dynamic ? getState().checklist.items : SECTIONS));
    }
  };
};

export const setNavKnowledgeLink = payload => ({ type: SET_NAVIGATION_KNOWLEDGE_BASE_LINK, payload });

export const resetNavKnowledgeLink = () => (dispatch, getState) => {
  const {
    content: { knowledgeTopics },
    navigation: { knowledgeLink }
  } = getState();

  // get state value or current browser location
  const prevState = knowledgeLink?.slug ?? window.location.pathname;

  // iterate over knowledge base items and find match or set null as default;
  const payload = getKnowledgeBaseItemBy(knowledgeTopics, ({ slug }) => prevState.includes(slug), null);

  return dispatch({ type: SET_NAVIGATION_KNOWLEDGE_BASE_LINK, payload });
};

const setHeaderButton = type => (pathname, url, callback) => ({
  type: SET_NAVIGATION_HEADER_BUTTON,
  payload: {
    type,
    pathname,
    url,
    callback
  }
});

export const setEditButton = setHeaderButton('editButton');
export const setHelpButton = setHeaderButton('helpButton');

export const setNavThemedPages = payload => dispatch => {
  const theme = ConfigService.get('THEME', {});

  return dispatch({ type: SET_NAVIGATION_THEMED_PAGES, payload: theme });
};

export const setRedirectionLink = payload => ({ type: SET_NAVIGATION_REDIRECT_LINK, payload });

const initialState = {
  checklistLink: null,
  knowledgeLink: null,
  redirectLink: '',
  headerButtons: {
    editButton: {},
    helpButton: {}
  },
  themedPages: void 0
};

export const navigation = createReducer(initialState, {
  [SET_NAVIGATION_CHECKLIST_LINK]: (state, action) => ({ ...state, checklistLink: action.payload }),
  [SET_NAVIGATION_KNOWLEDGE_BASE_LINK]: (state, action) => ({ ...state, knowledgeLink: action.payload }),
  [DELETE_ACCOUNT_SUCCESS]: state => ({
    ...state,
    checklistLink: null,
    knowledgeLink: null
  }),
  [SET_NAVIGATION_HEADER_BUTTON]: (state, { payload }) => {
    const { type, pathname, url, callback } = payload;

    return {
      ...state,
      headerButtons: {
        ...state.headerButtons,
        [type]: {
          ...state.headerButtons[type],
          [pathname]: {
            display: true,
            url,
            callback
          }
        }
      }
    };
  },
  [SET_NAVIGATION_THEMED_PAGES]: (state, action) => ({ ...state, themedPages: action.payload }),
  [SET_NAVIGATION_REDIRECT_LINK]: (state, action) => ({ ...state, redirectLink: action.payload })
});
