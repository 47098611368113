import React from 'react';
import { compose, withStateHandlers } from 'recompose';
import { Column } from '~/components/layout/grid';
import { ConfigService } from '../../../services/config-service';
import { Template } from '../../template';
import { Header } from '../../layout/page-header';
import { Spacer } from '../../layout/spacers';
import { CategoryFilter } from '../../layout/category-filter';
import { SegmentedButton } from '../../buttons/segmented-button';
import { KnowledgeBaseTopic } from './topic';
import { withKnowledgeBase } from './with-knowledge-base';

const tabs = [
  {
    label: 'All',
    value: 'All'
  },
  {
    label: 'Saving',
    value: 'Saving'
  },
  {
    label: 'Hunting',
    value: 'Hunting'
  },
  {
    label: 'Buying',
    value: 'Buying'
  }
];

const KnowledgeBaseComponent = React.memo(
  ({ knowledgeBase, filter, topicFilter, handleFilterChange, handleTopicFilterChange }) => {
    const { pageTitle, subtitle, filter: withFilter = false } = ConfigService.get('KNOWLEDGE_BASE', {});

    const filteredCategoriesByValue =
      filter === 'All' ? knowledgeBase : knowledgeBase.filter(({ category }) => category && category.includes(filter));

    return (
      <>
        <Header heading={pageTitle} subheading={subtitle} withKeyline />
        <Template>
          {withFilter.show && (
            <>
              {withFilter.type === 'homebuying-stage' && <Spacer type='top' />}
              <Column edge={['xss', 'lg']}>
                {withFilter.type === 'homebuying-stage' && (
                  <>
                    <Spacer type='component' />
                    <div className='grid'>
                      <SegmentedButton items={tabs} onChange={handleTopicFilterChange} />
                    </div>
                    <Spacer type='component' />
                  </>
                )}
                {withFilter.type === 'category' && (
                  <>
                    <Spacer type='content' />
                    <div className='grid'>
                      <CategoryFilter
                        selectName='knowledgeBase'
                        items={knowledgeBase}
                        accessor='category'
                        filterTitle={withFilter.filterTitle}
                        handleFilterChange={handleFilterChange}
                      />
                    </div>
                  </>
                )}
              </Column>
            </>
          )}
          <Column edge={['xss', 'lg']}>
            {filteredCategoriesByValue.map(topic => (
              <KnowledgeBaseTopic key={topic.id} topicFilter={topicFilter} {...topic} />
            ))}
          </Column>
          <Spacer type='bottom' />
        </Template>
      </>
    );
  }
);

export const KnowledgeBase = compose(
  withStateHandlers(({ filter = 'All', topicFilter = 'All' }) => ({ filter, topicFilter }), {
    handleFilterChange: () => value => ({ filter: value }),
    handleTopicFilterChange: () => value => ({ topicFilter: value })
  }),
  withKnowledgeBase
)(KnowledgeBaseComponent);
