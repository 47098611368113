import Loadable from 'react-loadable';
import { Loader } from '../components/with-loader';
import { CustomPageComplete } from '../components/templates/custom-page/complete';
import { asTemplate } from '../components/layout/with-layout';
import { CHECKPOINT, CUSTOM, LANDING } from '../helpers/navigation';

const CustomPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "customPage" */ '../components/templates/custom-page').then(
      module => module.CustomPage
    ),
  loading: Loader
});

const LandingPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "customPage" */ '../components/templates/landing').then(module => module.Landing),
  loading: Loader
});

export const customPageRoutes = [
  {
    to: '/checklist/:module/(start|result|info)',
    component: asTemplate(CUSTOM)(CustomPage)
  },
  {
    to: '/checklist/:module/done/:redirectTo?',
    component: asTemplate(CHECKPOINT)(CustomPageComplete)
  },
  {
    to: '/lp/:name',
    component: asTemplate(LANDING)(LandingPage)
  }
];
